@keyframes scaleUp-highlights {
  0% { }
  100% {
    transform: scale(1);
    opacity: 1; } }

.new-highlights-container {
  background-color: #f3f3f3;
  height: 100vh; }
  .new-highlights-container .header {
    position: fixed;
    z-index: 10;
    display: flex;
    height: 125px;
    max-width: 1240px;
    width: 100%;
    align-items: center;
    background-color: #011528;
    padding: 0px; }
    .new-highlights-container .header .back {
      display: flex;
      flex-grow: 0;
      text-align: center;
      margin-left: 10px;
      cursor: pointer; }
      .new-highlights-container .header .back .back-label {
        text-decoration: underline;
        font-size: 1.15em;
        font-family: CiscoSansTT; }
    .new-highlights-container .header .highlights-title-scroll {
      font-size: 3.75em;
      font-family: CiscoSansThin;
      text-align: center;
      flex-grow: 100; }
    .new-highlights-container .header .action {
      font-family: CiscoSansBold;
      font-size: 1.15em;
      background-color: #00bceb;
      padding: 10px 30px 8px 30px;
      border-radius: 25px;
      flex-grow: 0;
      text-align: center;
      margin-right: 10px;
      cursor: pointer; }

.cards {
  background-color: #eef2f6;
  display: flex;
  justify-content: space-around;
  align-items: inherit;
  flex-flow: row wrap; }
  .cards.fade-in .cardv2 {
    transform: scale(0.6);
    animation: scaleUp-highlights 0.65s ease-in-out forwards; }
  .cards.fade-in .text-feed {
    transform: scale(0.6);
    animation: scaleUp-highlights 0.65s ease-in-out forwards; }
  .cards.fade-out .cardv2 {
    transform-style: preserve-3d; }
  .cards.fade-out .text-feed {
    transform-style: preserve-3d; }
  .cards .cardv2 {
    position: relative;
    width: 240px;
    margin: 5px;
    border-radius: 0px; }
    .cards .cardv2.portrait .image {
      height: 200px;
      max-height: 200px; }
    .cards .cardv2.portrait .details {
      min-height: 150px; }
      .cards .cardv2.portrait .details .msg {
        min-height: 80px; }
    .cards .cardv2 .image {
      position: relative;
      width: 240px;
      height: 150px;
      max-height: 150px;
      background-size: cover;
      background-position: center center;
      overflow: hidden; }
      .cards .cardv2 .image img {
        max-width: 240px; }
      .cards .cardv2 .image .source {
        top: -15px;
        left: -10px; }
        .cards .cardv2 .image .source .src-twitter {
          width: 40px;
          height: 40px; }
          .cards .cardv2 .image .source .src-twitter .icon-fk-twitter-1 {
            font-size: 1em;
            line-height: 1.4;
            margin-left: 0;
            position: absolute;
            top: 15px;
            left: 10px; }
        .cards .cardv2 .image .source .src-instagram {
          width: 40px;
          height: 40px; }
          .cards .cardv2 .image .source .src-instagram .icon-fk-instagram-1 {
            font-size: 1.5em;
            position: absolute;
            top: 1px;
            left: 3px; }
    .cards .cardv2 .details {
      max-width: 240px;
      max-height: 250px; }
      .cards .cardv2 .details .author {
        margin-bottom: 20px;
        position: relative;
        font-size: 1em;
        width: 100%;
        height: 50px;
        background-repeat: no-repeat;
        background-image: none;
        background-size: 100px; }
        .cards .cardv2 .details .author img {
          position: absolute;
          top: 30px;
          left: 10px; }
        .cards .cardv2 .details .author .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50px; }
          .cards .cardv2 .details .author .avatar img {
            position: absolute;
            top: 30px;
            left: 10px; }
        .cards .cardv2 .details .author .name {
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 150px;
          max-height: 70px;
          padding-top: 35px;
          padding-left: 45px;
          font-weight: 500;
          position: relative;
          font-size: 0.8em;
          text-align: left;
          overflow: hidden;
          color: #0d274d; }
        .cards .cardv2 .details .author .handle {
          font-size: 0.6em;
          padding-top: 3px;
          padding-left: 45px;
          color: #828282; }
        .cards .cardv2 .details .author .created-at {
          font-size: 0.65em;
          top: 40px; }
      .cards .cardv2 .details .msg {
        padding: 14px 14px 14px 14px;
        font-size: 0.8em;
        min-height: 120px;
        color: #272727; }
    .cards .cardv2 .stats {
      border-top: none;
      width: 200px;
      font-size: 0.9em;
      display: flex;
      justify-content: flex-end; }
      .cards .cardv2 .stats .icon-fk-reply {
        color: #8c8c8c; }
      .cards .cardv2 .stats .like-icon-fk {
        color: #8c8c8c;
        margin-left: 10px; }
      .cards .cardv2 .stats .retweet-icon-fk {
        color: #8c8c8c;
        margin-left: 10px; }
      .cards .cardv2 .stats .like-count {
        color: #8c8c8c; }
      .cards .cardv2 .stats .retweet-count {
        color: #8c8c8c; }
    .cards .cardv2:hover {
      transform: none;
      transition: none; }
  .cards .text-feed {
    width: 240px;
    height: 343px;
    max-height: 343px;
    margin: 6px 6px 3px 6px;
    box-shadow: 0px 7px 43px 0 rgba(77, 77, 77, 0.11); }
    .cards .text-feed .details {
      border-radius: 0;
      width: 225px;
      max-width: 225px;
      max-height: 190px; }
      .cards .text-feed .details .author {
        background-image: none;
        margin-bottom: 10px;
        position: relative;
        font-size: 13.5px;
        color: #4c4c4c;
        width: 100%;
        height: 50px; }
        .cards .text-feed .details .author .avatar {
          position: absolute;
          top: 15px;
          left: 15px;
          width: 30px;
          height: 30px;
          border-radius: 50px;
          font-size: 13.5px;
          color: #4c4c4c;
          border: none; }
        .cards .text-feed .details .author .name {
          max-width: 97px;
          padding-left: 5px;
          max-height: 38px;
          padding-top: 20px;
          font-weight: 700;
          position: relative;
          font-size: 0.85em;
          text-align: left;
          overflow: hidden;
          font-family: "Open Sans", sans-serif;
          margin-left: 22%; }
        .cards .text-feed .details .author .handle {
          max-width: 150px;
          max-height: 15px;
          padding-left: 5px;
          font-size: 0.75em;
          font-weight: 400;
          position: relative;
          text-align: left;
          overflow: hidden;
          color: #828282;
          margin-left: 22%; }
        .cards .text-feed .details .author .created-at {
          font-size: 0.75em;
          position: absolute;
          top: 40px;
          right: -10px;
          color: #9ca5aa;
          font-weight: 400; }
      .cards .text-feed .details .msg {
        color: #4c4c4c;
        font-size: 14px;
        font-weight: 500;
        padding: 0px 14px 14px 14px;
        line-height: 1.54;
        letter-spacing: 0.56px;
        text-align: left;
        min-height: 150px;
        pointer-events: none;
        height: auto;
        overflow: auto;
        margin-top: 10px; }
    .cards .text-feed .stats {
      position: absolute;
      bottom: 5px;
      border-top: 0px solid #edf2f6;
      width: 100%;
      font-family: CiscoSans;
      font-size: 0.9em;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      color: #b2c0c8; }
      .cards .text-feed .stats .source {
        padding-left: 1.5vw; }
        .cards .text-feed .stats .source .src-twitter .icon-fk-twitter-1 {
          color: #00a4e8; }
      .cards .text-feed .stats .retweet-icon-fk {
        color: #8c8c8c; }
        .cards .text-feed .stats .retweet-icon-fk.icon-fk-retweet {
          color: #8c8c8c; }
        .cards .text-feed .stats .retweet-icon-fk .retwee-count {
          color: #8c8c8c; }
      .cards .text-feed .stats .retweet-count {
        padding-right: 0px;
        margin-right: 10px;
        color: #8c8c8c; }
      .cards .text-feed .stats .like-count {
        padding-right: 10px;
        margin-right: 10px;
        color: #8c8c8c; }
      .cards .text-feed .stats .like-icon-fk {
        color: #8c8c8c; }
        .cards .text-feed .stats .like-icon-fk.icon-fk-heart-empty {
          color: #8c8c8c; }
      .cards .text-feed .stats .like-count {
        color: #8c8c8c; }

@media (min-width: 320px) and (max-width: 600px) {
  .cards.new .header .action {
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
    font-size: 0.8rem; }
  .cards.new .header .title {
    font-size: 1.2rem;
    left: 35%; }
  .cards.new .cardv2 {
    max-width: 275px;
    width: 275px; }
    .cards.new .cardv2 .image {
      max-width: 275px;
      width: 275px; }
    .cards.new .cardv2 .details {
      max-width: 275px;
      width: 275px; }
  .cards.new .text-feed {
    width: 275px;
    min-width: 275px; }
    .cards.new .text-feed .details {
      min-width: 100%; } }

@media (max-width: 320px) {
  .cards.new .header {
    padding: 0.5rem; }
    .cards.new .header .action {
      font-size: 0.75rem;
      padding: 0.25rem 1rem 0.25rem 0.25rem; }
    .cards.new .header .title {
      font-size: 1rem;
      left: 40%; } }
