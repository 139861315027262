.landing-parent {
  background-image: url(./../assets/landing-bg.png);
  background-size: cover;
  background-position: center center;
  padding: 2.3% 3% 10.7% 3%; }
  .landing-parent .landing-header {
    display: flex; }
    .landing-parent .landing-header .landing-parent-title {
      flex-grow: 9;
      color: #ffffff;
      text-align: center;
      font-family: CiscoSans;
      font-size: 3em;
      padding-bottom: 2%; }
    .landing-parent .landing-header .landing-action-btn {
      flex-grow: 1; }
      .landing-parent .landing-header .landing-action-btn .action-btn {
        background-color: #02b1e4;
        color: #ffffff; }
        .landing-parent .landing-header .landing-action-btn .action-btn .icon-fk-twitter-1 {
          color: #ffffff; }

.landing-container {
  padding-bottom: 3%;
  height: 100%;
  color: #ffffff;
  background-color: #0d274d; }
  .landing-container .landing-description {
    padding: 5% 1% 4% 1%;
    font-size: 1.55em;
    text-align: center;
    line-height: 1.45;
    letter-spacing: normal;
    font-family: CiscoSansThin; }
  .landing-container .row {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start; }
