.fk-header-wrapper {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 88px;
  background-color: #01366A;
  gap: 30px;
  padding: 0 30px; }
  .fk-header-wrapper .fk-filter-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1; }
    .fk-header-wrapper .fk-filter-wrapper .fk-filters-anchor {
      border-radius: 30px;
      border: solid 2px #fff;
      padding: 9px 35px 9px 21px;
      position: relative;
      cursor: pointer; }
      .fk-header-wrapper .fk-filter-wrapper .fk-filters-anchor .fk-filters-anchor-button {
        color: #fff;
        display: flex;
        align-items: flex-end; }
        .fk-header-wrapper .fk-filter-wrapper .fk-filters-anchor .fk-filters-anchor-button img {
          padding-right: 7px; }
        .fk-header-wrapper .fk-filter-wrapper .fk-filters-anchor .fk-filters-anchor-button .fk-filters-count {
          font-size: 12px;
          background-color: #f6be00;
          height: 20px;
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-left: 5px;
          color: #000; }
    .fk-header-wrapper .fk-filter-wrapper .fk-heading {
      color: #0d274d;
      font-size: 16px;
      width: 138px; }
    .fk-header-wrapper .fk-filter-wrapper .fk-header-refresh-container .fk-header-refresh {
      grid-area: refresh;
      background-image: url(./../../../assets/impact21/images/Refresh.svg);
      height: 40px;
      width: 40px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      margin: 5px; }

.fk-header-wrapper-mobile {
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
  padding: 0 10px; }
  .fk-header-wrapper-mobile .fk-filter-wrapper {
    width: 100%; }
    .fk-header-wrapper-mobile .fk-filter-wrapper .fk-filters-anchor {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
      .fk-header-wrapper-mobile .fk-filter-wrapper .fk-filters-anchor .fk-filters-anchor-button img {
        padding: 0; }
      .fk-header-wrapper-mobile .fk-filter-wrapper .fk-filters-anchor .fk-filters-anchor-button span {
        display: none; }
      .fk-header-wrapper-mobile .fk-filter-wrapper .fk-filters-anchor .fk-filters-anchor-button .fk-filters-count {
        display: flex;
        position: absolute;
        top: -3px;
        right: -4px;
        font-size: 10px;
        height: 15px;
        width: 15px; }
    .fk-header-wrapper-mobile .fk-filter-wrapper .fk-heading {
      margin: 0; }
    .fk-header-wrapper-mobile .fk-filter-wrapper .fk-filter-list {
      padding-top: 10px; }
