@keyframes fadein {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes fadeout {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 0; } }

.gallery-container .gallery-title {
  display: none; }

.gallery-container .gallery-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-wrap: wrap; }
  .gallery-container .gallery-cards .gallery-card {
    margin: 1px; }
    .gallery-container .gallery-cards .gallery-card .gallery-details {
      display: none; }
    .gallery-container .gallery-cards .gallery-card:hover .gallery-details {
      display: block;
      width: 300px; }
