$width: 340px;

.fk-container {
  display: flex;
  // border: 1px solid #000000;
  justify-content: center;
  align-items: center;
  padding: 5px;

  &.hide {
    display: none;
  }

  .working-space {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: 1px solid #000000;
    padding: 5px;

    .fabricCanvas {}

    .canvas {
      display: none;
      border: 1px solid #000000;
    }

    .stickers {
      width: $width;
      border: 1px solid #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 5px;

      img {
        cursor: pointer;
        // background-color: #000000;
        // border: 1px solid #000000;
        width: 55px;
        height: 55px;
        margin: 3px;
        border-radius: 10px;
      }
    }

    .backgrounds {
      width: $width;
      // border: 1px solid #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 5px;

      img {
        cursor: pointer;
        // border: 1px solid #000000;
        width: 55px;
        height: 55px;
        margin: 3px;
        border-radius: 10px;
      }
    }

    .download-copy {
      margin-top: 15px;
      margin-bottom: 15px;
      max-width: 490px;
      text-align: center;
      font-family: 'CiscoSansLight';
    }

    .download {
      background-color: #00bceb;
      width: 168px;
      height: 39px;
      color: #ffffff;
      padding: 11px 24px;
      border-radius: 8px;
      text-align: center;
      font-family: 'CiscoSansTT';
      font-weight: bold;
      cursor: pointer;
    }
  }
}