.fk-search {
  width: 300px;
  height: 44px;
  font-size: 12px; }
  .fk-search .fk-search-field {
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr auto;
    position: relative;
    border: solid 2px rgba(255, 255, 255, 0.6);
    align-items: center;
    padding: 2px 0; }
    .fk-search .fk-search-field .fk-search-box {
      outline: none;
      background: transparent;
      border: none;
      padding: 10px;
      color: rgba(255, 255, 255, 0.6); }
      .fk-search .fk-search-field .fk-search-box::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(255, 255, 255, 0.6);
        opacity: 1;
        /* Firefox */ }
      .fk-search .fk-search-field .fk-search-box:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(255, 255, 255, 0.6); }
      .fk-search .fk-search-field .fk-search-box::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(255, 255, 255, 0.6); }
    .fk-search .fk-search-field .fk-search-btn {
      height: 16px;
      width: 16px;
      cursor: pointer;
      margin: 10px;
      align-self: center;
      opacity: 0.8;
      background-color: transparent;
      border: 0;
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: url("../../../assets/impact21/images/search-icon.svg"); }
      .fk-search .fk-search-field .fk-search-btn:hover {
        outline: none;
        opacity: 1; }
      .fk-search .fk-search-field .fk-search-btn:focus {
        outline: none;
        opacity: 1; }
  .fk-search .fk-options {
    max-height: 185px;
    padding: 10px;
    background-color: #f5f5f5;
    box-shadow: 8px 8px 24px 0 rgba(13, 39, 77, 0.16);
    display: grid;
    position: relative;
    overflow: auto;
    z-index: 21; }
    .fk-search .fk-options .fk-options-item {
      color: #64758d;
      height: 28px;
      display: grid;
      align-content: center;
      padding: 0 10px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .fk-search .fk-options .fk-options-item .fk-matched-suggestion {
        display: contents;
        color: #0d274d;
        font-weight: bold; }
      .fk-search .fk-options .fk-options-item.fk-hovered {
        background-color: rgba(111, 136, 164, 0.15); }
      .fk-search .fk-options .fk-options-item:hover {
        background-color: rgba(111, 136, 164, 0.15); }

.fk-search-mobile {
  width: 100%;
  max-width: 200px; }
  .fk-search-mobile .fk-search-box {
    max-width: 140px; }
