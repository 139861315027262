@keyframes popUp-gallery {
  0% { }
  70% {
    transform: scale(1.1);
    opacity: 0.8;
    animation-timing-function: ease-out; }
  100% {
    transform: scale(1);
    opacity: 1; } }

.gallery-parent-container {
  background-color: #f3f3f3;
  height: 100vh; }
  .gallery-parent-container .header {
    display: flex;
    padding: 1vw; }
    .gallery-parent-container .header .back {
      cursor: pointer;
      width: 4em;
      height: 2em;
      margin-left: 1.75vw;
      border-radius: 10px;
      box-shadow: 0px 6px 9px 0 rgba(0, 0, 0, 0.13);
      background-color: #ffffff;
      text-align: center; }
      .gallery-parent-container .header .back .icon-fk {
        color: #0d274d;
        line-height: 2em; }
    .gallery-parent-container .header .gallery-parent-title {
      width: 100%;
      text-align: center;
      font-family: "Open Sans", sans-serif;
      color: #0d274d;
      font-size: 2em; }
  .gallery-parent-container.fade-in .gallery-container .gallery-card {
    transform-style: preserve-3d;
    transform: scale(0.4);
    animation: popUp-gallery 0.8s ease-in forwards; }
  .gallery-parent-container.fade-out .gallery-container .gallery-card {
    transform-style: preserve-3d; }

.gallery-container .gallery-title {
  display: none; }

.gallery-container .gallery-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  flex-wrap: wrap; }
  .gallery-container .gallery-cards .gallery-author {
    display: none; }
  .gallery-container .gallery-cards .gallery-card {
    margin: 1px;
    width: 240px;
    height: 235px;
    overflow: hidden; }
    .gallery-container .gallery-cards .gallery-card .gallery-details {
      display: none; }
    .gallery-container .gallery-cards .gallery-card .gallery-src {
      font-size: 1em; }
      .gallery-container .gallery-cards .gallery-card .gallery-src.src-twitter {
        background-color: #ffffff;
        box-shadow: none;
        text-shadow: none;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        position: absolute;
        top: -20px;
        left: -20px; }
        .gallery-container .gallery-cards .gallery-card .gallery-src.src-twitter .icon-fk-twitter-1 {
          color: #00bceb;
          position: relative;
          top: 25px;
          left: 20px; }
      .gallery-container .gallery-cards .gallery-card .gallery-src.src-instagram {
        background-color: #ffffff;
        box-shadow: none;
        text-shadow: none;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        position: absolute;
        top: -20px;
        left: -20px; }
        .gallery-container .gallery-cards .gallery-card .gallery-src.src-instagram .icon-fk-instagram-1 {
          color: #bc1888;
          position: relative;
          top: 25px;
          left: 20px; }
    .gallery-container .gallery-cards .gallery-card:hover .gallery-details {
      display: none; }
