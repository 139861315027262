@keyframes carousel-slide {
  from {
    transform: translatex(0%);
  }
  to {
    transform: translatex(100%);
  }
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

.carousel-container {
  // display: flex;
  // flex-direction: row;
  // justify-content: space-around;
  // align-items: flex-start;

  display: flex;
  justify-content: center;
  // height: 70vh;
  // flex-wrap: no-wrap;
  // max-width: 1300px;

  // animation: slide ease-in-out 20s linear;
  // animation: slide 0.5s forwards;
  .previous {
    display: none;
    margin-top: 160px;
    margin-left: 10px;
    cursor: pointer;
    .icon-fk-left-open {
      font-size: 2em;
      &:hover {
        color: #d41b2c;
      }
    }
  }
  .next {
    display: none;
    margin-top: 160px;
    margin-right: 10px;
    cursor: pointer;
    .icon-fk-right-open {
      font-size: 2em;
      &:hover {
        color: #d41b2c;
      }
    }
  }

  .overlay {
    .cardv2 {
      max-height: 100vh;
      .image {
        .instagram-pic {
          min-height: 125px;
          background-image: url("./../assets/pureaccel-ig-bg.png");
          background-size: 350px 125px;
          background-repeat: no-repeat;
          padding: 1px 1px;
          margin: 1px 1px;
        }
        .twitter-pic {
          min-height: 125px;
          background-image: url("./../assets/pureaccel-twitter-bg.png");
          background-size: 350px 125px;
          background-repeat: no-repeat;
          padding: 1px 1px;
          margin: 1px 1px;
          img {
            border-radius: 0px;
          }
        }
        .details {
          .author {
            background-image: none;
            .avatar {
              top: 30px;
            }
          }
        }
        .source {
          .src-instagram {
            background: #ffffff;
            .icon-fk-instagram-1 {
              color: #fe5900;
            }
          }
          .src-twitter {
            .icon-fk-twitter-1 {
              color: #fe5900;
            }
          }
        }
        .stats {
          border-top: 0;
          a {
            i {
              color: #fe5900;
            }
          }
          color: #fe5900;
          display: flex;
          justify-content: flex-end;
          // z-index: 10;
          // background-color: #ffffff;
          // padding: 10px 10px 1px 10px;
        }
      }
    }
  }
  .cards {
    display: flex;
    justify-content: center;
    height: 70vh;
    flex-wrap: wrap;
    max-width: 1300px;
    .cardv2 {
      border-radius: 0;
      .image {
        width: 300px;
        height: 300px;
        max-height: 400px;
        background-size: cover;
        background-position: center center;
        .instagram-pic {
          &.portrait {
            display: flex;
            justify-content: center;
            overflow: hidden;
            max-height: 300px;
            img {
              width: 100%;
              height: 100%;
            }
            background-image: none;
            // background-size: 199px 105px;
          }
          min-height: 105px;
          background-image: url("./../assets/pureaccel-ig-bg.png");
          background-size: 300px 105px;
          background-repeat: no-repeat;
          padding: 0px 0px;
          margin: 0px 0px;
        }
        .twitter-pic {
          &.portrait {
            display: flex;
            justify-content: center;
            overflow: hidden;
            max-height: 300px;
            img {
              width: 100%;
              height: 100%;
            }
            background-image: none;
          }
          min-height: 105px;
          background-image: url("./../assets/pureaccel-twitter-bg.png");
          background-size: 300px 105px;
          background-repeat: no-repeat;
          padding: 0px 0px;
          margin: 0px 0px;
          img {
            border-radius: 0px;
          }
        }
      }
      .details {
        .author {
          background-image: none;
          .avatar {
            top: 30px;
          }
        }
        .msg {
          min-height: 150px;
          pointer-events: none;
        }
      }
      .stats {
        border-top: 0;
        a {
          display: none;
          i {
            color: #fe5900;
          }
        }
        color: #fe5900;
        display: flex;
        justify-content: flex-end;
        .retweet-count {
          padding-left: 10px;
        }
        .like-count {
          padding-left: 10px;
        }
      }
      .source {
        .src-instagram {
          background: #ffffff;
          .icon-fk-instagram-1 {
            color: #fe5900;
          }
        }
        .src-twitter {
          .icon-fk-twitter-1 {
            color: #fe5900;
          }
        }
      }
    }
  }
}
