.fk-gallery-view-container-mobile {
  font-family: "CiscoSansTT";
  padding: 0;
  box-sizing: border-box;
  position: relative;
  margin: auto;
  overflow: auto;
  height: 100%;
  background-color: transparent; }
  .fk-gallery-view-container-mobile .fk-story-wrapper {
    display: grid; }
    .fk-gallery-view-container-mobile .fk-story-wrapper .fk-story-wrapper-inner {
      overflow: auto; }
      .fk-gallery-view-container-mobile .fk-story-wrapper .fk-story-wrapper-inner .fk-story {
        display: flex;
        justify-content: center;
        margin: 20px; }
        .fk-gallery-view-container-mobile .fk-story-wrapper .fk-story-wrapper-inner .fk-story:first-child {
          margin-top: 0; }

.fk-scroll-loader {
  width: 100%;
  height: 5px;
  position: relative; }
  .fk-scroll-loader .fk-loader-container {
    backdrop-filter: none; }
    .fk-scroll-loader .fk-loader-container .fk-loader {
      width: 40px;
      height: 40px; }
