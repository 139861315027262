.cisco-impact,
.cisco-impact-stage,
.cisco-impact-app {
  font-family: "CiscoSansTT";
  width: 100%;
  min-height: 740px;
  height: 740px;
  max-height: 740px;
  display: flex;
  flex-direction: column; }
  .cisco-impact .fk-gallery-container,
  .cisco-impact-stage .fk-gallery-container,
  .cisco-impact-app .fk-gallery-container {
    width: 100%;
    min-height: 740px;
    height: 740px;
    max-height: 740px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #e2e2e2; }
    .cisco-impact .fk-gallery-container.fk-gallery-embed,
    .cisco-impact-stage .fk-gallery-container.fk-gallery-embed,
    .cisco-impact-app .fk-gallery-container.fk-gallery-embed {
      height: 100vh;
      max-height: 100vh; }
    .cisco-impact .fk-gallery-container .fk-footer-overlay,
    .cisco-impact-stage .fk-gallery-container .fk-footer-overlay,
    .cisco-impact-app .fk-gallery-container .fk-footer-overlay {
      width: 100%;
      height: 100px;
      left: 0;
      background-image: linear-gradient(to bottom, rgba(245, 245, 245, 0), rgba(245, 245, 245, 0.7) 50%, #f5f5f5 90%);
      position: absolute;
      bottom: 0;
      display: flex; }
