@keyframes fadein {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes fadeout {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 0; } }

.leaderboard-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #0d274d;
  width: 100%;
  min-height: 720px;
  font-family: CiscoSansThin; }
  .leaderboard-container .header {
    position: fixed;
    z-index: 10;
    display: flex;
    height: 125px;
    max-width: 1240px;
    width: 100%;
    align-items: center;
    background-color: #011528;
    padding: 0px; }
    .leaderboard-container .header .back {
      display: flex;
      flex-grow: 0;
      text-align: center;
      margin-left: 10px;
      cursor: pointer; }
      .leaderboard-container .header .back .icon-fk {
        display: none; }
      .leaderboard-container .header .back .back-label {
        text-decoration: underline;
        font-size: 1.15em;
        font-family: CiscoSansTT; }
    .leaderboard-container .header .title {
      font-size: 3.75em;
      font-family: CiscoSansThin;
      text-align: center;
      flex-grow: 100; }
    .leaderboard-container .header .action {
      font-family: CiscoSansBold;
      font-size: 1.15em;
      background-color: #00bceb;
      padding: 10px 30px 8px 30px;
      border-radius: 25px;
      flex-grow: 0;
      text-align: center;
      margin-right: 10px;
      cursor: pointer; }
  .leaderboard-container .leaderboard-content .post .post-img {
    overflow: hidden;
    cursor: pointer; }
    .leaderboard-container .leaderboard-content .post .post-img .pic {
      max-width: 127px;
      height: 125px;
      background-size: cover;
      background-position: top center;
      margin: 5% 0% 0% 8%; }
    .leaderboard-container .leaderboard-content .post .post-img img {
      display: none;
      margin: 8px 0px 0px 15px;
      max-width: 127px; }
  .leaderboard-container .leaderboard-content .post .post-body .post-content .author-name {
    width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 1em;
    text-align: center;
    font-size: 1.25em;
    line-height: 1.25em;
    font-family: CiscoSans; }
  .leaderboard-container .leaderboard-content .post .post-body .post-content .author-handle {
    width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    margin-top: 0.5em;
    font-size: 1.1em;
    line-height: 1.1em;
    font-family: CiscoSansThin; }
  .leaderboard-container .leaderboard-content .post .post-body .post-footer {
    color: #02b1e4;
    margin-top: 0.5em;
    font-family: CiscoSans; }
    .leaderboard-container .leaderboard-content .post .post-body .post-footer .post-count {
      text-align: center;
      font-size: 1.4em; }
    .leaderboard-container .leaderboard-content .post .post-body .post-footer .post-logo .icon-fk-twitter-1 {
      font-size: 2em; }
  .leaderboard-container .leaderboard-content .top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(2, 177, 228, 0.15); }
    .leaderboard-container .leaderboard-content .top .post {
      margin: auto; }
      .leaderboard-container .leaderboard-content .top .post .post-img {
        width: 170px;
        height: 150px;
        background-image: url(./../assets/leaderboard-bg.png);
        background-size: cover;
        background-position: center center; }
    .leaderboard-container .leaderboard-content .top .post:nth-child(1) .post-body {
      position: relative; }
      .leaderboard-container .leaderboard-content .top .post:nth-child(1) .post-body .medal {
        position: absolute;
        top: -180px;
        left: -30px;
        display: block;
        background: url(./../assets/gold-cup.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 57px;
        height: 76px; }
      .leaderboard-container .leaderboard-content .top .post:nth-child(1) .post-body .post-content .rank {
        position: absolute;
        top: -175px;
        left: -7px;
        font-size: 1.2em;
        font-family: CiscoSans; }
    .leaderboard-container .leaderboard-content .top .post:nth-child(2) .post-body {
      position: relative; }
      .leaderboard-container .leaderboard-content .top .post:nth-child(2) .post-body .medal {
        position: absolute;
        top: -180px;
        left: -30px;
        display: block;
        background: url(./../assets/silver-cup.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 57px;
        height: 76px; }
      .leaderboard-container .leaderboard-content .top .post:nth-child(2) .post-body .post-content .rank {
        position: absolute;
        top: -175px;
        left: -7px;
        font-size: 1.2em;
        font-family: CiscoSans; }
    .leaderboard-container .leaderboard-content .top .post:nth-child(3) .post-body {
      position: relative; }
      .leaderboard-container .leaderboard-content .top .post:nth-child(3) .post-body .medal {
        position: absolute;
        top: -180px;
        left: -30px;
        display: block;
        background: url(./../assets/bronze-cup.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 57px;
        height: 76px; }
      .leaderboard-container .leaderboard-content .top .post:nth-child(3) .post-body .post-content .rank {
        position: absolute;
        top: -175px;
        left: -7px;
        font-size: 1.2em;
        font-family: CiscoSans; }
  .leaderboard-container .leaderboard-content .rest {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    background-color: #0d274d; }
    .leaderboard-container .leaderboard-content .rest .post .post-body {
      position: relative; }
      .leaderboard-container .leaderboard-content .rest .post .post-body .post-content .rank {
        position: absolute;
        top: -175px;
        left: 10px;
        font-size: 1.2em;
        font-family: CiscoSans; }
    .leaderboard-container .leaderboard-content .rest .post .post-img {
      width: 190px;
      height: 160px;
      background-image: url(./../assets/rest-image-bg.png);
      background-size: cover;
      background-position: center center; }
      .leaderboard-container .leaderboard-content .rest .post .post-img .pic {
        max-width: 125px;
        margin: 10% 0% 0% 20%; }
      .leaderboard-container .leaderboard-content .rest .post .post-img img {
        width: 125px;
        height: 125px;
        margin: 20px 0px 0px 36px;
        display: none; }
  .leaderboard-container .leaderboard-content .btn {
    display: none;
    text-align: center;
    font-family: CiscoSansTTBold;
    background-color: #f4b122;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    cursor: pointer; }
  .leaderboard-container .leaderboard-content .cisco-btn {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px; }
