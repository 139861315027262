.badge-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 10px;
  background-image: url(./../assets/ciscolive2022/mobile-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  height: 100%;
  min-height: 757px;
  font-family: 'CiscoSansThin'; }
  .badge-container .header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; }
    .badge-container .header .back {
      display: flex;
      margin-top: 30px;
      cursor: pointer;
      z-index: 20; }
    .badge-container .header .title {
      margin-top: -10px;
      font-size: 3.5em;
      width: 100vw;
      text-align: center;
      z-index: 10;
      background-color: #061932; }
  .badge-container .fk-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px; }
  .badge-container input {
    padding: 10px 10px;
    margin: 10px 00px;
    width: 375px;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #c7ccd0; }
    .badge-container input:focus {
      outline: none; }
  .badge-container button {
    padding: 10px 10px;
    margin: 10px 00px; }
  .badge-container .warning {
    padding: 1px;
    margin: 1px;
    font-size: 0.75em; }
    .badge-container .warning.red {
      color: red; }
  .badge-container .submit {
    width: 170px;
    height: 40px;
    padding: 5px;
    text-align: center;
    padding: 11px 24px;
    border-radius: 8px;
    margin-top: 10px;
    background-color: #00bceb;
    cursor: pointer;
    font-family: 'CiscoSansLight';
    font-weight: bold; }
  .badge-container .userInfo {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; }
    .badge-container .userInfo .title {
      font-size: 1.5em;
      max-width: 365px;
      text-align: center;
      line-height: 1.14;
      margin-bottom: 20px; }
  .badge-container .badge-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; }
