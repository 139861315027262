@import url(./../assets/impact21/fonts/cisco-fonts.css);
@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

* {
  box-sizing: border-box; }

.cisco-retail .card {
  background-color: #006a8d;
  font-family: "CiscoSans";
  width: 460px; }
  .cisco-retail .card .details {
    flex-direction: column;
    background-color: transparent;
    padding: 10px 20px; }
    .cisco-retail .card .details .author {
      height: 40px; }
      .cisco-retail .card .details .author .avatar {
        display: none; }
      .cisco-retail .card .details .author .name {
        color: white;
        font-size: 18px;
        position: relative;
        top: auto;
        left: auto; }
      .cisco-retail .card .details .author .handle {
        line-height: 24px;
        font-size: 16px;
        position: relative;
        top: auto;
        left: auto;
        padding-left: 5px; }
      .cisco-retail .card .details .author .created-at {
        font-size: 18px; }
    .cisco-retail .card .details .msg {
      font-size: 27px;
      line-height: 31px;
      margin-left: 0; }
    .cisco-retail .card .details .stats span {
      font-size: 18px; }

.cisco-store .fk-container {
  justify-content: center; }
  .cisco-store .fk-container .card-body .author {
    font-size: 0.8rem; }
    .cisco-store .fk-container .card-body .author .handle {
      color: #20cb64; }
  .cisco-store .fk-container .card-text {
    font-size: 1rem; }
    .cisco-store .fk-container .card-text .highlight {
      color: #20cb64; }
  .cisco-store .fk-container .card-footer {
    font-size: 0.8rem; }
    .cisco-store .fk-container .card-footer .icon-fk {
      font-size: 1.7em; }
  .cisco-store .fk-container .textCards.textonly .row .card-body .author {
    font-size: 0.8rem; }
  .cisco-store .fk-container .textCards.textonly .row .card-body .card-text {
    font-size: 1rem; }
    .cisco-store .fk-container .textCards.textonly .row .card-body .card-text .highlight {
      color: #20cb64; }
  .cisco-store .fk-container .textCards.textonly .row .card-body .card-footer {
    font-size: 0.8rem; }
    .cisco-store .fk-container .textCards.textonly .row .card-body .card-footer .icon-fk {
      font-size: 1.7em; }

.cisco-store .tweet-cards {
  justify-content: center; }
  .cisco-store .tweet-cards .card {
    margin: 0.5px; }

.cisco-store .card {
  background-color: #868686;
  font-family: "CiscoSans";
  width: 342px;
  height: 342px; }
  .cisco-store .card .details {
    padding: 10px 20px;
    min-height: 150px;
    max-height: 150px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(77, 77, 78, 0.6) 20%, #4d4d4e 100%); }
    .cisco-store .card .details .author .avatar {
      padding-left: 0;
      width: 42px;
      height: 42px; }
    .cisco-store .card .details .author .name {
      display: none; }
    .cisco-store .card .details .author .handle {
      margin-left: 13px;
      font-size: 18px;
      line-height: normal;
      text-align: left;
      color: #ffffff; }
    .cisco-store .card .details .author .created-at {
      display: none; }
    .cisco-store .card .details .msg {
      overflow: hidden;
      max-height: 100%;
      margin-left: 0;
      font-size: 14px;
      line-height: 1.2em;
      text-align: left;
      color: #ffffff;
      border: none; }
    .cisco-store .card .details .stats {
      display: none; }
  .cisco-store .card.text .details {
    top: 0px;
    bottom: auto;
    min-height: 100%; }
    .cisco-store .card.text .details .author .handle {
      font-size: 1.5rem; }
    .cisco-store .card.text .details .msg {
      font-size: 1.2rem; }

.cisco-store .gallery-container.fade-in .gallery-cards .gallery-card {
  animation: none; }

.cisco-store .gallery-container.fade-out .gallery-cards .gallery-card {
  animation: none; }

.cisco-store .gallery-container .gallery-cards {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }
  .cisco-store .gallery-container .gallery-cards .gallery-card-parent {
    margin: 2px 1px 2px 1px; }
    .cisco-store .gallery-container .gallery-cards .gallery-card-parent:hover {
      border-bottom: none; }
      .cisco-store .gallery-container .gallery-cards .gallery-card-parent:hover .gallery-author {
        border-bottom: 3px solid #1e4471; }
    .cisco-store .gallery-container .gallery-cards .gallery-card-parent .gallery-card {
      width: 300px;
      border: none; }
      .cisco-store .gallery-container .gallery-cards .gallery-card-parent .gallery-card .no-link {
        font-family: CiscoSans; }
        .cisco-store .gallery-container .gallery-cards .gallery-card-parent .gallery-card .no-link .gallery-details {
          width: 300px; }
          .cisco-store .gallery-container .gallery-cards .gallery-card-parent .gallery-card .no-link .gallery-details .gallery-msg {
            font-family: CiscoSans; }
    .cisco-store .gallery-container .gallery-cards .gallery-card-parent .gallery-author {
      margin-left: 1px;
      margin-right: 1px;
      border-bottom: 3px solid #eee; }

.cisco-store .gallery-scroll-parent-container {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1430px; }
  .cisco-store .gallery-scroll-parent-container .header {
    display: none;
    padding-top: 25px; }
    .cisco-store .gallery-scroll-parent-container .header .action {
      display: none; }
    .cisco-store .gallery-scroll-parent-container .header .gallery-scroll-parent-title {
      display: none; }
  .cisco-store .gallery-scroll-parent-container .scroll-to-top {
    background-image: url(./../assets/arrow-up.svg);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 10px 6px;
    font-size: 1em;
    display: block;
    z-index: 20; }
    .cisco-store .gallery-scroll-parent-container .scroll-to-top i {
      display: none; }
    .cisco-store .gallery-scroll-parent-container .scroll-to-top.hide {
      display: none; }
  .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card-parent {
    position: absolute;
    z-index: 10;
    max-width: 620px;
    max-height: 620px; }
    .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card-parent.portrait {
      top: 15%;
      left: 35%; }
      .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card-parent.portrait video {
        height: 640px; }
    .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card-parent.landscape {
      top: 30%;
      left: 30%; }
      .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card-parent.landscape video {
        width: 580px; }
  .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card {
    z-index: 10; }
    .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .close {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 25px;
      height: 25px;
      z-index: 20;
      background-image: url(./../assets/close.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      cursor: pointer; }
    .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container {
        margin-top: -50px;
        width: 95%;
        height: 50px;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .closed-captions {
          text-align: center;
          position: absolute;
          bottom: 15px;
          margin-bottom: 5px; }
          .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .closed-captions .cc {
            text-align: center;
            color: #ffffff;
            background-color: #00000052;
            padding: 3px;
            font-family: CiscoSans;
            font-size: 0.85em; }
        .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls {
          display: flex;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          justify-content: space-between;
          align-items: center; }
          .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls .left {
            display: flex; }
            .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls .left .play-pause .icon {
              color: #ffffff;
              cursor: pointer;
              font-size: 1em;
              margin-left: 5px;
              margin-right: 5px; }
            .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls .left .duration {
              font-family: CiscoSans;
              margin-top: 2px;
              font-size: 1em;
              color: #ffffff; }
          .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls .right {
            display: flex; }
            .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls .right .volume .icon {
              color: #ffffff;
              cursor: pointer;
              font-size: 0.75em;
              margin-left: 5px;
              margin-right: 5px; }
            .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls .right .download .icon {
              color: #ffffff;
              font-size: 0.75em;
              margin-left: 5px;
              margin-right: 5px; }
      .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container video {
        background-size: cover;
        overflow: hidden;
        border-radius: 11px; }
  .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards {
    height: 800px;
    width: 101%;
    overflow-y: scroll; }
  .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card {
    width: 315px;
    height: 315px; }
    .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link {
      font-family: CiscoSans; }
      .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link .gallery-src {
        position: absolute;
        top: 10px;
        left: 85%;
        width: 30px;
        height: 30px;
        z-index: 9; }
        .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link .gallery-src i {
          display: none; }
        .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link .gallery-src.src-instagram {
          background-color: #ffffff00;
          background-image: url(./../assets/instagram-logo.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          width: 35px;
          height: 35px; }
        .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link .gallery-src.src-twitter {
          background-color: #ffffff00;
          background-image: url(./../assets/twitter-blue.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 30px;
          width: 35px;
          height: 35px; }
        .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link .gallery-src.src-fankave {
          background-image: url(./../assets/logo-small-blue.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center; }
          .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link .gallery-src.src-fankave img {
            display: none; }
      .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link:hover .gallery-details {
        height: 315px; }
        .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link:hover .gallery-details img {
          position: absolute;
          top: 40%;
          left: 40%;
          width: 30px;
          height: 30px; }
        .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link:hover .gallery-details .gallery-src {
          display: none; }
        .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link:hover .gallery-details .gallery-msg {
          text-align: left;
          position: absolute;
          top: 40px;
          left: 5px;
          display: block;
          font-size: 16px;
          line-height: 1.25;
          letter-spacing: 0px;
          margin-left: 20px;
          margin-right: 20px;
          font-family: CiscoSans; }
        .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link:hover .gallery-details .gallery-stats {
          display: block;
          text-align: right;
          margin-top: 35%; }
          .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link:hover .gallery-details .gallery-stats i {
            display: none; }
          .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link:hover .gallery-details .gallery-stats .heart {
            display: inline-block;
            background-image: url(./../assets/heart-regular.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 20px;
            height: 20px;
            margin-right: 2px; }
          .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link:hover .gallery-details .gallery-stats .retweet {
            display: inline-block;
            background-image: url(./../assets/retweet-solid.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 20px;
            height: 20px;
            margin-right: 2px; }
          .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link:hover .gallery-details .gallery-stats .retweet-count {
            display: inline-block;
            vertical-align: top;
            padding-right: 0px; }
          .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link:hover .gallery-details .gallery-stats .like-count {
            display: inline-block;
            vertical-align: top;
            padding-right: 0px; }
        .cisco-store .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .no-link:hover .gallery-details .view-post {
          display: none; }

.cisco-store .carousel-container {
  overflow-x: auto;
  overflow-y: hidden; }
  .cisco-store .carousel-container .previous {
    display: block;
    background-image: url(./../assets/previous.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 30px;
    height: 30px;
    margin-top: 160px;
    margin-left: 10px; }
    .cisco-store .carousel-container .previous i {
      display: none; }
  .cisco-store .carousel-container .carousel {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    max-height: 325px;
    justify-content: center;
    overflow-y: hidden; }
    .cisco-store .carousel-container .carousel .cardv2 {
      position: relative; }
      .cisco-store .carousel-container .carousel .cardv2 .image {
        width: 325px;
        height: 325px; }
        .cisco-store .carousel-container .carousel .cardv2 .image .source {
          z-index: 10; }
          .cisco-store .carousel-container .carousel .cardv2 .image .source i {
            display: none; }
          .cisco-store .carousel-container .carousel .cardv2 .image .source .src-instagram {
            background-color: #ffffff00;
            background-image: url(./../assets/instagram-logo.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 35px;
            height: 35px; }
          .cisco-store .carousel-container .carousel .cardv2 .image .source .src-twitter {
            background-color: #ffffff00;
            background-image: url(./../assets/twitter-blue.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 30px;
            width: 35px;
            height: 35px; }
          .cisco-store .carousel-container .carousel .cardv2 .image .source .src-fankave {
            background-color: #ffffff00;
            background-image: url(./../assets/logo-small-blue.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 35px;
            height: 35px; }
            .cisco-store .carousel-container .carousel .cardv2 .image .source .src-fankave img {
              display: none; }
      .cisco-store .carousel-container .carousel .cardv2 .details {
        position: absolute;
        top: 1px;
        left: 1px;
        margin: -1px 0px 0px -1px;
        max-width: 325px;
        max-height: 325px;
        height: 100%;
        width: 100%;
        background-color: #000000c9; }
        .cisco-store .carousel-container .carousel .cardv2 .details .author {
          display: none; }
        .cisco-store .carousel-container .carousel .cardv2 .details .msg {
          margin-left: 10px;
          text-align: left;
          margin-top: 40px;
          display: block;
          color: #ffffff;
          font-size: 16px;
          line-height: 1.25;
          letter-spacing: 0px; }
      .cisco-store .carousel-container .carousel .cardv2:hover .details {
        display: block; }
      .cisco-store .carousel-container .carousel .cardv2:hover .stats {
        bottom: 30px;
        display: block;
        border-top: none;
        text-align: right;
        margin-top: 35%;
        font-size: 16px; }
        .cisco-store .carousel-container .carousel .cardv2:hover .stats span {
          padding-right: 5px; }
        .cisco-store .carousel-container .carousel .cardv2:hover .stats i {
          display: none; }
        .cisco-store .carousel-container .carousel .cardv2:hover .stats .heart {
          display: inline-block;
          background-image: url(./../assets/heart-regular.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          margin-top: 3px; }
        .cisco-store .carousel-container .carousel .cardv2:hover .stats .retweet {
          display: inline-block;
          background-image: url(./../assets/retweet-solid.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          margin-top: 3px; }
        .cisco-store .carousel-container .carousel .cardv2:hover .stats .retweet-count {
          display: inline-block;
          vertical-align: top;
          padding-right: 1px;
          padding-top: 0px; }
        .cisco-store .carousel-container .carousel .cardv2:hover .stats .like-count {
          display: inline-block;
          vertical-align: top;
          padding-right: 1px;
          padding-top: 0px; }
  .cisco-store .carousel-container .next {
    display: block;
    background-image: url(./../assets/next.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 30px;
    height: 30px;
    margin-top: 160px;
    margin-right: 10px; }
    .cisco-store .carousel-container .next i {
      display: none; }

.cisco-partner-summit .gallery-scroll-parent-container {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1430px; }
  .cisco-partner-summit .gallery-scroll-parent-container .header {
    display: none;
    padding-top: 25px; }
    .cisco-partner-summit .gallery-scroll-parent-container .header .action {
      display: none; }
    .cisco-partner-summit .gallery-scroll-parent-container .header .gallery-scroll-parent-title {
      display: none; }
  .cisco-partner-summit .gallery-scroll-parent-container .scroll-to-top {
    background-image: url(./../assets/arrow-up.svg);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 10px 6px;
    font-size: 1em;
    display: block;
    z-index: 20; }
    .cisco-partner-summit .gallery-scroll-parent-container .scroll-to-top i {
      display: none; }
    .cisco-partner-summit .gallery-scroll-parent-container .scroll-to-top.hide {
      display: none; }
  .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card-parent {
    position: absolute;
    z-index: 10;
    max-width: 620px;
    max-height: 620px; }
    .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card-parent.portrait {
      top: 15%;
      left: 35%; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card-parent.portrait video {
        height: 640px; }
    .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card-parent.landscape {
      top: 30%;
      left: 30%; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card-parent.landscape video {
        width: 580px; }
  .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card {
    z-index: 10; }
    .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .close {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 25px;
      height: 25px;
      z-index: 20;
      background-image: url(./../assets/close.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      cursor: pointer; }
    .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container {
        margin-top: -50px;
        width: 95%;
        height: 50px;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .closed-captions {
          text-align: center;
          position: absolute;
          bottom: 15px;
          margin-bottom: 5px; }
          .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .closed-captions .cc {
            text-align: center;
            color: #ffffff;
            background-color: #00000052;
            padding: 3px;
            font-family: CiscoSans;
            font-size: 0.85em; }
        .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls {
          display: flex;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          justify-content: space-between;
          align-items: center; }
          .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls .left {
            display: flex; }
            .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls .left .play-pause .icon {
              color: #ffffff;
              cursor: pointer;
              font-size: 1em;
              margin-left: 5px;
              margin-right: 5px; }
            .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls .left .duration {
              font-family: CiscoSans;
              margin-top: 2px;
              font-size: 1em;
              color: #ffffff; }
          .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls .right {
            display: flex; }
            .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls .right .volume .icon {
              color: #ffffff;
              cursor: pointer;
              font-size: 0.75em;
              margin-left: 5px;
              margin-right: 5px; }
            .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container .controls-container .controls .right .download .icon {
              color: #ffffff;
              font-size: 0.75em;
              margin-left: 5px;
              margin-right: 5px; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .video-card .video-container video {
        background-size: cover;
        overflow: hidden;
        border-radius: 11px; }
  .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards {
    height: 800px;
    width: 101%;
    overflow-y: scroll; }
  .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card {
    width: 315px;
    height: 315px; }
    .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .gallery-src {
      position: absolute;
      top: 10px;
      left: 85%;
      width: 30px;
      height: 30px;
      z-index: 9; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .gallery-src i {
        display: none; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .gallery-src.src-instagram {
        background-color: #ffffff00;
        background-image: url(./../assets/instagram-logo.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 35px;
        height: 35px; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .gallery-src.src-twitter {
        background-color: #ffffff00;
        background-image: url(./../assets/twitter-blue.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 30px;
        width: 35px;
        height: 35px; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .gallery-src.src-fankave {
        background-image: url(./../assets/logo-small-blue.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; }
        .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card .gallery-src.src-fankave img {
          display: none; }
    .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card:hover .gallery-details {
      height: 315px; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card:hover .gallery-details img {
        position: absolute;
        top: 40%;
        left: 40%;
        width: 30px;
        height: 30px; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card:hover .gallery-details .gallery-src {
        display: none; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card:hover .gallery-details .gallery-msg {
        text-align: left;
        position: absolute;
        top: 40px;
        left: 5px;
        display: block;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 0px;
        margin-left: 20px;
        margin-right: 20px; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card:hover .gallery-details .gallery-stats {
        display: block;
        text-align: right;
        margin-top: 35%; }
        .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card:hover .gallery-details .gallery-stats i {
          display: none; }
        .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card:hover .gallery-details .gallery-stats .heart {
          display: inline-block;
          background-image: url(./../assets/heart-regular.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 20px;
          height: 20px;
          margin-right: 2px; }
        .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card:hover .gallery-details .gallery-stats .retweet {
          display: inline-block;
          background-image: url(./../assets/retweet-solid.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 20px;
          height: 20px;
          margin-right: 2px; }
        .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card:hover .gallery-details .gallery-stats .retweet-count {
          display: inline-block;
          vertical-align: top;
          padding-right: 0px; }
        .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card:hover .gallery-details .gallery-stats .like-count {
          display: inline-block;
          vertical-align: top;
          padding-right: 0px; }
      .cisco-partner-summit .gallery-scroll-parent-container .gallery-scroll-container .gallery-card:hover .gallery-details .view-post {
        display: none; }

.cisco-partner-summit .carousel-container {
  overflow-x: auto;
  overflow-y: hidden; }
  .cisco-partner-summit .carousel-container .previous {
    display: block;
    background-image: url(./../assets/previous.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 30px;
    height: 30px;
    margin-top: 160px;
    margin-left: 10px; }
    .cisco-partner-summit .carousel-container .previous i {
      display: none; }
  .cisco-partner-summit .carousel-container .carousel {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    max-height: 325px;
    justify-content: center;
    overflow-y: hidden; }
    .cisco-partner-summit .carousel-container .carousel .cardv2 {
      position: relative; }
      .cisco-partner-summit .carousel-container .carousel .cardv2 .image, .cisco-partner-summit .carousel-container .carousel .cardv2 .text {
        width: 325px;
        height: 325px; }
        .cisco-partner-summit .carousel-container .carousel .cardv2 .image .source, .cisco-partner-summit .carousel-container .carousel .cardv2 .text .source {
          z-index: 10; }
          .cisco-partner-summit .carousel-container .carousel .cardv2 .image .source i, .cisco-partner-summit .carousel-container .carousel .cardv2 .text .source i {
            display: none; }
          .cisco-partner-summit .carousel-container .carousel .cardv2 .image .source .src-instagram, .cisco-partner-summit .carousel-container .carousel .cardv2 .text .source .src-instagram {
            background-color: #ffffff00;
            background-image: url(https://storage.googleapis.com/animable/ciscolive/us2024/instagram-logo.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 35px;
            height: 35px; }
          .cisco-partner-summit .carousel-container .carousel .cardv2 .image .source .src-twitter, .cisco-partner-summit .carousel-container .carousel .cardv2 .text .source .src-twitter {
            background-color: #ffffff00;
            background-image: url(https://storage.googleapis.com/animable/ciscolive/us2024/x-logo-border.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 30px;
            width: 35px;
            height: 35px; }
          .cisco-partner-summit .carousel-container .carousel .cardv2 .image .source .src-fankave, .cisco-partner-summit .carousel-container .carousel .cardv2 .text .source .src-fankave {
            background-color: #ffffff00;
            background-image: url(./../assets/logo-small-blue.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 35px;
            height: 35px; }
            .cisco-partner-summit .carousel-container .carousel .cardv2 .image .source .src-fankave img, .cisco-partner-summit .carousel-container .carousel .cardv2 .text .source .src-fankave img {
              display: none; }
      .cisco-partner-summit .carousel-container .carousel .cardv2 .details {
        position: absolute;
        top: 1px;
        left: 1px;
        margin: -1px 0px 0px -1px;
        max-width: 325px;
        max-height: 325px;
        height: 100%;
        width: 100%;
        background-color: #000000c9; }
        .cisco-partner-summit .carousel-container .carousel .cardv2 .details .author {
          display: none; }
        .cisco-partner-summit .carousel-container .carousel .cardv2 .details .msg {
          margin-left: 10px;
          text-align: left;
          margin-top: 40px;
          display: block;
          color: #ffffff;
          font-size: 16px;
          line-height: 1.25;
          letter-spacing: 0px; }
      .cisco-partner-summit .carousel-container .carousel .cardv2:hover .details {
        display: block; }
      .cisco-partner-summit .carousel-container .carousel .cardv2:hover .stats {
        bottom: 30px;
        display: block;
        border-top: none;
        text-align: right;
        margin-top: 35%;
        font-size: 16px; }
        .cisco-partner-summit .carousel-container .carousel .cardv2:hover .stats span {
          padding-right: 5px; }
        .cisco-partner-summit .carousel-container .carousel .cardv2:hover .stats i {
          display: none; }
        .cisco-partner-summit .carousel-container .carousel .cardv2:hover .stats .heart {
          display: inline-block;
          background-image: url(./../assets/heart-regular.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          margin-top: 3px; }
        .cisco-partner-summit .carousel-container .carousel .cardv2:hover .stats .retweet {
          display: inline-block;
          background-image: url(./../assets/retweet-solid.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          margin-top: 3px; }
        .cisco-partner-summit .carousel-container .carousel .cardv2:hover .stats .retweet-count {
          display: inline-block;
          vertical-align: top;
          padding-right: 1px;
          padding-top: 0px; }
        .cisco-partner-summit .carousel-container .carousel .cardv2:hover .stats .like-count {
          display: inline-block;
          vertical-align: top;
          padding-right: 1px;
          padding-top: 0px; }
  .cisco-partner-summit .carousel-container .next {
    display: block;
    background-image: url(./../assets/next.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 30px;
    height: 30px;
    margin-top: 160px;
    margin-right: 10px; }
    .cisco-partner-summit .carousel-container .next i {
      display: none; }

.ciscoliveus2023 {
  max-width: 1240px;
  max-height: 760px;
  overflow-x: hidden;
  overflow-y: auto; }
  .ciscoliveus2023::-webkit-scrollbar {
    width: 3px;
    background-color: #071a33;
    background: #071a33; }
  .ciscoliveus2023::-webkit-scrollbar-thumb {
    width: 2px;
    height: 5px;
    background-color: #fff;
    background: #00bceb; }
  .ciscoliveus2023 .lounge-container {
    background-color: #13284c;
    background-image: none;
    color: #f5f5f5;
    padding: 46px 24px;
    display: flex;
    flex-direction: column; }
    .ciscoliveus2023 .lounge-container .lounge-header {
      background-color: transparent; }
      .ciscoliveus2023 .lounge-container .lounge-header .title {
        font-size: 3.75em;
        line-height: 1.5;
        font-family: CiscoSansThin;
        text-align: center; }
      .ciscoliveus2023 .lounge-container .lounge-header .action {
        display: none; }
        .ciscoliveus2023 .lounge-container .lounge-header .action .icon-fk-twitter-1 {
          display: none; }
    .ciscoliveus2023 .lounge-container .lounge-content {
      height: 575px;
      background-color: transparent; }
      .ciscoliveus2023 .lounge-container .lounge-content .lounge-description {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .ciscoliveus2023 .lounge-container .lounge-content .lounge-description .description {
          font-size: 1em;
          font-family: CiscoSansLight;
          text-align: center;
          line-height: 1.5;
          width: 75%; }
        .ciscoliveus2023 .lounge-container .lounge-content .lounge-description .virtual-booth {
          display: none; }
      .ciscoliveus2023 .lounge-container .lounge-content .interactive-section {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 10px;
        justify-content: space-evenly; }
        .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .one0 {
          padding: 32px 16px;
          background-image: url(./../assets/ciscoliveus2023/highlights-bg2.png);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 500px;
          height: 260px;
          display: flex; }
        .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .two1 {
          padding: 32px 16px;
          background-image: url(./../assets/ciscoliveus2023/gallery-bg2.png);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 500px;
          height: 260px;
          display: flex; }
        .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .three2 {
          padding: 32px 16px;
          background-image: url(./../assets/ciscoliveus2023/badge-bg2.png);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 500px;
          height: 260px;
          display: flex; }
        .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .four3 {
          padding: 32px 16px;
          background-image: url(./../assets/ciscoliveus2023/leaderboard-bg2.png);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 500px;
          height: 260px;
          display: flex; }
        .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button {
          width: 100%;
          height: 100%;
          cursor: pointer;
          border-radius: 1%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: static; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.one {
            background-color: #ffffff;
            color: #0c1654;
            width: 100%;
            height: 100%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.one .interaction-icon-fk {
              background-image: url(./../assets/ciscoliveus2023/highlights.png); }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.one .interaction-title {
              width: 220px;
              text-align: center; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.two {
            background-color: #ffffff;
            color: #0c1654;
            width: 100%;
            height: 100%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.two .interaction-icon-fk {
              background-image: url(./../assets/ciscoliveus2023/gallery.png); }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.two .interaction-title {
              width: 209px;
              text-align: left;
              margin-left: 35px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.three {
            background-color: #ffffff;
            color: #0c1654;
            width: 100%;
            height: 100%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.three .interaction-icon-fk {
              background-image: url(./../assets/ciscoliveus2023/badge.png); }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.three .interaction-title {
              width: 220px;
              text-align: center; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.four {
            background-color: #ffffff;
            color: #0c1654;
            width: 100%;
            height: 100%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.four .interaction-icon-fk {
              background-image: url(./../assets/ciscoliveus2023/leaderboards.png); }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.four .interaction-title {
              width: 220px;
              text-align: center; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
            width: 77px;
            height: 74px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: 0px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-description .interaction-title {
            font-family: CiscoSansLight;
            font-size: 1.75em;
            line-height: 1; }
  .ciscoliveus2023 .leaderboard-container {
    background-color: #13284c;
    color: #ffffff; }
    .ciscoliveus2023 .leaderboard-container .spinner {
      left: 30%; }
    .ciscoliveus2023 .leaderboard-container .header {
      margin-top: 25px;
      position: absolute;
      height: 90px;
      background-color: #13284c; }
      .ciscoliveus2023 .leaderboard-container .header .back .back-label {
        text-decoration: none; }
      .ciscoliveus2023 .leaderboard-container .header .back .icon-fk {
        display: block; }
    .ciscoliveus2023 .leaderboard-container .leaderboard-content {
      width: 100vw;
      background-color: #13284c;
      max-height: 760px;
      overflow-y: auto; }
      .ciscoliveus2023 .leaderboard-container .leaderboard-content::-webkit-scrollbar {
        width: 3px;
        background-color: #071a33;
        background: #071a33; }
      .ciscoliveus2023 .leaderboard-container .leaderboard-content::-webkit-scrollbar-thumb {
        width: 2px;
        height: 5px;
        background-color: #fff;
        background: #00bceb; }
      .ciscoliveus2023 .leaderboard-container .leaderboard-content .post .post-count {
        display: none; }
      .ciscoliveus2023 .leaderboard-container .leaderboard-content .top {
        margin-top: 12%;
        padding-top: 40px;
        margin-bottom: 25px;
        padding-bottom: 20px;
        background-color: #1669aa;
        border-radius: 150px;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 3%;
        margin-right: 3%; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post:nth-child(1) .post-body .medal {
          background: url(./../assets/gold-star.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post:nth-child(1) .post-body .post-content .rank::after {
          content: "st"; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post:nth-child(2) .post-body .medal {
          background: url(./../assets/silver-star.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post:nth-child(2) .post-body .rank::after {
          content: "nd"; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post:nth-child(3) .post-body .medal {
          background: url(./../assets/bronze-star.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post:nth-child(3) .post-body .rank::after {
          content: "rd"; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-img {
          background-image: none;
          border-radius: 100%; }
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-img img {
            border-radius: 100%; }
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-img .pic {
            border-radius: 100px;
            margin-left: 20%; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-body .medal {
          top: -170px;
          left: 10px; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-body .post-content {
          color: #ffffff; }
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-body .post-content .author-handle {
            margin-top: 0px;
            font-family: CiscoSans;
            font-weight: 500;
            font-size: 1em; }
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-body .post-content .author-name {
            margin-top: 0px;
            font-family: CiscoSans;
            font-weight: 300; }
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-body .post-content .rank {
            top: -150px;
            font-size: 1.4em;
            left: 25px;
            font-family: CiscoSansBold; }
            .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-body .post-content .rank::after {
              font-size: 15px;
              vertical-align: super; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-footer {
          margin-top: 0px; }
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-footer .post-count {
            margin-right: 10px; }
            .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-footer .post-count .icon-fk-twitter-1 {
              font-size: 20px; }
            .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post .post-footer .post-count span {
              font-size: 20px;
              font-family: CiscoSansBold; }
      .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest {
        padding-top: 0px;
        background-color: transparent;
        justify-content: space-evenly; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post:nth-child(1) .post-body .medal {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post:nth-child(2) .post-body .medal {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post:nth-child(3) .post-body .medal {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-img {
          background-image: none;
          border-radius: 100%; }
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-img img {
            border-radius: 100%; }
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-img .pic {
            border-radius: 100px;
            margin-left: 17%; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-body .medal {
          top: -189px;
          left: 0px; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-content {
          color: #ffffff; }
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-content .author-handle {
            font-family: CiscoSans;
            font-weight: 500;
            margin-top: 0px;
            font-size: 1em; }
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-content .author-name {
            margin-top: 0px;
            font-family: CiscoSans;
            font-weight: 300; }
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-content .rank {
            font-size: 1.4em;
            top: -160px;
            left: 28px;
            font-family: CiscoSansBold; }
            .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-content .rank::after {
              content: "th";
              font-size: 15px;
              vertical-align: super; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-footer {
          margin-top: 0px; }
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-footer .post-count {
            margin-right: 20px; }
            .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-footer .post-count .icon-fk-twitter-1 {
              font-size: 20px; }
            .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-footer .post-count span {
              font-size: 20px;
              font-family: CiscoSansBold; }
      .ciscoliveus2023 .leaderboard-container .leaderboard-content .btn {
        display: block; }
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .btn .cisco-btn {
          margin-top: 35px; }
  .ciscoliveus2023 .spinner {
    z-index: 100;
    position: absolute;
    top: 45%;
    left: 45%; }
    .ciscoliveus2023 .spinner:after {
      content: " ";
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 5px solid #fff;
      border-color: #adadad transparent #adadad transparent;
      animation: spin 1.2s linear infinite; }
    .ciscoliveus2023 .spinner.hide {
      display: none; }
  .ciscoliveus2023 .new-highlights-container-scroll {
    background-color: #13284c;
    color: #ffffff; }
    .ciscoliveus2023 .new-highlights-container-scroll .header {
      position: absolute;
      z-index: 10;
      display: flex;
      height: 125px;
      max-width: 1240px;
      width: 100%;
      align-items: center;
      background-color: #13284c;
      padding: 0px; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .back {
        display: flex;
        flex-grow: 0;
        text-align: center;
        margin-left: 10px;
        cursor: pointer; }
        .ciscoliveus2023 .new-highlights-container-scroll .header .back .icon-fk {
          display: block; }
        .ciscoliveus2023 .new-highlights-container-scroll .header .back .back-label {
          text-decoration: none;
          font-size: 1.15em;
          font-family: CiscoSansTT; }
        .ciscoliveus2023 .new-highlights-container-scroll .header .back .back-label-mobile {
          display: none; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .title {
        font-size: 3.75em;
        font-family: CiscoSansThin;
        text-align: center;
        flex-grow: 100; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .action {
        font-family: CiscoSansBold;
        font-size: 1.15em;
        background-color: #00bceb;
        padding: 10px 30px 8px 30px;
        border-radius: 25px;
        flex-grow: 0;
        text-align: center;
        margin-right: 10px;
        cursor: pointer; }
    .ciscoliveus2023 .new-highlights-container-scroll .sub-header {
      display: none; }
    .ciscoliveus2023 .new-highlights-container-scroll .cards {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 125px;
      height: 600px;
      background-color: #13284c; }
      .ciscoliveus2023 .new-highlights-container-scroll .cards::-webkit-scrollbar {
        width: 3px;
        background-color: #071a33;
        background: #071a33; }
      .ciscoliveus2023 .new-highlights-container-scroll .cards::-webkit-scrollbar-thumb {
        width: 2px;
        height: 5px;
        background-color: #fff;
        background: #00bceb; }
      .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 {
        width: 290px;
        height: 425px; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .created-at {
          top: 55px; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .source {
          position: absolute;
          top: 5px;
          left: 260px; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .source .src-twitter {
            width: 25px;
            height: 25px;
            font-size: 1.25em; }
            .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .source .src-twitter .icon-fk-twitter-1 {
              top: 0px;
              left: -1px; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .source .src-instagram {
            width: 25px;
            height: 25px; }
            .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .source .src-instagram .icon-fk-instagram-1 {
              top: -2px;
              left: -2px;
              font-size: 1.25em; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2.portrait .image {
          height: 250px;
          width: 290px;
          max-height: 250px; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2.portrait .details {
          max-height: 200px;
          width: 280px;
          max-width: 280px;
          margin-left: auto;
          margin-right: auto; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2.portrait .details .msg {
            height: 100px; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2.portrait .source .src-instagram .icon-fk-instagram-1 {
          top: 4px;
          left: -2px; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .image {
          max-height: 250px;
          height: 175px;
          width: 290px;
          background-repeat: no-repeat;
          background-position: 0px 0px; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .details {
          display: block;
          max-height: 275px;
          width: 280px;
          max-width: 280px;
          margin-left: auto;
          margin-right: auto; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .details .msg {
            display: block;
            height: 180px;
            font-family: CiscoSansLight; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .stats {
          justify-content: space-between;
          align-items: center;
          color: #02b1e4;
          width: 270px; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .stats .retweet-icon-fk {
            color: #02b1e4;
            font-size: 1.1em; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .stats .retweet-count {
            color: #02b1e4;
            font-size: 1.1em; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .stats .like-icon-fk {
            color: #02b1e4;
            font-size: 1.1em; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .stats .like-count {
            color: #02b1e4;
            font-size: 1.1em; }
      .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed {
        width: 290px;
        height: 425px;
        max-height: 425px; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .created-at {
          right: 0px;
          left: 75%; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .source {
          position: absolute;
          top: 5px;
          left: 250px; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .source .src-twitter {
            width: 25px;
            height: 25px;
            font-size: 1.25em; }
            .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .source .src-twitter .icon-fk-twitter-1 {
              top: -1px;
              left: -1px;
              color: #00a4e8; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .source .src-instagram {
            width: 25px;
            height: 25px; }
            .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .source .src-instagram .icon-fk-instagram-1 {
              top: -2px;
              left: 0px;
              font-size: 1.25em; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .image {
          max-height: 250px;
          height: 250px;
          width: 225px; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .details {
          display: block;
          max-height: 375px; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .details .msg {
            width: 260px;
            display: block;
            height: 90%;
            font-family: CiscoSansLight; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .stats {
          left: 5px;
          bottom: 2px;
          justify-content: space-between;
          align-items: center;
          color: #02b1e4; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .stats .retweet-icon-fk {
            color: #02b1e4;
            font-size: 1.1em; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .stats .retweet-count {
            color: #02b1e4;
            font-size: 1.1em; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .stats .like-icon-fk {
            color: #02b1e4;
            font-size: 1.1em; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed .stats .like-count {
            color: #02b1e4;
            font-size: 1.1em; }
      .ciscoliveus2023 .new-highlights-container-scroll .cards .spinner-loading {
        z-index: 100;
        position: absolute;
        bottom: 45%;
        left: 45%; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .spinner-loading:after {
          content: " ";
          display: block;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          border: 5px solid #fff;
          border-color: #adadad transparent #adadad transparent;
          animation: spin 1.2s linear infinite; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .spinner-loading.hide {
          display: none; }
    .ciscoliveus2023 .new-highlights-container-scroll .scroll-to-top {
      background-color: #00bceb;
      width: 40px;
      height: 40px; }
      .ciscoliveus2023 .new-highlights-container-scroll .scroll-to-top.icon-fk-up-1 {
        font-size: 1.25em;
        padding-top: 0px; }
  .ciscoliveus2023 .gallery-scroll-parent-container {
    position: relative;
    background-color: #13284c;
    color: #ffffff; }
    .ciscoliveus2023 .gallery-scroll-parent-container .spinner {
      left: 30%; }
    .ciscoliveus2023 .gallery-scroll-parent-container .header {
      position: absolute;
      background-color: #13284c; }
      .ciscoliveus2023 .gallery-scroll-parent-container .header .back .back-label {
        text-decoration: none; }
      .ciscoliveus2023 .gallery-scroll-parent-container .header .back .icon-fk {
        display: block; }
    .ciscoliveus2023 .gallery-scroll-parent-container .scroll-to-top {
      z-index: 10;
      line-height: 2;
      background-color: #00bceb;
      padding-top: 0px; }
      .ciscoliveus2023 .gallery-scroll-parent-container .scroll-to-top .icon-fk-up-1 {
        font-size: 1.25em;
        padding-top: 10px; }
    .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container {
      padding-top: 120px; }
      .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .spinner {
        left: 45%; }
      .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .spinner-loading {
        z-index: 100;
        position: absolute;
        bottom: 45%;
        left: 45%; }
        .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .spinner-loading:after {
          content: " ";
          display: block;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          border: 5px solid #fff;
          border-color: #adadad transparent #adadad transparent;
          animation: spin 1.2s linear infinite; }
        .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .spinner-loading.hide {
          display: none; }
      .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards {
        overflow: auto;
        height: 640px;
        width: 100%;
        background-color: #13284c; }
        .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .spinner {
          left: 45%; }
        .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards::-webkit-scrollbar {
          width: 5px;
          background-color: #13284c;
          background: #13284c; }
        .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards::-webkit-scrollbar-thumb {
          width: 2px;
          height: 5px;
          background-color: #fff;
          background: #00bceb; }
        .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card {
          border: none;
          background-repeat: no-repeat;
          background-position: 0px -50px; }
          .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details {
            cursor: auto; }
            .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats {
              display: none;
              font-family: CiscoSans;
              background-color: #ffffff;
              color: #04a6e1;
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 5px;
              right: 5%; }
              .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .reply {
                cursor: pointer; }
                .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .reply .reply-icon-fk {
                  font-size: 18px; }
              .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .retweet .icon-fk-retweet {
                font-size: 20px;
                cursor: pointer; }
              .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .retweet .retweet-count {
                font-size: 18px;
                padding: 0px;
                margin: 0px 0px 0px 0px; }
              .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .like {
                margin-right: 5px; }
                .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .like .icon-fk-heart-empty-1 {
                  font-size: 20px;
                  cursor: pointer; }
                .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .like .like-count {
                  font-size: 18px;
                  padding: 0px;
                  margin: 0px 0px 0px 0px; }
            .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .view-post {
              height: 35px;
              cursor: pointer;
              width: 60%;
              margin-left: 17%;
              padding-top: 9px;
              padding-right: 5px;
              padding-bottom: 5px;
              padding-left: 5px;
              font-family: CiscoSansBold;
              font-size: 17px;
              background-color: #04a6e1; }
          .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src {
            display: none; }
            .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-twitter {
              width: 30px;
              height: 30px;
              top: 10px;
              left: 200px; }
              .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-twitter .icon-fk-twitter-1 {
                top: 7px;
                left: 1px; }
            .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-fankave {
              background-color: #ffffff;
              border-radius: 50px;
              width: 30px;
              height: 30px;
              top: 5px;
              left: 205px; }
              .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-fankave img {
                margin-top: 7px;
                margin-left: 7px;
                width: 20px;
                height: 15px; }
              .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-fankave .icon-fk-twitter-1 {
                top: 7px;
                left: 1px; }
            .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-instagram {
              width: 30px;
              height: 30px;
              top: 10px;
              left: 200px; }
              .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-instagram .icon-fk-instagram-1 {
                top: 7px;
                left: 0px; }
          .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card.insta:hover .gallery-details .gallery-stats {
            display: none; }
          .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card.insta:hover .gallery-details .view-post {
            bottom: 100px; }
  .ciscoliveus2023 .badge-container {
    background-color: #13284c;
    background-image: none; }
    .ciscoliveus2023 .badge-container .header {
      margin-top: 25px; }
      .ciscoliveus2023 .badge-container .header .title {
        background-color: #13284c; }
      .ciscoliveus2023 .badge-container .header .back-label {
        font-family: CiscoSansTT;
        font-size: 18px; }
    .ciscoliveus2023 .badge-container .submit {
      border-radius: 25px; }
    .ciscoliveus2023 .badge-container .fk-container .working-space .download {
      border-radius: 25px; }

.ciscoliveapjc2023 {
  max-width: 1240px;
  max-height: 760px;
  overflow-x: hidden;
  overflow-y: auto; }
  .ciscoliveapjc2023::-webkit-scrollbar {
    width: 3px;
    background-color: #071a33;
    background: #071a33; }
  .ciscoliveapjc2023::-webkit-scrollbar-thumb {
    width: 2px;
    height: 5px;
    background-color: #fff;
    background: #00bceb; }
  .ciscoliveapjc2023 .lounge-container {
    background-color: #13284c;
    background-image: none;
    color: #f5f5f5;
    padding: 46px 24px;
    display: flex;
    flex-direction: column; }
    .ciscoliveapjc2023 .lounge-container .lounge-header {
      background-color: transparent; }
      .ciscoliveapjc2023 .lounge-container .lounge-header .title {
        font-size: 3.75em;
        line-height: 1.5;
        font-family: CiscoSansThin;
        text-align: center; }
      .ciscoliveapjc2023 .lounge-container .lounge-header .action {
        display: none; }
        .ciscoliveapjc2023 .lounge-container .lounge-header .action .icon-fk-twitter-1 {
          display: none; }
    .ciscoliveapjc2023 .lounge-container .lounge-content {
      height: 575px;
      background-color: transparent; }
      .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description .description {
          font-size: 1em;
          font-family: CiscoSansLight;
          text-align: center;
          line-height: 1.5;
          width: 75%; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description .virtual-booth {
          display: none; }
      .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section {
        position: relative;
        margin-top: 5%;
        width: 100%;
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .one0 {
          padding: 32px 16px;
          background-image: url(./../assets/ciscoliveus2023/highlights-bg2.png);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 500px;
          height: 260px;
          display: flex; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .two1 {
          padding: 32px 16px;
          background-image: url(./../assets/ciscoliveus2023/gallery-bg2.png);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 500px;
          height: 260px;
          display: none; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .three2 {
          padding: 32px 16px;
          background-image: url(./../assets/ciscoliveus2023/badge-bg2.png);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 500px;
          height: 260px;
          display: none; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .four3 {
          padding: 32px 16px;
          background-image: url(./../assets/ciscoliveus2023/leaderboard-bg2.png);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 500px;
          height: 260px;
          display: none; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button {
          width: 100%;
          height: 100%;
          cursor: pointer;
          border-radius: 1%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: static; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.one {
            background-color: #ffffff;
            color: #0c1654;
            width: 100%;
            height: 100%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.one .interaction-icon-fk {
              background-image: url(./../assets/ciscoliveus2023/highlights.png); }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.one .interaction-title {
              width: 220px;
              text-align: center; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.two {
            background-color: #ffffff;
            color: #0c1654;
            width: 100%;
            height: 100%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.two .interaction-icon-fk {
              background-image: url(./../assets/ciscoliveus2023/gallery.png); }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.two .interaction-title {
              width: 209px;
              text-align: left;
              margin-left: 35px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.three {
            background-color: #ffffff;
            color: #0c1654;
            width: 100%;
            height: 100%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.three .interaction-icon-fk {
              background-image: url(./../assets/ciscoliveus2023/badge.png); }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.three .interaction-title {
              width: 220px;
              text-align: center; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.four {
            background-color: #ffffff;
            color: #0c1654;
            width: 100%;
            height: 100%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.four .interaction-icon-fk {
              background-image: url(./../assets/ciscoliveus2023/leaderboards.png); }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.four .interaction-title {
              width: 220px;
              text-align: center; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
            width: 77px;
            height: 74px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-bottom: 0px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-description .interaction-title {
            font-family: CiscoSansLight;
            font-size: 1.75em;
            line-height: 1; }
  .ciscoliveapjc2023 .leaderboard-container {
    background-color: #13284c;
    color: #ffffff; }
    .ciscoliveapjc2023 .leaderboard-container .spinner {
      left: 30%; }
    .ciscoliveapjc2023 .leaderboard-container .header {
      margin-top: 25px;
      position: absolute;
      height: 90px;
      background-color: #13284c; }
      .ciscoliveapjc2023 .leaderboard-container .header .back .back-label {
        text-decoration: none; }
      .ciscoliveapjc2023 .leaderboard-container .header .back .icon-fk {
        display: block; }
    .ciscoliveapjc2023 .leaderboard-container .leaderboard-content {
      width: 100vw;
      background-color: #13284c;
      max-height: 760px;
      overflow-y: auto; }
      .ciscoliveapjc2023 .leaderboard-container .leaderboard-content::-webkit-scrollbar {
        width: 3px;
        background-color: #071a33;
        background: #071a33; }
      .ciscoliveapjc2023 .leaderboard-container .leaderboard-content::-webkit-scrollbar-thumb {
        width: 2px;
        height: 5px;
        background-color: #fff;
        background: #00bceb; }
      .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .post .post-count {
        display: none; }
      .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top {
        margin-top: 12%;
        padding-top: 40px;
        margin-bottom: 25px;
        padding-bottom: 20px;
        background-color: #1669aa;
        border-radius: 150px;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 3%;
        margin-right: 3%; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post:nth-child(1) .post-body .medal {
          background: url(./../assets/gold-star.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post:nth-child(1) .post-body .post-content .rank::after {
          content: "st"; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post:nth-child(2) .post-body .medal {
          background: url(./../assets/silver-star.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post:nth-child(2) .post-body .rank::after {
          content: "nd"; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post:nth-child(3) .post-body .medal {
          background: url(./../assets/bronze-star.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post:nth-child(3) .post-body .rank::after {
          content: "rd"; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-img {
          background-image: none;
          border-radius: 100%; }
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-img img {
            border-radius: 100%; }
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-img .pic {
            border-radius: 100px;
            margin-left: 20%; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-body .medal {
          top: -170px;
          left: 10px; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-body .post-content {
          color: #ffffff; }
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-body .post-content .author-handle {
            margin-top: 0px;
            font-family: CiscoSans;
            font-weight: 500;
            font-size: 1em; }
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-body .post-content .author-name {
            margin-top: 0px;
            font-family: CiscoSans;
            font-weight: 300; }
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-body .post-content .rank {
            top: -150px;
            font-size: 1.4em;
            left: 25px;
            font-family: CiscoSansBold; }
            .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-body .post-content .rank::after {
              font-size: 15px;
              vertical-align: super; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-footer {
          margin-top: 0px; }
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-footer .post-count {
            margin-right: 10px; }
            .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-footer .post-count .icon-fk-twitter-1 {
              font-size: 20px; }
            .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post .post-footer .post-count span {
              font-size: 20px;
              font-family: CiscoSansBold; }
      .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest {
        padding-top: 0px;
        background-color: transparent;
        justify-content: space-evenly; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post:nth-child(1) .post-body .medal {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post:nth-child(2) .post-body .medal {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post:nth-child(3) .post-body .medal {
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-img {
          background-image: none;
          border-radius: 100%; }
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-img img {
            border-radius: 100%; }
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-img .pic {
            border-radius: 100px;
            margin-left: 17%; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-body .medal {
          top: -189px;
          left: 0px; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-content {
          color: #ffffff; }
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-content .author-handle {
            font-family: CiscoSans;
            font-weight: 500;
            margin-top: 0px;
            font-size: 1em; }
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-content .author-name {
            margin-top: 0px;
            font-family: CiscoSans;
            font-weight: 300; }
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-content .rank {
            font-size: 1.4em;
            top: -160px;
            left: 28px;
            font-family: CiscoSansBold; }
            .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-content .rank::after {
              content: "th";
              font-size: 15px;
              vertical-align: super; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-footer {
          margin-top: 0px; }
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-footer .post-count {
            margin-right: 20px; }
            .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-footer .post-count .icon-fk-twitter-1 {
              font-size: 20px; }
            .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post .post-body .post-footer .post-count span {
              font-size: 20px;
              font-family: CiscoSansBold; }
      .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .btn {
        display: block; }
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .btn .cisco-btn {
          margin-top: 35px; }
  .ciscoliveapjc2023 .spinner {
    z-index: 100;
    position: absolute;
    top: 45%;
    left: 45%; }
    .ciscoliveapjc2023 .spinner:after {
      content: " ";
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 5px solid #fff;
      border-color: #adadad transparent #adadad transparent;
      animation: spin 1.2s linear infinite; }
    .ciscoliveapjc2023 .spinner.hide {
      display: none; }
  .ciscoliveapjc2023 .new-highlights-container-scroll {
    background-color: #13284c;
    color: #ffffff; }
    .ciscoliveapjc2023 .new-highlights-container-scroll .header {
      position: absolute;
      z-index: 10;
      display: flex;
      height: 125px;
      max-width: 1240px;
      width: 100%;
      align-items: center;
      background-color: #13284c;
      padding: 0px; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .back {
        display: flex;
        flex-grow: 0;
        text-align: center;
        margin-left: 10px;
        cursor: pointer; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .header .back .icon-fk {
          display: block; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .header .back .back-label {
          text-decoration: none;
          font-size: 1.15em;
          font-family: CiscoSansTT; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .header .back .back-label-mobile {
          display: none; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .title {
        font-size: 3.75em;
        font-family: CiscoSansThin;
        text-align: center;
        flex-grow: 100; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .action {
        display: flex;
        font-family: CiscoSansBold;
        font-size: 1.15em;
        background-color: #00bceb;
        padding: 10px 30px 8px 30px;
        border-radius: 25px;
        flex-grow: 0;
        text-align: center;
        margin-right: 10px;
        cursor: pointer; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .header .action .icon-fk-twitter-1 {
          display: none; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .header .action .x-logo {
          width: 30px;
          height: 20px;
          background-image: url(../assets/logo-black.png);
          background-size: contain;
          background-repeat: no-repeat; }
    .ciscoliveapjc2023 .new-highlights-container-scroll .sub-header {
      display: none; }
    .ciscoliveapjc2023 .new-highlights-container-scroll .cards {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 125px;
      height: 600px;
      background-color: #13284c; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .cards::-webkit-scrollbar {
        width: 3px;
        background-color: #071a33;
        background: #071a33; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .cards::-webkit-scrollbar-thumb {
        width: 2px;
        height: 5px;
        background-color: #fff;
        background: #00bceb; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 {
        width: 290px;
        height: 425px; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .created-at {
          top: 55px; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .source {
          position: absolute;
          top: 5px;
          left: 260px; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .source .src-twitter {
            width: 25px;
            height: 25px;
            font-size: 1.25em; }
            .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .source .src-twitter .x-logo {
              background-image: url(./../assets/logo-black.png);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              width: 18px;
              height: 25px;
              margin-left: 4px; }
            .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .source .src-twitter .icon-fk-twitter-1 {
              top: 0px;
              display: none;
              left: -1px; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .source .src-instagram {
            width: 25px;
            height: 25px; }
            .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .source .src-instagram .icon-fk-instagram-1 {
              top: -2px;
              left: -2px;
              font-size: 1.25em; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2.portrait .image {
          height: 250px;
          width: 290px;
          max-height: 250px; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2.portrait .details {
          max-height: 200px;
          width: 280px;
          max-width: 280px;
          margin-left: auto;
          margin-right: auto; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2.portrait .details .msg {
            height: 100px; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2.portrait .source .src-instagram .icon-fk-instagram-1 {
          top: 4px;
          left: -2px; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .image {
          max-height: 250px;
          height: 175px;
          width: 290px;
          background-repeat: no-repeat;
          background-position: 0px 0px; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .details {
          display: block;
          max-height: 275px;
          width: 280px;
          max-width: 280px;
          margin-left: auto;
          margin-right: auto; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .details .msg {
            display: block;
            height: 180px;
            font-family: CiscoSansLight; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .stats {
          justify-content: space-between;
          align-items: center;
          color: #02b1e4;
          width: 270px; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .stats .retweet-icon-fk {
            color: #02b1e4;
            font-size: 1.1em; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .stats .retweet-count {
            color: #02b1e4;
            font-size: 1.1em; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .stats .like-icon-fk {
            color: #02b1e4;
            font-size: 1.1em; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .stats .like-count {
            color: #02b1e4;
            font-size: 1.1em; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed {
        width: 290px;
        height: 425px;
        max-height: 425px; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .created-at {
          right: 0px;
          left: 75%; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .source {
          position: absolute;
          top: 5px;
          left: 250px; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .source .src-twitter {
            width: 25px;
            height: 25px;
            font-size: 1.25em; }
            .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .source .src-twitter .x-logo {
              background-image: url(./../assets/logo-black.png);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              width: 18px;
              height: 25px;
              margin-left: 4px; }
            .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .source .src-twitter .icon-fk-twitter-1 {
              top: -1px;
              left: -1px;
              color: #00a4e8;
              display: none; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .source .src-instagram {
            width: 25px;
            height: 25px; }
            .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .source .src-instagram .icon-fk-instagram-1 {
              top: -2px;
              left: 0px;
              font-size: 1.25em; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .image {
          max-height: 250px;
          height: 250px;
          width: 225px; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .details {
          display: block;
          max-height: 375px; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .details .msg {
            width: 260px;
            display: block;
            height: 90%;
            font-family: CiscoSansLight; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .stats {
          left: 5px;
          bottom: 2px;
          justify-content: space-between;
          align-items: center;
          color: #02b1e4; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .stats .retweet-icon-fk {
            color: #02b1e4;
            font-size: 1.1em; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .stats .retweet-count {
            color: #02b1e4;
            font-size: 1.1em; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .stats .like-icon-fk {
            color: #02b1e4;
            font-size: 1.1em; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed .stats .like-count {
            color: #02b1e4;
            font-size: 1.1em; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .cards .spinner-loading {
        z-index: 100;
        position: absolute;
        bottom: 45%;
        left: 45%; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .spinner-loading:after {
          content: " ";
          display: block;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          border: 5px solid #fff;
          border-color: #adadad transparent #adadad transparent;
          animation: spin 1.2s linear infinite; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .spinner-loading.hide {
          display: none; }
    .ciscoliveapjc2023 .new-highlights-container-scroll .scroll-to-top {
      background-color: #00bceb;
      width: 40px;
      height: 40px; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .scroll-to-top.icon-fk-up-1 {
        font-size: 1.25em;
        padding-top: 0px; }
  .ciscoliveapjc2023 .gallery-scroll-parent-container {
    position: relative;
    background-color: #13284c;
    color: #ffffff; }
    .ciscoliveapjc2023 .gallery-scroll-parent-container .spinner {
      left: 30%; }
    .ciscoliveapjc2023 .gallery-scroll-parent-container .header {
      position: absolute;
      background-color: #13284c; }
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .back .back-label {
        text-decoration: none; }
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .back .icon-fk {
        display: block; }
    .ciscoliveapjc2023 .gallery-scroll-parent-container .scroll-to-top {
      z-index: 10;
      line-height: 2;
      background-color: #00bceb;
      padding-top: 0px; }
      .ciscoliveapjc2023 .gallery-scroll-parent-container .scroll-to-top .icon-fk-up-1 {
        font-size: 1.25em;
        padding-top: 10px; }
    .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container {
      padding-top: 120px; }
      .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .spinner {
        left: 45%; }
      .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .spinner-loading {
        z-index: 100;
        position: absolute;
        bottom: 45%;
        left: 45%; }
        .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .spinner-loading:after {
          content: " ";
          display: block;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          border: 5px solid #fff;
          border-color: #adadad transparent #adadad transparent;
          animation: spin 1.2s linear infinite; }
        .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .spinner-loading.hide {
          display: none; }
      .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards {
        overflow: auto;
        height: 640px;
        width: 100%;
        background-color: #13284c; }
        .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .spinner {
          left: 45%; }
        .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards::-webkit-scrollbar {
          width: 5px;
          background-color: #13284c;
          background: #13284c; }
        .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards::-webkit-scrollbar-thumb {
          width: 2px;
          height: 5px;
          background-color: #fff;
          background: #00bceb; }
        .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card {
          border: none;
          background-repeat: no-repeat;
          background-position: 0px -50px; }
          .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details {
            cursor: auto; }
            .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats {
              display: none;
              font-family: CiscoSans;
              background-color: #ffffff;
              color: #04a6e1;
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 5px;
              right: 5%; }
              .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .reply {
                cursor: pointer; }
                .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .reply .reply-icon-fk {
                  font-size: 18px; }
              .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .retweet .icon-fk-retweet {
                font-size: 20px;
                cursor: pointer; }
              .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .retweet .retweet-count {
                font-size: 18px;
                padding: 0px;
                margin: 0px 0px 0px 0px; }
              .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .like {
                margin-right: 5px; }
                .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .like .icon-fk-heart-empty-1 {
                  font-size: 20px;
                  cursor: pointer; }
                .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats .like .like-count {
                  font-size: 18px;
                  padding: 0px;
                  margin: 0px 0px 0px 0px; }
            .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .view-post {
              height: 35px;
              cursor: pointer;
              width: 60%;
              margin-left: 17%;
              padding-top: 9px;
              padding-right: 5px;
              padding-bottom: 5px;
              padding-left: 5px;
              font-family: CiscoSansBold;
              font-size: 17px;
              background-color: #04a6e1; }
          .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src {
            display: none; }
            .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-twitter {
              width: 30px;
              height: 30px;
              top: 10px;
              left: 200px; }
              .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-twitter .icon-fk-twitter-1 {
                top: 7px;
                left: 1px; }
            .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-fankave {
              background-color: #ffffff;
              border-radius: 50px;
              width: 30px;
              height: 30px;
              top: 5px;
              left: 205px; }
              .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-fankave img {
                margin-top: 7px;
                margin-left: 7px;
                width: 20px;
                height: 15px; }
              .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-fankave .icon-fk-twitter-1 {
                top: 7px;
                left: 1px; }
            .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-instagram {
              width: 30px;
              height: 30px;
              top: 10px;
              left: 200px; }
              .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-instagram .icon-fk-instagram-1 {
                top: 7px;
                left: 0px; }
          .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card.insta:hover .gallery-details .gallery-stats {
            display: none; }
          .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards .gallery-card-parent .gallery-card.insta:hover .gallery-details .view-post {
            bottom: 100px; }
  .ciscoliveapjc2023 .badge-container {
    background-color: #13284c;
    background-image: none; }
    .ciscoliveapjc2023 .badge-container .header {
      margin-top: 25px; }
      .ciscoliveapjc2023 .badge-container .header .title {
        background-color: #13284c; }
      .ciscoliveapjc2023 .badge-container .header .back-label {
        font-family: CiscoSansTT;
        font-size: 18px; }
    .ciscoliveapjc2023 .badge-container .submit {
      border-radius: 25px; }
    .ciscoliveapjc2023 .badge-container .fk-container .working-space .download {
      border-radius: 25px; }

.autodesk .cards {
  max-height: 390px;
  padding: 30px; }

.autodesk .spinner {
  z-index: 100;
  position: absolute;
  top: 45%;
  left: 45%; }
  .autodesk .spinner:after {
    content: " ";
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #adadad transparent #adadad transparent;
    animation: spin 1.2s linear infinite; }
  .autodesk .spinner.hide {
    display: none; }

.autodesk .home-container {
  color: #ffffff;
  padding: 5% 0%;
  background-color: #ffffff;
  background: linear-gradient(130.8deg, rgba(255, 255, 255, 0.2) 16.8%, rgba(255, 255, 255, 0.1) 72.82%);
  backdrop-filter: blur(40px);
  font-family: "Artifakt Element", "Roboto", "sans-serif";
  height: 100%;
  border-radius: 12px;
  width: 100%;
  max-width: 1020px;
  max-height: 1080px; }
  .autodesk .home-container .title-1 {
    margin-left: auto;
    margin-right: auto;
    max-width: 35%;
    font-size: 2em;
    font-weight: bold;
    font-family: "Artifakt Element", "sans-serif";
    color: #ffffff;
    padding-top: 1%; }
  .autodesk .home-container .title-2 {
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
    font-size: 2em;
    font-weight: bold;
    font-family: "Artifakt Element", "Roboto", "sans-serif";
    color: #ffffff; }
  .autodesk .home-container .title-hashtag {
    display: flex;
    justify-content: center;
    align-items: center; }
    .autodesk .home-container .title-hashtag .hashtag {
      padding-top: 5%;
      color: #ffffff;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.5em;
      font-family: "Artifakt Element", "Roboto", "sans-serif";
      font-weight: normal; }
    .autodesk .home-container .title-hashtag .photobooth {
      display: none;
      width: 0px;
      height: 0px;
      cursor: pointer;
      margin-right: 1em; }
      .autodesk .home-container .title-hashtag .photobooth .text {
        display: none;
        background-color: #FFAB00;
        color: #ffffff;
        font-size: 0.75em;
        padding: 0.5em;
        border-radius: 10px; }
  .autodesk .home-container .content {
    background-color: transparent;
    padding: 3% 0% 6% 0%; }
  .autodesk .home-container .experience {
    margin-top: 10px;
    flex-direction: row-reverse;
    background-color: transparent; }
    .autodesk .home-container .experience .exp-container {
      background: transparent; }
      .autodesk .home-container .experience .exp-container .block {
        background: transparent;
        display: flex;
        flex-direction: column-reverse; }
        .autodesk .home-container .experience .exp-container .block .selection-container {
          margin-left: 2vw;
          margin-right: 2vw; }
          .autodesk .home-container .experience .exp-container .block .selection-container .selection {
            border: none;
            position: relative;
            background-color: transparent; }
            .autodesk .home-container .experience .exp-container .block .selection-container .selection .top {
              background: rgba(255, 255, 255, 0);
              position: absolute;
              height: 160px;
              top: 50%;
              left: 25%;
              z-index: 10;
              width: 250px; }
              .autodesk .home-container .experience .exp-container .block .selection-container .selection .top img {
                overflow: hidden; }
              .autodesk .home-container .experience .exp-container .block .selection-container .selection .top .imgalt {
                display: block;
                width: 75px; }
            .autodesk .home-container .experience .exp-container .block .selection-container .selection .below {
              border-radius: 12px;
              background: rgba(255, 255, 255, 0.2);
              height: 250px; }
              .autodesk .home-container .experience .exp-container .block .selection-container .selection .below .selection-title {
                margin-left: 20%;
                font-size: 30px;
                font-family: "Artifakt Element", "Roboto", "sans-serif";
                color: #ffffff;
                font-weight: bold; }
      .autodesk .home-container .experience .exp-container.first .block .selection-container .selection .top {
        left: 15%;
        top: 35%; }
        .autodesk .home-container .experience .exp-container.first .block .selection-container .selection .top img {
          display: none; }
        .autodesk .home-container .experience .exp-container.first .block .selection-container .selection .top .imgalt {
          width: 100px;
          height: 75px;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(./../assets/featured-brs.png); }
      .autodesk .home-container .experience .exp-container.first .block .selection-container .selection .below .selection-title {
        margin-left: 0%;
        margin-top: -30%;
        font-weight: 600; }
      .autodesk .home-container .experience .exp-container.second .block .selection-container .selection .top {
        left: 20%;
        top: 35%; }
        .autodesk .home-container .experience .exp-container.second .block .selection-container .selection .top img {
          display: none; }
        .autodesk .home-container .experience .exp-container.second .block .selection-container .selection .top .imgalt {
          width: 100px;
          height: 75px;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(./../assets/msg.png); }
      .autodesk .home-container .experience .exp-container.second .block .selection-container .selection .below .selection-title {
        margin-left: 0%;
        margin-top: -30%;
        font-weight: 600; }
    .autodesk .home-container .experience .tagline {
      padding-bottom: 0px;
      font-size: 1.5em;
      color: #172B4D;
      font-family: "Charlie Display", "Roboto", "sans-serif"; }

.autodesk .gallery-scroll-parent-container {
  position: relative;
  background-color: #000000; }
  .autodesk .gallery-scroll-parent-container .header {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 20; }
    .autodesk .gallery-scroll-parent-container .header .gallery-scroll-parent-title {
      font-family: "Artifakt Element", "Roboto", "sans-serif";
      color: #ffffff;
      font-size: 48px; }
    .autodesk .gallery-scroll-parent-container .header .back {
      width: 3em;
      display: flex;
      justify-content: center;
      align-items: center; }
      .autodesk .gallery-scroll-parent-container .header .back .icon-fk.icon-fk-left-arrow {
        color: #172B4D; }
      .autodesk .gallery-scroll-parent-container .header .back .back-label {
        display: none; }
    .autodesk .gallery-scroll-parent-container .header .action {
      display: none;
      background-color: #FFAB00;
      font-family: "Roboto", "sans-serif";
      cursor: pointer; }
  .autodesk .gallery-scroll-parent-container .scroll-to-top {
    background-color: #363636;
    background-image: url(./../assets/up.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 8px 8px;
    font-size: 1em;
    display: block;
    z-index: 20; }
    .autodesk .gallery-scroll-parent-container .scroll-to-top.hide {
      display: none; }
    .autodesk .gallery-scroll-parent-container .scroll-to-top .icon-fk-up-1 {
      display: none; }
  .autodesk .gallery-scroll-parent-container .gallery-scroll-parent-title {
    color: #ffffff; }
  .autodesk .gallery-scroll-parent-container .gallery-scroll-container {
    padding-top: 100px;
    position: relative; }
  .autodesk .gallery-scroll-parent-container .video-card-parent {
    position: absolute;
    z-index: 10;
    max-width: 620px;
    max-height: 620px; }
    .autodesk .gallery-scroll-parent-container .video-card-parent.portrait {
      top: 17%;
      left: 33%; }
      .autodesk .gallery-scroll-parent-container .video-card-parent.portrait video {
        height: 720px; }
    .autodesk .gallery-scroll-parent-container .video-card-parent.landscape {
      top: 30%;
      left: 25%; }
      .autodesk .gallery-scroll-parent-container .video-card-parent.landscape video {
        width: 640px; }
  .autodesk .gallery-scroll-parent-container .video-card {
    z-index: 10; }
    .autodesk .gallery-scroll-parent-container .video-card .close {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 25px;
      height: 25px;
      z-index: 20;
      background-image: url(./../assets/close.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      cursor: pointer; }
    .autodesk .gallery-scroll-parent-container .video-card .video-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .autodesk .gallery-scroll-parent-container .video-card .video-container .controls-container {
        margin-top: -50px;
        width: 95%;
        height: 50px;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .autodesk .gallery-scroll-parent-container .video-card .video-container .controls-container .closed-captions {
          text-align: center;
          position: absolute;
          bottom: 15px;
          margin-bottom: 5px; }
          .autodesk .gallery-scroll-parent-container .video-card .video-container .controls-container .closed-captions .cc {
            text-align: center;
            color: #ffffff;
            background-color: #00000052;
            padding: 3px;
            font-family: CiscoSans;
            font-size: 0.85em; }
        .autodesk .gallery-scroll-parent-container .video-card .video-container .controls-container .controls {
          display: flex;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          justify-content: space-between;
          align-items: center; }
          .autodesk .gallery-scroll-parent-container .video-card .video-container .controls-container .controls .left {
            display: flex; }
            .autodesk .gallery-scroll-parent-container .video-card .video-container .controls-container .controls .left .play-pause .icon {
              color: #ffffff;
              cursor: pointer;
              font-size: 1em;
              margin-left: 5px;
              margin-right: 5px; }
            .autodesk .gallery-scroll-parent-container .video-card .video-container .controls-container .controls .left .duration {
              font-family: CiscoSans;
              margin-top: 4px;
              font-size: 1em;
              color: #ffffff; }
          .autodesk .gallery-scroll-parent-container .video-card .video-container .controls-container .controls .right {
            display: flex; }
            .autodesk .gallery-scroll-parent-container .video-card .video-container .controls-container .controls .right .volume .icon {
              color: #ffffff;
              cursor: pointer;
              font-size: 0.75em;
              margin-left: 5px;
              margin-right: 5px; }
            .autodesk .gallery-scroll-parent-container .video-card .video-container .controls-container .controls .right .download .icon {
              color: #ffffff;
              font-size: 0.75em;
              margin-left: 5px;
              margin-right: 5px; }
      .autodesk .gallery-scroll-parent-container .video-card .video-container video {
        background-size: cover;
        overflow: hidden;
        border-radius: 11px; }
  .autodesk .gallery-scroll-parent-container .gallery-scroll-cards {
    background: linear-gradient(130.8deg, rgba(255, 255, 255, 0.4) 16.8%, rgba(255, 255, 255, 0.1) 72.82%);
    overflow: auto;
    height: 800px;
    width: 100%;
    padding: 20px 0px 5px 0px;
    margin: 0px;
    max-width: 1280px; }
    .autodesk .gallery-scroll-parent-container .gallery-scroll-cards.blur {
      filter: blur(5px);
      z-index: -1; }
    .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card {
      background-repeat: no-repeat;
      margin: 5px;
      border-radius: 11px;
      border: 1px solid rgba(0, 0, 0, 0);
      position: relative;
      z-index: 1;
      width: 300px;
      height: 300px; }
      .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .volume-control {
        display: none; }
        .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .volume-control img {
          width: 25px;
          height: 25px;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 10; }
      .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card video {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        background-size: cover;
        overflow: hidden; }
        .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card video:hover {
          z-index: 1; }
      .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-msg {
        margin-left: 5px;
        line-clamp: 20;
        width: 95%;
        height: 90%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src {
        top: 10px;
        right: 10px;
        left: auto;
        width: 40px;
        height: 40px; }
        .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-twitter {
          background-image: url(./../assets/twitter.svg);
          background-color: #ffffff00;
          background-size: contain;
          background-repeat: no-repeat;
          border-radius: 0px; }
          .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-twitter .icon-fk-twitter-1 {
            display: none;
            color: #FFAB00; }
        .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-instagram {
          background-image: url(./../assets/instagram.png);
          background-repeat: no-repeat;
          background-size: contain; }
          .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-instagram .icon-fk-instagram-1 {
            display: none;
            top: 22px;
            left: 18px;
            color: #FFAB00; }
        .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-src.src-fankave img {
          width: 25px;
          height: 20px; }
      .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details {
        width: 100%;
        height: 100%; }
        .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-msg {
          display: block; }
        .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .gallery-stats {
          display: none;
          color: #FFAB00; }
        .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card .gallery-details .view-post {
          height: 30px;
          width: 75%;
          margin-left: 10%;
          padding-top: 1.5%;
          background-color: #FFAB00;
          font-family: "Artifakt Element", "Roboto", "sans-serif"; }
      .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card:hover video {
        z-index: 1; }
      .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card:hover .gallery-details {
        z-index: 2; }
        .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card:hover .gallery-details .gallery-src {
          display: none; }
        .autodesk .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card-parent .gallery-card:hover .gallery-details img {
          position: absolute;
          top: 45%;
          left: 45%;
          width: 30px;
          height: 30px; }

.impact-stories,
.impact-stories-stage,
.impact-stories-app {
  background-color: #fafafa;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%; }
  .impact-stories.aeApps .action-container,
  .impact-stories-stage.aeApps .action-container,
  .impact-stories-app.aeApps .action-container {
    background-image: url(./../assets/impact21/images/aeBg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: 7px; }
    .impact-stories.aeApps .action-container .title,
    .impact-stories-stage.aeApps .action-container .title,
    .impact-stories-app.aeApps .action-container .title {
      font-size: 20px !important;
      font-weight: bold;
      line-height: 1 !important; }
    .impact-stories.aeApps .action-container .tagline,
    .impact-stories-stage.aeApps .action-container .tagline,
    .impact-stories-app.aeApps .action-container .tagline {
      font-size: 16px !important;
      line-height: 1 !important;
      text-align: center;
      margin: 20px 15px 20px 15px !important; }
    .impact-stories.aeApps .action-container .action-items,
    .impact-stories-stage.aeApps .action-container .action-items,
    .impact-stories-app.aeApps .action-container .action-items {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 20px !important; }
      .impact-stories.aeApps .action-container .action-items .action-card,
      .impact-stories-stage.aeApps .action-container .action-items .action-card,
      .impact-stories-app.aeApps .action-container .action-items .action-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 160px !important;
        min-height: 160px !important;
        width: 160px !important;
        padding: 10px !important;
        border-radius: 10px;
        box-shadow: #00000029 0px 0px 6px 0px; }
        .impact-stories.aeApps .action-container .action-items .action-card .top-line,
        .impact-stories-stage.aeApps .action-container .action-items .action-card .top-line,
        .impact-stories-app.aeApps .action-container .action-items .action-card .top-line {
          display: none !important; }
        .impact-stories.aeApps .action-container .action-items .action-card .action-pic,
        .impact-stories-stage.aeApps .action-container .action-items .action-card .action-pic,
        .impact-stories-app.aeApps .action-container .action-items .action-card .action-pic {
          display: flex;
          justify-content: center;
          align-items: center; }
        .impact-stories.aeApps .action-container .action-items .action-card .action-heading,
        .impact-stories-stage.aeApps .action-container .action-items .action-card .action-heading,
        .impact-stories-app.aeApps .action-container .action-items .action-card .action-heading {
          font-size: 16px !important;
          font-weight: bold;
          line-height: 1 !important;
          text-align: center;
          min-height: 16px !important;
          margin-top: 20px; }
        .impact-stories.aeApps .action-container .action-items .action-card .action-btn-bg,
        .impact-stories-stage.aeApps .action-container .action-items .action-card .action-btn-bg,
        .impact-stories-app.aeApps .action-container .action-items .action-card .action-btn-bg {
          display: none !important; }
        .impact-stories.aeApps .action-container .action-items .action-card .action-description,
        .impact-stories-stage.aeApps .action-container .action-items .action-card .action-description,
        .impact-stories-app.aeApps .action-container .action-items .action-card .action-description {
          display: none !important; }
    .impact-stories.aeApps .action-container .footer,
    .impact-stories-stage.aeApps .action-container .footer,
    .impact-stories-app.aeApps .action-container .footer {
      margin-top: 425px !important; }
  .impact-stories.aeApps .hashtag-container.error .hashtags,
  .impact-stories-stage.aeApps .hashtag-container.error .hashtags,
  .impact-stories-app.aeApps .hashtag-container.error .hashtags {
    border: 1px solid #c10707 !important; }
  .impact-stories.aeApps .hashtag-container.error .text-area-meta,
  .impact-stories-stage.aeApps .hashtag-container.error .text-area-meta,
  .impact-stories-app.aeApps .hashtag-container.error .text-area-meta {
    margin-top: 5px;
    display: block;
    font-size: 0.625em;
    font-weight: bold;
    color: #c10707; }
  .impact-stories.aeApps .hashtag-container .text-area-meta,
  .impact-stories-stage.aeApps .hashtag-container .text-area-meta,
  .impact-stories-app.aeApps .hashtag-container .text-area-meta {
    display: none; }
  .impact-stories.aeApps .spinner,
  .impact-stories-stage.aeApps .spinner,
  .impact-stories-app.aeApps .spinner {
    top: 50%;
    left: 46%; }
  .impact-stories.aeApps .footer,
  .impact-stories-stage.aeApps .footer,
  .impact-stories-app.aeApps .footer {
    margin-top: 225px !important;
    background-color: #0d274d00 !important; }
  .impact-stories.aeApps .image.video,
  .impact-stories-stage.aeApps .image.video,
  .impact-stories-app.aeApps .image.video {
    background-image: url(./../assets/impact21/images/videoae.svg) !important; }
  .impact-stories.aeApps .image.upload,
  .impact-stories-stage.aeApps .image.upload,
  .impact-stories-app.aeApps .image.upload {
    background-image: url(./../assets//impact21/images/uploadae.svg) !important; }
  .impact-stories.aeApps .stickers-preview .sticker,
  .impact-stories-stage.aeApps .stickers-preview .sticker,
  .impact-stories-app.aeApps .stickers-preview .sticker {
    background-color: #01366A !important; }
  .impact-stories.aeApps .capture-overlay,
  .impact-stories-stage.aeApps .capture-overlay,
  .impact-stories-app.aeApps .capture-overlay {
    color: #01366A !important; }
  .impact-stories.aeApps .username,
  .impact-stories-stage.aeApps .username,
  .impact-stories-app.aeApps .username {
    color: #01366A !important; }
  .impact-stories.aeApps .download,
  .impact-stories-stage.aeApps .download,
  .impact-stories-app.aeApps .download {
    background-color: #01366A !important; }
  .impact-stories.aeApps .btn-redo,
  .impact-stories-stage.aeApps .btn-redo,
  .impact-stories-app.aeApps .btn-redo {
    color: #01366A; }
  .impact-stories.aeApps .back,
  .impact-stories-stage.aeApps .back,
  .impact-stories-app.aeApps .back {
    border: 1px solid #01366A !important;
    color: #01366A !important; }
  .impact-stories.aeApps .continue,
  .impact-stories-stage.aeApps .continue,
  .impact-stories-app.aeApps .continue {
    background-color: #01366A !important; }
  .impact-stories.aeApps .action .redo,
  .impact-stories-stage.aeApps .action .redo,
  .impact-stories-app.aeApps .action .redo {
    color: #01366A !important;
    border: 1px solid #01366A !important; }
  .impact-stories.aeApps .action .continue,
  .impact-stories-stage.aeApps .action .continue,
  .impact-stories-app.aeApps .action .continue {
    background-color: #01366A !important; }
  .impact-stories.aeApps .custom-file-upload,
  .impact-stories-stage.aeApps .custom-file-upload,
  .impact-stories-app.aeApps .custom-file-upload {
    background-color: #01366A !important; }
  .impact-stories.aeApps .download p,
  .impact-stories-stage.aeApps .download p,
  .impact-stories-app.aeApps .download p {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .impact-stories.aeApps span,
  .impact-stories-stage.aeApps span,
  .impact-stories-app.aeApps span {
    line-height: 1 !important; }
  .impact-stories.aeApps .action-container .top-left-logo,
  .impact-stories-stage.aeApps .action-container .top-left-logo,
  .impact-stories-app.aeApps .action-container .top-left-logo {
    display: none;
    height: 96px;
    background-image: url(./../assets/impact21/images/top-ribbon-image-mobile.png); }
    .impact-stories.aeApps .action-container .top-left-logo .logo,
    .impact-stories-stage.aeApps .action-container .top-left-logo .logo,
    .impact-stories-app.aeApps .action-container .top-left-logo .logo {
      margin-left: 38px; }
      .impact-stories.aeApps .action-container .top-left-logo .logo img,
      .impact-stories-stage.aeApps .action-container .top-left-logo .logo img,
      .impact-stories-app.aeApps .action-container .top-left-logo .logo img {
        width: 100px; }
  .impact-stories.aeApps .action-container .title,
  .impact-stories-stage.aeApps .action-container .title,
  .impact-stories-app.aeApps .action-container .title {
    margin: 23px auto 0 auto;
    font-size: 32px;
    line-height: 40px; }
  .impact-stories.aeApps .action-container .tagline,
  .impact-stories-stage.aeApps .action-container .tagline,
  .impact-stories-app.aeApps .action-container .tagline {
    margin: 0 auto 15px auto;
    line-height: 40px; }
  .impact-stories.aeApps .action-container .action-items,
  .impact-stories-stage.aeApps .action-container .action-items,
  .impact-stories-app.aeApps .action-container .action-items {
    margin: auto 15px;
    grid-gap: 15px; }
    .impact-stories.aeApps .action-container .action-items .action-card,
    .impact-stories-stage.aeApps .action-container .action-items .action-card,
    .impact-stories-app.aeApps .action-container .action-items .action-card {
      width: 100%;
      min-height: 230px;
      padding: 25px 14px 21px 36px; }
      .impact-stories.aeApps .action-container .action-items .action-card .top-line,
      .impact-stories-stage.aeApps .action-container .action-items .action-card .top-line,
      .impact-stories-app.aeApps .action-container .action-items .action-card .top-line {
        background-color: #01366A; }
      .impact-stories.aeApps .action-container .action-items .action-card .action-btn-bg .action-btn,
      .impact-stories-stage.aeApps .action-container .action-items .action-card .action-btn-bg .action-btn,
      .impact-stories-app.aeApps .action-container .action-items .action-card .action-btn-bg .action-btn {
        background-color: #01366A; }
      .impact-stories.aeApps .action-container .action-items .action-card .action-pic,
      .impact-stories-stage.aeApps .action-container .action-items .action-card .action-pic,
      .impact-stories-app.aeApps .action-container .action-items .action-card .action-pic {
        height: 35px; }
        .impact-stories.aeApps .action-container .action-items .action-card .action-pic .image.camera,
        .impact-stories-stage.aeApps .action-container .action-items .action-card .action-pic .image.camera,
        .impact-stories-app.aeApps .action-container .action-items .action-card .action-pic .image.camera {
          background-image: url(./../assets/impact21/images/captureae.svg);
          width: 47.8px;
          height: 35.4px; }
        .impact-stories.aeApps .action-container .action-items .action-card .action-pic .image.video,
        .impact-stories-stage.aeApps .action-container .action-items .action-card .action-pic .image.video,
        .impact-stories-app.aeApps .action-container .action-items .action-card .action-pic .image.video {
          width: 50.6px;
          height: 28px; }
        .impact-stories.aeApps .action-container .action-items .action-card .action-pic .image.upload,
        .impact-stories-stage.aeApps .action-container .action-items .action-card .action-pic .image.upload,
        .impact-stories-app.aeApps .action-container .action-items .action-card .action-pic .image.upload {
          width: 49.8px;
          height: 33.1px; }
        .impact-stories.aeApps .action-container .action-items .action-card .action-pic .image.twitter,
        .impact-stories-stage.aeApps .action-container .action-items .action-card .action-pic .image.twitter,
        .impact-stories-app.aeApps .action-container .action-items .action-card .action-pic .image.twitter {
          width: 40px;
          height: 32.5px; }
      .impact-stories.aeApps .action-container .action-items .action-card .action-heading,
      .impact-stories-stage.aeApps .action-container .action-items .action-card .action-heading,
      .impact-stories-app.aeApps .action-container .action-items .action-card .action-heading {
        line-height: 1.43;
        min-height: 47px; }
  .impact-stories.aeApps .fk-container .back-icon-fk,
  .impact-stories-stage.aeApps .fk-container .back-icon-fk,
  .impact-stories-app.aeApps .fk-container .back-icon-fk {
    background-image: url(./../assets/impact21/images/backae.svg) !important; }
  .impact-stories.aeApps .fk-container .top-left-logo,
  .impact-stories-stage.aeApps .fk-container .top-left-logo,
  .impact-stories-app.aeApps .fk-container .top-left-logo {
    display: none;
    height: 96px;
    background-image: url(./../assets/impact21/images/top-ribbon-image-mobile.png); }
    .impact-stories.aeApps .fk-container .top-left-logo .logo,
    .impact-stories-stage.aeApps .fk-container .top-left-logo .logo,
    .impact-stories-app.aeApps .fk-container .top-left-logo .logo {
      margin-left: 38px; }
      .impact-stories.aeApps .fk-container .top-left-logo .logo img,
      .impact-stories-stage.aeApps .fk-container .top-left-logo .logo img,
      .impact-stories-app.aeApps .fk-container .top-left-logo .logo img {
        width: 100px; }
  .impact-stories.aeApps .fk-container .cancel-btn-desktop,
  .impact-stories-stage.aeApps .fk-container .cancel-btn-desktop,
  .impact-stories-app.aeApps .fk-container .cancel-btn-desktop {
    display: none; }
  .impact-stories.aeApps .fk-container .cancel-btn-mobile,
  .impact-stories-stage.aeApps .fk-container .cancel-btn-mobile,
  .impact-stories-app.aeApps .fk-container .cancel-btn-mobile {
    color: #01366A;
    display: flex; }
  .impact-stories.aeApps .fk-container.image-review .heading-out,
  .impact-stories-stage.aeApps .fk-container.image-review .heading-out,
  .impact-stories-app.aeApps .fk-container.image-review .heading-out {
    margin-top: 20px;
    font-size: 20px; }
  .impact-stories.aeApps .fk-container.image-review .back-icon-fk,
  .impact-stories-stage.aeApps .fk-container.image-review .back-icon-fk,
  .impact-stories-app.aeApps .fk-container.image-review .back-icon-fk {
    background-image: url(./../assets/impact21/images/backae.svg) !important; }
  .impact-stories.aeApps .fk-container.image-review .image-post-preview-container,
  .impact-stories-stage.aeApps .fk-container.image-review .image-post-preview-container,
  .impact-stories-app.aeApps .fk-container.image-review .image-post-preview-container {
    margin-top: 20px; }
  .impact-stories.aeApps .fk-container.image-download .action .redo,
  .impact-stories-stage.aeApps .fk-container.image-download .action .redo,
  .impact-stories-app.aeApps .fk-container.image-download .action .redo {
    color: white !important;
    background-color: #01366A; }
  .impact-stories.aeApps .fk-container.video-review .action .back .text,
  .impact-stories-stage.aeApps .fk-container.video-review .action .back .text,
  .impact-stories-app.aeApps .fk-container.video-review .action .back .text {
    color: #01366A !important; }
  .impact-stories.aeApps .fk-container.video-review .heading-out,
  .impact-stories-stage.aeApps .fk-container.video-review .heading-out,
  .impact-stories-app.aeApps .fk-container.video-review .heading-out {
    font-size: 20px;
    padding-top: 13px;
    height: 40px;
    display: flex;
    align-items: center; }
  .impact-stories.aeApps .fk-container.upload-image .footer,
  .impact-stories-stage.aeApps .fk-container.upload-image .footer,
  .impact-stories-app.aeApps .fk-container.upload-image .footer {
    margin-top: 440px !important; }
  .impact-stories.aeApps .fk-container.upload-review .heading-out,
  .impact-stories-stage.aeApps .fk-container.upload-review .heading-out,
  .impact-stories-app.aeApps .fk-container.upload-review .heading-out {
    margin-top: 20px;
    font-size: 20px; }
  .impact-stories.aeApps .fk-container.upload-review .upload-post-review-container,
  .impact-stories-stage.aeApps .fk-container.upload-review .upload-post-review-container,
  .impact-stories-app.aeApps .fk-container.upload-review .upload-post-review-container {
    margin-top: 20px; }
  .impact-stories.aeApps .fk-container.upload-review .action .back .text,
  .impact-stories-stage.aeApps .fk-container.upload-review .action .back .text,
  .impact-stories-app.aeApps .fk-container.upload-review .action .back .text {
    color: #01366A; }
  .impact-stories.aeApps .fk-container.upload-preview .sticker-container .select-bg-container,
  .impact-stories-stage.aeApps .fk-container.upload-preview .sticker-container .select-bg-container,
  .impact-stories-app.aeApps .fk-container.upload-preview .sticker-container .select-bg-container {
    display: none !important; }
  .impact-stories.aeApps .fk-container.upload-preview .action .redo .image.camera,
  .impact-stories-stage.aeApps .fk-container.upload-preview .action .redo .image.camera,
  .impact-stories-app.aeApps .fk-container.upload-preview .action .redo .image.camera {
    background-image: url(./../assets/impact21/images/backae.svg) !important; }
  .impact-stories.aeApps .fk-container.upload-preview .footer,
  .impact-stories-stage.aeApps .fk-container.upload-preview .footer,
  .impact-stories-app.aeApps .fk-container.upload-preview .footer {
    margin-top: 400px !important; }
  .impact-stories.aeApps .fk-container.upload-download .title,
  .impact-stories-stage.aeApps .fk-container.upload-download .title,
  .impact-stories-app.aeApps .fk-container.upload-download .title {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .impact-stories.aeApps .fk-container.upload-download .tagline,
  .impact-stories-stage.aeApps .fk-container.upload-download .tagline,
  .impact-stories-app.aeApps .fk-container.upload-download .tagline {
    margin-left: auto;
    margin-right: auto; }
  .impact-stories.aeApps .fk-container.video-download .title,
  .impact-stories-stage.aeApps .fk-container.video-download .title,
  .impact-stories-app.aeApps .fk-container.video-download .title {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .impact-stories.aeApps .fk-container.video-download .tagline,
  .impact-stories-stage.aeApps .fk-container.video-download .tagline,
  .impact-stories-app.aeApps .fk-container.video-download .tagline {
    margin-left: auto;
    margin-right: auto; }
  .impact-stories.aeApps .fk-container.image-download .title,
  .impact-stories-stage.aeApps .fk-container.image-download .title,
  .impact-stories-app.aeApps .fk-container.image-download .title {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .impact-stories.aeApps .fk-container.image-download .tagline,
  .impact-stories-stage.aeApps .fk-container.image-download .tagline,
  .impact-stories-app.aeApps .fk-container.image-download .tagline {
    margin-left: auto;
    margin-right: auto; }
  .impact-stories.aeApps .image-capture-container,
  .impact-stories-stage.aeApps .image-capture-container,
  .impact-stories-app.aeApps .image-capture-container {
    margin-top: 23px;
    padding: 35px 0 25px 0;
    min-height: unset; }
    .impact-stories.aeApps .image-capture-container .capture-action,
    .impact-stories-stage.aeApps .image-capture-container .capture-action,
    .impact-stories-app.aeApps .image-capture-container .capture-action {
      width: 95%;
      background-color: #01366A; }
    .impact-stories.aeApps .image-capture-container .heading,
    .impact-stories-stage.aeApps .image-capture-container .heading,
    .impact-stories-app.aeApps .image-capture-container .heading {
      font-size: 20px; }
    .impact-stories.aeApps .image-capture-container .image-live-preview,
    .impact-stories-stage.aeApps .image-capture-container .image-live-preview,
    .impact-stories-app.aeApps .image-capture-container .image-live-preview {
      margin-top: 20px;
      width: 100%; }
      .impact-stories.aeApps .image-capture-container .image-live-preview .capture-overlay,
      .impact-stories-stage.aeApps .image-capture-container .image-live-preview .capture-overlay,
      .impact-stories-app.aeApps .image-capture-container .image-live-preview .capture-overlay {
        color: #01366A; }
  .impact-stories.aeApps .image-preview-container,
  .impact-stories-stage.aeApps .image-preview-container,
  .impact-stories-app.aeApps .image-preview-container {
    margin-top: 23px;
    padding: 40px 15px 25px 15px;
    min-height: unset;
    width: 95%; }
    .impact-stories.aeApps .image-preview-container .heading,
    .impact-stories-stage.aeApps .image-preview-container .heading,
    .impact-stories-app.aeApps .image-preview-container .heading {
      font-size: 20px; }
    .impact-stories.aeApps .image-preview-container .image-preview,
    .impact-stories-stage.aeApps .image-preview-container .image-preview,
    .impact-stories-app.aeApps .image-preview-container .image-preview {
      margin-top: 20px; }
    .impact-stories.aeApps .image-preview-container .camera,
    .impact-stories-stage.aeApps .image-preview-container .camera,
    .impact-stories-app.aeApps .image-preview-container .camera {
      background-image: url(./../assets/impact21/images/backae.svg) !important; }
  .impact-stories.aeApps .full-image-container,
  .impact-stories-stage.aeApps .full-image-container,
  .impact-stories-app.aeApps .full-image-container {
    margin-top: 33px; }
    .impact-stories.aeApps .full-image-container .heading,
    .impact-stories-stage.aeApps .full-image-container .heading,
    .impact-stories-app.aeApps .full-image-container .heading {
      font-size: 20px; }
    .impact-stories.aeApps .full-image-container .add-post-container,
    .impact-stories-stage.aeApps .full-image-container .add-post-container,
    .impact-stories-app.aeApps .full-image-container .add-post-container {
      margin-top: 20px; }
      .impact-stories.aeApps .full-image-container .add-post-container .image-preview-and-retake,
      .impact-stories-stage.aeApps .full-image-container .add-post-container .image-preview-and-retake,
      .impact-stories-app.aeApps .full-image-container .add-post-container .image-preview-and-retake {
        margin: 0 auto 20px auto; }
        .impact-stories.aeApps .full-image-container .add-post-container .image-preview-and-retake .action-retake,
        .impact-stories-stage.aeApps .full-image-container .add-post-container .image-preview-and-retake .action-retake,
        .impact-stories-app.aeApps .full-image-container .add-post-container .image-preview-and-retake .action-retake {
          font-size: 13px; }
      .impact-stories.aeApps .full-image-container .add-post-container .add-story-container .user-info .user-image,
      .impact-stories-stage.aeApps .full-image-container .add-post-container .add-story-container .user-info .user-image,
      .impact-stories-app.aeApps .full-image-container .add-post-container .add-story-container .user-info .user-image {
        height: 45px;
        width: 45px; }
        .impact-stories.aeApps .full-image-container .add-post-container .add-story-container .user-info .user-image img,
        .impact-stories-stage.aeApps .full-image-container .add-post-container .add-story-container .user-info .user-image img,
        .impact-stories-app.aeApps .full-image-container .add-post-container .add-story-container .user-info .user-image img {
          height: 39px;
          width: 39px; }
      .impact-stories.aeApps .full-image-container .add-post-container .add-story-container .user-info .username,
      .impact-stories-stage.aeApps .full-image-container .add-post-container .add-story-container .user-info .username,
      .impact-stories-app.aeApps .full-image-container .add-post-container .add-story-container .user-info .username {
        font-size: 16px; }
    .impact-stories.aeApps .full-image-container .action-container .back .back-icon-fk,
    .impact-stories-stage.aeApps .full-image-container .action-container .back .back-icon-fk,
    .impact-stories-app.aeApps .full-image-container .action-container .back .back-icon-fk {
      background-image: url(./../assets/impact21/images/backae.svg) !important; }
  .impact-stories.aeApps .image-download-container .download-video-hashtag,
  .impact-stories-stage.aeApps .image-download-container .download-video-hashtag,
  .impact-stories-app.aeApps .image-download-container .download-video-hashtag {
    font-size: 34px; }
  .impact-stories.aeApps .image-download-container .thank-you,
  .impact-stories-stage.aeApps .image-download-container .thank-you,
  .impact-stories-app.aeApps .image-download-container .thank-you {
    font-size: 20px; }
  .impact-stories.aeApps .record-video-container,
  .impact-stories-stage.aeApps .record-video-container,
  .impact-stories-app.aeApps .record-video-container {
    margin-top: 33px;
    padding-top: 20px; }
    .impact-stories.aeApps .record-video-container .heading,
    .impact-stories-stage.aeApps .record-video-container .heading,
    .impact-stories-app.aeApps .record-video-container .heading {
      font-size: 20px;
      height: 40px;
      display: flex;
      align-items: center; }
    .impact-stories.aeApps .record-video-container .video-live-preview,
    .impact-stories-stage.aeApps .record-video-container .video-live-preview,
    .impact-stories-app.aeApps .record-video-container .video-live-preview {
      margin-top: 0; }
    .impact-stories.aeApps .record-video-container .capture-action,
    .impact-stories-stage.aeApps .record-video-container .capture-action,
    .impact-stories-app.aeApps .record-video-container .capture-action {
      margin-top: 0; }
      .impact-stories.aeApps .record-video-container .capture-action.record .image,
      .impact-stories-stage.aeApps .record-video-container .capture-action.record .image,
      .impact-stories-app.aeApps .record-video-container .capture-action.record .image {
        width: 71px;
        height: 71px; }
        .impact-stories.aeApps .record-video-container .capture-action.record .image .video-icon-fk,
        .impact-stories-stage.aeApps .record-video-container .capture-action.record .image .video-icon-fk,
        .impact-stories-app.aeApps .record-video-container .capture-action.record .image .video-icon-fk {
          width: 35.2px;
          height: 23.2px; }
      .impact-stories.aeApps .record-video-container .capture-action.stop .image,
      .impact-stories-stage.aeApps .record-video-container .capture-action.stop .image,
      .impact-stories-app.aeApps .record-video-container .capture-action.stop .image {
        width: 71px;
        height: 71px; }
        .impact-stories.aeApps .record-video-container .capture-action.stop .image .stop-icon-fk,
        .impact-stories-stage.aeApps .record-video-container .capture-action.stop .image .stop-icon-fk,
        .impact-stories-app.aeApps .record-video-container .capture-action.stop .image .stop-icon-fk {
          width: 38px;
          height: 38px; }
  .impact-stories.aeApps .video-preview-container,
  .impact-stories-stage.aeApps .video-preview-container,
  .impact-stories-app.aeApps .video-preview-container {
    margin-top: 33px;
    padding-top: 20px;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.24); }
    .impact-stories.aeApps .video-preview-container .action span,
    .impact-stories-stage.aeApps .video-preview-container .action span,
    .impact-stories-app.aeApps .video-preview-container .action span {
      line-height: 1 !important; }
    .impact-stories.aeApps .video-preview-container .heading,
    .impact-stories-stage.aeApps .video-preview-container .heading,
    .impact-stories-app.aeApps .video-preview-container .heading {
      font-size: 20px;
      display: flex;
      align-items: center;
      height: 40px; }
    .impact-stories.aeApps .video-preview-container .video-preview,
    .impact-stories-stage.aeApps .video-preview-container .video-preview,
    .impact-stories-app.aeApps .video-preview-container .video-preview {
      margin-top: 8px; }
      .impact-stories.aeApps .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container,
      .impact-stories-stage.aeApps .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container,
      .impact-stories-app.aeApps .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container {
        margin-top: 1px;
        height: 6px;
        border-radius: 30px;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.19);
        background-color: #f5f5f5; }
        .impact-stories.aeApps .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek,
        .impact-stories-stage.aeApps .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek,
        .impact-stories-app.aeApps .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek {
          height: 6px;
          border-radius: 30px; }
      .impact-stories.aeApps .video-preview-container .video-preview .player-container .video-controls .video-info,
      .impact-stories-stage.aeApps .video-preview-container .video-preview .player-container .video-controls .video-info,
      .impact-stories-app.aeApps .video-preview-container .video-preview .player-container .video-controls .video-info {
        font-size: 15px; }
  .impact-stories.aeApps .full-video-container,
  .impact-stories-stage.aeApps .full-video-container,
  .impact-stories-app.aeApps .full-video-container {
    width: 95%;
    margin-top: 33px;
    padding-left: 10px;
    padding-right: 10px; }
    .impact-stories.aeApps .full-video-container .heading,
    .impact-stories-stage.aeApps .full-video-container .heading,
    .impact-stories-app.aeApps .full-video-container .heading {
      font-size: 20px; }
    .impact-stories.aeApps .full-video-container .add-post-container,
    .impact-stories-stage.aeApps .full-video-container .add-post-container,
    .impact-stories-app.aeApps .full-video-container .add-post-container {
      margin-top: 20px; }
      .impact-stories.aeApps .full-video-container .add-post-container .video-preview-and-retake,
      .impact-stories-stage.aeApps .full-video-container .add-post-container .video-preview-and-retake,
      .impact-stories-app.aeApps .full-video-container .add-post-container .video-preview-and-retake {
        margin: 0 auto 20px auto; }
        .impact-stories.aeApps .full-video-container .add-post-container .video-preview-and-retake .player-container,
        .impact-stories-stage.aeApps .full-video-container .add-post-container .video-preview-and-retake .player-container,
        .impact-stories-app.aeApps .full-video-container .add-post-container .video-preview-and-retake .player-container {
          margin: auto;
          width: 320px;
          height: 430px; }
          .impact-stories.aeApps .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container,
          .impact-stories-stage.aeApps .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container,
          .impact-stories-app.aeApps .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container {
            margin-top: 1px;
            height: 6px;
            border-radius: 30px;
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.19);
            background-color: #f5f5f5; }
            .impact-stories.aeApps .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container .seek,
            .impact-stories-stage.aeApps .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container .seek,
            .impact-stories-app.aeApps .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container .seek {
              height: 6px;
              border-radius: 30px; }
          .impact-stories.aeApps .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-info,
          .impact-stories-stage.aeApps .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-info,
          .impact-stories-app.aeApps .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-info {
            font-size: 15px; }
        .impact-stories.aeApps .full-video-container .add-post-container .video-preview-and-retake .action-retake,
        .impact-stories-stage.aeApps .full-video-container .add-post-container .video-preview-and-retake .action-retake,
        .impact-stories-app.aeApps .full-video-container .add-post-container .video-preview-and-retake .action-retake {
          margin-top: 50px; }
          .impact-stories.aeApps .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo .text,
          .impact-stories-stage.aeApps .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo .text,
          .impact-stories-app.aeApps .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo .text {
            font-size: 13px; }
      .impact-stories.aeApps .full-video-container .add-post-container .add-story-container .user-info .user-image,
      .impact-stories-stage.aeApps .full-video-container .add-post-container .add-story-container .user-info .user-image,
      .impact-stories-app.aeApps .full-video-container .add-post-container .add-story-container .user-info .user-image {
        height: 45px;
        width: 45px; }
        .impact-stories.aeApps .full-video-container .add-post-container .add-story-container .user-info .user-image img,
        .impact-stories-stage.aeApps .full-video-container .add-post-container .add-story-container .user-info .user-image img,
        .impact-stories-app.aeApps .full-video-container .add-post-container .add-story-container .user-info .user-image img {
          height: 39px;
          width: 39px; }
      .impact-stories.aeApps .full-video-container .add-post-container .add-story-container .user-info .username,
      .impact-stories-stage.aeApps .full-video-container .add-post-container .add-story-container .user-info .username,
      .impact-stories-app.aeApps .full-video-container .add-post-container .add-story-container .user-info .username {
        font-size: 16px; }
  .impact-stories.aeApps .video-post-preview-container,
  .impact-stories-stage.aeApps .video-post-preview-container,
  .impact-stories-app.aeApps .video-post-preview-container {
    margin-top: 10px !important; }
    .impact-stories.aeApps .video-post-preview-container .video-preview .player-container,
    .impact-stories-stage.aeApps .video-post-preview-container .video-preview .player-container,
    .impact-stories-app.aeApps .video-post-preview-container .video-preview .player-container {
      width: 320px;
      height: 460px;
      margin-bottom: 10px; }
      .impact-stories.aeApps .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container,
      .impact-stories-stage.aeApps .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container,
      .impact-stories-app.aeApps .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container {
        height: 6px; }
        .impact-stories.aeApps .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek,
        .impact-stories-stage.aeApps .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek,
        .impact-stories-app.aeApps .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek {
          height: 6px; }
      .impact-stories.aeApps .video-post-preview-container .video-preview .player-container .video-controls .video-info,
      .impact-stories-stage.aeApps .video-post-preview-container .video-preview .player-container .video-controls .video-info,
      .impact-stories-app.aeApps .video-post-preview-container .video-preview .player-container .video-controls .video-info {
        font-size: 15px; }
    .impact-stories.aeApps .video-post-preview-container .story,
    .impact-stories-stage.aeApps .video-post-preview-container .story,
    .impact-stories-app.aeApps .video-post-preview-container .story {
      font-size: 13px; }
    .impact-stories.aeApps .video-post-preview-container .hashtags .hashtag-wrapper,
    .impact-stories-stage.aeApps .video-post-preview-container .hashtags .hashtag-wrapper,
    .impact-stories-app.aeApps .video-post-preview-container .hashtags .hashtag-wrapper {
      font-size: 13px; }
    .impact-stories.aeApps .video-post-preview-container .user-info .user-image,
    .impact-stories-stage.aeApps .video-post-preview-container .user-info .user-image,
    .impact-stories-app.aeApps .video-post-preview-container .user-info .user-image {
      width: 45px;
      height: 45px; }
      .impact-stories.aeApps .video-post-preview-container .user-info .user-image img,
      .impact-stories-stage.aeApps .video-post-preview-container .user-info .user-image img,
      .impact-stories-app.aeApps .video-post-preview-container .user-info .user-image img {
        width: 40px;
        height: 40px; }
    .impact-stories.aeApps .video-post-preview-container .user-info .username,
    .impact-stories-stage.aeApps .video-post-preview-container .user-info .username,
    .impact-stories-app.aeApps .video-post-preview-container .user-info .username {
      font-size: 16px; }
  .impact-stories.aeApps .video-download-container .action .redo,
  .impact-stories-stage.aeApps .video-download-container .action .redo,
  .impact-stories-app.aeApps .video-download-container .action .redo {
    color: white !important;
    background-color: #01366A !important; }
  .impact-stories.aeApps .video-download-container .video-download-preview .video-thumb,
  .impact-stories-stage.aeApps .video-download-container .video-download-preview .video-thumb,
  .impact-stories-app.aeApps .video-download-container .video-download-preview .video-thumb {
    width: 320px;
    height: 460px; }
    .impact-stories.aeApps .video-download-container .video-download-preview .video-thumb .thumb-preview,
    .impact-stories-stage.aeApps .video-download-container .video-download-preview .video-thumb .thumb-preview,
    .impact-stories-app.aeApps .video-download-container .video-download-preview .video-thumb .thumb-preview {
      width: 320px;
      height: 420px;
      object-fit: cover; }
  .impact-stories.aeApps .video-download-container .video-download-preview .download-video-hashtag,
  .impact-stories-stage.aeApps .video-download-container .video-download-preview .download-video-hashtag,
  .impact-stories-app.aeApps .video-download-container .video-download-preview .download-video-hashtag {
    font-size: 34px; }
  .impact-stories.aeApps .video-download-container .video-download-preview .thank-you,
  .impact-stories-stage.aeApps .video-download-container .video-download-preview .thank-you,
  .impact-stories-app.aeApps .video-download-container .video-download-preview .thank-you {
    font-size: 20px; }
  .impact-stories.aeApps .upload-image-container,
  .impact-stories-stage.aeApps .upload-image-container,
  .impact-stories-app.aeApps .upload-image-container {
    padding: 33px 15px 70px 15px;
    margin-top: 33px;
    height: 310px; }
    .impact-stories.aeApps .upload-image-container .heading,
    .impact-stories-stage.aeApps .upload-image-container .heading,
    .impact-stories-app.aeApps .upload-image-container .heading {
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 20px; }
    .impact-stories.aeApps .upload-image-container .drag-n-drop,
    .impact-stories-stage.aeApps .upload-image-container .drag-n-drop,
    .impact-stories-app.aeApps .upload-image-container .drag-n-drop {
      margin-top: 60px;
      border: none;
      background-color: transparent;
      height: max-content; }
      .impact-stories.aeApps .upload-image-container .drag-n-drop .dnd-title,
      .impact-stories-stage.aeApps .upload-image-container .drag-n-drop .dnd-title,
      .impact-stories-app.aeApps .upload-image-container .drag-n-drop .dnd-title {
        display: none; }
      .impact-stories.aeApps .upload-image-container .drag-n-drop p,
      .impact-stories-stage.aeApps .upload-image-container .drag-n-drop p,
      .impact-stories-app.aeApps .upload-image-container .drag-n-drop p {
        display: none; }
      .impact-stories.aeApps .upload-image-container .drag-n-drop .file-upload .max-limit,
      .impact-stories-stage.aeApps .upload-image-container .drag-n-drop .file-upload .max-limit,
      .impact-stories-app.aeApps .upload-image-container .drag-n-drop .file-upload .max-limit {
        font-size: 0.8em;
        margin-top: 8px;
        height: 40px;
        display: flex;
        align-items: center; }
      .impact-stories.aeApps .upload-image-container .drag-n-drop .file-upload .custom-file-upload,
      .impact-stories-stage.aeApps .upload-image-container .drag-n-drop .file-upload .custom-file-upload,
      .impact-stories-app.aeApps .upload-image-container .drag-n-drop .file-upload .custom-file-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0076d5;
        width: 100%;
        max-width: 254px;
        min-height: 40px;
        margin: auto;
        font-size: 1em;
        font-family: CiscoSansTT;
        color: #fff;
        border-radius: 24px;
        cursor: pointer;
        gap: 10px; }
        .impact-stories.aeApps .upload-image-container .drag-n-drop .file-upload .custom-file-upload img,
        .impact-stories-stage.aeApps .upload-image-container .drag-n-drop .file-upload .custom-file-upload img,
        .impact-stories-app.aeApps .upload-image-container .drag-n-drop .file-upload .custom-file-upload img {
          height: 20px; }
      .impact-stories.aeApps .upload-image-container .drag-n-drop .file-upload label .text,
      .impact-stories-stage.aeApps .upload-image-container .drag-n-drop .file-upload label .text,
      .impact-stories-app.aeApps .upload-image-container .drag-n-drop .file-upload label .text {
        font-size: 14px;
        padding-top: 3px; }
      .impact-stories.aeApps .upload-image-container .drag-n-drop .file-upload input,
      .impact-stories-stage.aeApps .upload-image-container .drag-n-drop .file-upload input,
      .impact-stories-app.aeApps .upload-image-container .drag-n-drop .file-upload input {
        display: none; }
    .impact-stories.aeApps .upload-image-container .info,
    .impact-stories-stage.aeApps .upload-image-container .info,
    .impact-stories-app.aeApps .upload-image-container .info {
      position: absolute;
      bottom: 0;
      text-align: center;
      padding-bottom: 25px; }
    .impact-stories.aeApps .upload-image-container .error,
    .impact-stories-stage.aeApps .upload-image-container .error,
    .impact-stories-app.aeApps .upload-image-container .error {
      margin-top: 5px;
      font-size: 0.75em;
      font-family: CiscoSansTTBold;
      text-align: right;
      color: #c10707; }
  .impact-stories.aeApps .upload-multiple-preview-image-container,
  .impact-stories-stage.aeApps .upload-multiple-preview-image-container,
  .impact-stories-app.aeApps .upload-multiple-preview-image-container {
    padding: 13px 18px 44px 18px;
    margin-top: 33px; }
    .impact-stories.aeApps .upload-multiple-preview-image-container .heading,
    .impact-stories-stage.aeApps .upload-multiple-preview-image-container .heading,
    .impact-stories-app.aeApps .upload-multiple-preview-image-container .heading {
      font-size: 20px;
      height: 40px; }
    .impact-stories.aeApps .upload-multiple-preview-image-container .upload-multiple-preview .items,
    .impact-stories-stage.aeApps .upload-multiple-preview-image-container .upload-multiple-preview .items,
    .impact-stories-app.aeApps .upload-multiple-preview-image-container .upload-multiple-preview .items {
      margin-top: 23px; }
      .impact-stories.aeApps .upload-multiple-preview-image-container .upload-multiple-preview .items .item,
      .impact-stories-stage.aeApps .upload-multiple-preview-image-container .upload-multiple-preview .items .item,
      .impact-stories-app.aeApps .upload-multiple-preview-image-container .upload-multiple-preview .items .item {
        margin-bottom: 10px;
        gap: 18px; }
        .impact-stories.aeApps .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-image,
        .impact-stories-stage.aeApps .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-image,
        .impact-stories-app.aeApps .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-image {
          width: 54px;
          height: 54px; }
    .impact-stories.aeApps .upload-multiple-preview-image-container .upload-multiple-preview .action,
    .impact-stories-stage.aeApps .upload-multiple-preview-image-container .upload-multiple-preview .action,
    .impact-stories-app.aeApps .upload-multiple-preview-image-container .upload-multiple-preview .action {
      gap: 5px; }
  .impact-stories.aeApps .upload-preview-container,
  .impact-stories-stage.aeApps .upload-preview-container,
  .impact-stories-app.aeApps .upload-preview-container {
    margin-top: 23px;
    padding: 40px 15px 25px 15px;
    min-height: unset;
    width: 95%; }
    .impact-stories.aeApps .upload-preview-container .action .continue,
    .impact-stories-stage.aeApps .upload-preview-container .action .continue,
    .impact-stories-app.aeApps .upload-preview-container .action .continue {
      background-color: #01366A !important; }
    .impact-stories.aeApps .upload-preview-container .heading,
    .impact-stories-stage.aeApps .upload-preview-container .heading,
    .impact-stories-app.aeApps .upload-preview-container .heading {
      font-size: 20px; }
    .impact-stories.aeApps .upload-preview-container .upload-preview,
    .impact-stories-stage.aeApps .upload-preview-container .upload-preview,
    .impact-stories-app.aeApps .upload-preview-container .upload-preview {
      margin-top: 25px; }
  .impact-stories.aeApps .full-upload-container,
  .impact-stories-stage.aeApps .full-upload-container,
  .impact-stories-app.aeApps .full-upload-container {
    margin-top: 33px; }
    .impact-stories.aeApps .full-upload-container .heading,
    .impact-stories-stage.aeApps .full-upload-container .heading,
    .impact-stories-app.aeApps .full-upload-container .heading {
      font-size: 20px; }
    .impact-stories.aeApps .full-upload-container .add-post-container,
    .impact-stories-stage.aeApps .full-upload-container .add-post-container,
    .impact-stories-app.aeApps .full-upload-container .add-post-container {
      margin-top: 20px; }
      .impact-stories.aeApps .full-upload-container .add-post-container .upload-preview-and-retake,
      .impact-stories-stage.aeApps .full-upload-container .add-post-container .upload-preview-and-retake,
      .impact-stories-app.aeApps .full-upload-container .add-post-container .upload-preview-and-retake {
        margin: 0 auto 20px auto; }
        .impact-stories.aeApps .full-upload-container .add-post-container .upload-preview-and-retake .action-retake,
        .impact-stories-stage.aeApps .full-upload-container .add-post-container .upload-preview-and-retake .action-retake,
        .impact-stories-app.aeApps .full-upload-container .add-post-container .upload-preview-and-retake .action-retake {
          font-size: 13px; }
      .impact-stories.aeApps .full-upload-container .add-post-container .add-story-container .user-info .user-image,
      .impact-stories-stage.aeApps .full-upload-container .add-post-container .add-story-container .user-info .user-image,
      .impact-stories-app.aeApps .full-upload-container .add-post-container .add-story-container .user-info .user-image {
        height: 45px;
        width: 45px; }
        .impact-stories.aeApps .full-upload-container .add-post-container .add-story-container .user-info .user-image img,
        .impact-stories-stage.aeApps .full-upload-container .add-post-container .add-story-container .user-info .user-image img,
        .impact-stories-app.aeApps .full-upload-container .add-post-container .add-story-container .user-info .user-image img {
          height: 39px;
          width: 39px; }
      .impact-stories.aeApps .full-upload-container .add-post-container .add-story-container .user-info .username,
      .impact-stories-stage.aeApps .full-upload-container .add-post-container .add-story-container .user-info .username,
      .impact-stories-app.aeApps .full-upload-container .add-post-container .add-story-container .user-info .username {
        font-size: 16px; }
  .impact-stories.aeApps .upload-post-review-container,
  .impact-stories-stage.aeApps .upload-post-review-container,
  .impact-stories-app.aeApps .upload-post-review-container {
    margin-top: 23px;
    padding: 15px 15px 25px 15px;
    min-height: unset; }
    .impact-stories.aeApps .upload-post-review-container .heading,
    .impact-stories-stage.aeApps .upload-post-review-container .heading,
    .impact-stories-app.aeApps .upload-post-review-container .heading {
      font-size: 20px; }
    .impact-stories.aeApps .upload-post-review-container .image-preview,
    .impact-stories-stage.aeApps .upload-post-review-container .image-preview,
    .impact-stories-app.aeApps .upload-post-review-container .image-preview {
      margin-top: 30px; }
  .impact-stories.aeApps .upload-download-container .action .redo,
  .impact-stories-stage.aeApps .upload-download-container .action .redo,
  .impact-stories-app.aeApps .upload-download-container .action .redo {
    color: white !important;
    background-color: #01366A; }
  .impact-stories.aeApps .upload-download-container .upload-download-preview .download-video-hashtag,
  .impact-stories-stage.aeApps .upload-download-container .upload-download-preview .download-video-hashtag,
  .impact-stories-app.aeApps .upload-download-container .upload-download-preview .download-video-hashtag {
    font-size: 34px; }
  .impact-stories.aeApps .upload-download-container .upload-download-preview .thank-you,
  .impact-stories-stage.aeApps .upload-download-container .upload-download-preview .thank-you,
  .impact-stories-app.aeApps .upload-download-container .upload-download-preview .thank-you {
    font-size: 20px; }
  .impact-stories .footer,
  .impact-stories-stage .footer,
  .impact-stories-app .footer {
    width: 100%;
    height: 80px;
    margin-top: 100px;
    background-color: #0d274d; }
  .impact-stories .spinner,
  .impact-stories-stage .spinner,
  .impact-stories-app .spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 35%;
    left: 40%;
    z-index: 100; }
    .impact-stories .spinner.hide,
    .impact-stories-stage .spinner.hide,
    .impact-stories-app .spinner.hide {
      display: none; }
  .impact-stories .spinner:after,
  .impact-stories-stage .spinner:after,
  .impact-stories-app .spinner:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: load-spinner 1.2s linear infinite; }

@keyframes load-spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  .impact-stories .fk-container,
  .impact-stories-stage .fk-container,
  .impact-stories-app .fk-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 320px; }
    .impact-stories .fk-container .instructions,
    .impact-stories-stage .fk-container .instructions,
    .impact-stories-app .fk-container .instructions {
      font-family: CiscoSansTTLight;
      font-size: 12px;
      color: #0d274d;
      margin-bottom: 5px; }
    .impact-stories .fk-container .top-left-logo,
    .impact-stories-stage .fk-container .top-left-logo,
    .impact-stories-app .fk-container .top-left-logo {
      height: 150px;
      width: 100%;
      background: url(./../assets/impact21/images/top-ribbon-image-desktop.png) no-repeat center center/cover;
      display: flex;
      align-items: center; }
      .impact-stories .fk-container .top-left-logo .logo,
      .impact-stories-stage .fk-container .top-left-logo .logo,
      .impact-stories-app .fk-container .top-left-logo .logo {
        margin-left: 100px; }
        .impact-stories .fk-container .top-left-logo .logo img,
        .impact-stories-stage .fk-container .top-left-logo .logo img,
        .impact-stories-app .fk-container .top-left-logo .logo img {
          width: 150px; }
    .impact-stories .fk-container .title,
    .impact-stories-stage .fk-container .title,
    .impact-stories-app .fk-container .title {
      font-family: CiscoSansTTLight;
      color: #0d274d;
      font-size: 48px;
      margin: 62px 0 14px 106px;
      line-height: 1.2;
      width: 90%; }
    .impact-stories .fk-container .tagline,
    .impact-stories-stage .fk-container .tagline,
    .impact-stories-app .fk-container .tagline {
      font-family: CiscoSansTT;
      margin-left: 106px;
      font-size: 16px;
      line-height: 1.44;
      color: #0d274d;
      margin-bottom: 44px;
      max-width: 800px;
      width: 90%; }
    .impact-stories .fk-container .cancel-btn-mobile,
    .impact-stories-stage .fk-container .cancel-btn-mobile,
    .impact-stories-app .fk-container .cancel-btn-mobile {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      gap: 2px;
      color: #0076d5;
      font-family: 'CiscoSansTT';
      align-items: center;
      display: none;
      font-size: 13px;
      cursor: pointer; }
      .impact-stories .fk-container .cancel-btn-mobile p,
      .impact-stories-stage .fk-container .cancel-btn-mobile p,
      .impact-stories-app .fk-container .cancel-btn-mobile p {
        margin: 2px 0 0 0; }
    .impact-stories .fk-container .btn,
    .impact-stories-stage .fk-container .btn,
    .impact-stories-app .fk-container .btn {
      font-family: CiscoSansTT;
      background-color: #fff;
      color: #0076d5;
      margin: 1.87em 0;
      border-radius: 30px;
      cursor: pointer;
      border: 1px solid #0076d5;
      font-size: 1em;
      width: 96px;
      min-width: 58px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .impact-stories .fk-container.full-image .full-image-container .action-container.full-image::before,
    .impact-stories-stage .fk-container.full-image .full-image-container .action-container.full-image::before,
    .impact-stories-app .fk-container.full-image .full-image-container .action-container.full-image::before {
      background: none; }
    .impact-stories .fk-container.image-review .heading-out,
    .impact-stories-stage .fk-container.image-review .heading-out,
    .impact-stories-app .fk-container.image-review .heading-out {
      margin-top: 72px;
      text-align: center;
      color: #0d274d;
      font-family: CiscoSansTTLight;
      font-size: 32px; }
    .impact-stories .fk-container.image-review .image-post-preview-container,
    .impact-stories-stage .fk-container.image-review .image-post-preview-container,
    .impact-stories-app .fk-container.image-review .image-post-preview-container {
      margin-top: 33px; }
    .impact-stories .fk-container.image-review .action .back,
    .impact-stories-stage .fk-container.image-review .action .back,
    .impact-stories-app .fk-container.image-review .action .back {
      width: 100px;
      min-height: 36px;
      font-size: 1em; }
      .impact-stories .fk-container.image-review .action .back .icon-fk,
      .impact-stories-stage .fk-container.image-review .action .back .icon-fk,
      .impact-stories-app .fk-container.image-review .action .back .icon-fk {
        display: none;
        font-size: 1.25em;
        padding-top: 0.25em; }
      .impact-stories .fk-container.image-review .action .back .image.back-icon-fk,
      .impact-stories-stage .fk-container.image-review .action .back .image.back-icon-fk,
      .impact-stories-app .fk-container.image-review .action .back .image.back-icon-fk {
        background-image: url(./../assets/impact21/images/back-icon.svg);
        width: 20px;
        height: 14px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 5px; }
      .impact-stories .fk-container.image-review .action .back .text,
      .impact-stories-stage .fk-container.image-review .action .back .text,
      .impact-stories-app .fk-container.image-review .action .back .text {
        padding-top: 2px; }
    .impact-stories .fk-container.image-review .action .continue,
    .impact-stories-stage .fk-container.image-review .action .continue,
    .impact-stories-app .fk-container.image-review .action .continue {
      width: 235px; }
      .impact-stories .fk-container.image-review .action .continue .text,
      .impact-stories-stage .fk-container.image-review .action .continue .text,
      .impact-stories-app .fk-container.image-review .action .continue .text {
        padding-top: 2px; }
    .impact-stories .fk-container.image-download,
    .impact-stories-stage .fk-container.image-download,
    .impact-stories-app .fk-container.image-download {
      align-items: flex-start; }
      .impact-stories .fk-container.image-download .action,
      .impact-stories-stage .fk-container.image-download .action,
      .impact-stories-app .fk-container.image-download .action {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: center;
        background-color: transparent;
        background-image: none;
        gap: 20px;
        max-width: 420px; }
        .impact-stories .fk-container.image-download .action .close,
        .impact-stories-stage .fk-container.image-download .action .close,
        .impact-stories-app .fk-container.image-download .action .close {
          background-color: #f5f5f5;
          color: #0076d5;
          width: 190px;
          min-width: 190px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #0076d5;
          height: 40px;
          margin-left: auto;
          margin-right: auto;
          font-size: 14px;
          line-height: 1.33;
          font-family: CiscoSansTT;
          border-radius: 50px;
          cursor: pointer; }
        .impact-stories .fk-container.image-download .action .redo,
        .impact-stories-stage .fk-container.image-download .action .redo,
        .impact-stories-app .fk-container.image-download .action .redo {
          width: 204px;
          min-width: 204px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #0076d5;
          background-color: #0076d5;
          height: 40px;
          margin-left: auto;
          margin-right: auto;
          font-size: 14px;
          color: #fff;
          line-height: 1.33;
          font-family: CiscoSansTT;
          border-radius: 50px;
          cursor: pointer; }
    .impact-stories .fk-container.video-capture .info,
    .impact-stories-stage .fk-container.video-capture .info,
    .impact-stories-app .fk-container.video-capture .info {
      text-align: center;
      margin-top: -30px;
      z-index: 10; }
    .impact-stories .fk-container.full-video .full-video-container .action-container::before,
    .impact-stories-stage .fk-container.full-video .full-video-container .action-container::before,
    .impact-stories-app .fk-container.full-video .full-video-container .action-container::before {
      content: none;
      background: none; }
    .impact-stories .fk-container.video-review .heading-out,
    .impact-stories-stage .fk-container.video-review .heading-out,
    .impact-stories-app .fk-container.video-review .heading-out {
      z-index: 10;
      padding-top: 31px;
      text-align: center;
      color: #0d274d;
      font-family: CiscoSansTTLight;
      font-size: 32px; }
    .impact-stories .fk-container.video-review .video-post-preview-container,
    .impact-stories-stage .fk-container.video-review .video-post-preview-container,
    .impact-stories-app .fk-container.video-review .video-post-preview-container {
      margin-top: 35px; }
    .impact-stories .fk-container.video-review .action,
    .impact-stories-stage .fk-container.video-review .action,
    .impact-stories-app .fk-container.video-review .action {
      max-width: 380px; }
      .impact-stories .fk-container.video-review .action .back .icon-fk,
      .impact-stories-stage .fk-container.video-review .action .back .icon-fk,
      .impact-stories-app .fk-container.video-review .action .back .icon-fk {
        display: none;
        font-size: 1.25em;
        padding-top: 0.25em; }
      .impact-stories .fk-container.video-review .action .back .image.back-icon-fk,
      .impact-stories-stage .fk-container.video-review .action .back .image.back-icon-fk,
      .impact-stories-app .fk-container.video-review .action .back .image.back-icon-fk {
        background-image: url(./../assets/impact21/images/back-icon.svg);
        width: 20px;
        height: 14px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 5px; }
      .impact-stories .fk-container.video-review .action .back .text,
      .impact-stories-stage .fk-container.video-review .action .back .text,
      .impact-stories-app .fk-container.video-review .action .back .text {
        padding-top: 2px;
        color: #0076d5; }
      .impact-stories .fk-container.video-review .action .continue .text,
      .impact-stories-stage .fk-container.video-review .action .continue .text,
      .impact-stories-app .fk-container.video-review .action .continue .text {
        padding-top: 2px; }
    .impact-stories .fk-container.upload-review .heading-out,
    .impact-stories-stage .fk-container.upload-review .heading-out,
    .impact-stories-app .fk-container.upload-review .heading-out {
      margin-top: 60px;
      z-index: 10;
      text-align: center;
      color: #0d274d;
      font-family: CiscoSansTTLight;
      font-size: 32px; }
    .impact-stories .fk-container.upload-review .upload-post-review-container,
    .impact-stories-stage .fk-container.upload-review .upload-post-review-container,
    .impact-stories-app .fk-container.upload-review .upload-post-review-container {
      margin-top: 36px; }
    .impact-stories .fk-container.video-download,
    .impact-stories-stage .fk-container.video-download,
    .impact-stories-app .fk-container.video-download {
      align-items: flex-start; }
      .impact-stories .fk-container.video-download .action .action,
      .impact-stories-stage .fk-container.video-download .action .action,
      .impact-stories-app .fk-container.video-download .action .action {
        display: flex;
        flex-direction: row;
        margin: 0%;
        justify-content: flex-start;
        background-color: transparent;
        background-image: none; }
        .impact-stories .fk-container.video-download .action .action .close,
        .impact-stories-stage .fk-container.video-download .action .action .close,
        .impact-stories-app .fk-container.video-download .action .action .close {
          width: 256px;
          margin-right: 20px;
          margin-top: 3.5em;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f5f5f5;
          min-height: 50px;
          font-size: 0.75em;
          line-height: 1.33;
          font-family: CiscoSansTT;
          color: #0076d5;
          border: 1px solid #0076d5;
          border-radius: 50px;
          cursor: pointer; }
        .impact-stories .fk-container.video-download .action .action .redo,
        .impact-stories-stage .fk-container.video-download .action .action .redo,
        .impact-stories-app .fk-container.video-download .action .action .redo {
          width: 256px;
          margin-top: 2.5em;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #0076d5;
          border: 1px solid #0076d5;
          background-color: #0076d5;
          height: 36px;
          margin-left: auto;
          margin-right: auto;
          font-size: 1em;
          line-height: 1.33;
          font-family: CiscoSansTT;
          color: #f5f5f5;
          border: 4px solid #f5f5f5;
          border-radius: 50px;
          cursor: pointer; }
    .impact-stories .fk-container.upload-image .footer,
    .impact-stories-stage .fk-container.upload-image .footer,
    .impact-stories-app .fk-container.upload-image .footer {
      margin-top: 50px; }
    .impact-stories .fk-container.full-upload .full-upload-container .action-container::before,
    .impact-stories-stage .fk-container.full-upload .full-upload-container .action-container::before,
    .impact-stories-app .fk-container.full-upload .full-upload-container .action-container::before {
      content: none;
      background: none; }
    .impact-stories .fk-container.upload-review .action,
    .impact-stories-stage .fk-container.upload-review .action,
    .impact-stories-app .fk-container.upload-review .action {
      max-width: 380px; }
      .impact-stories .fk-container.upload-review .action .back,
      .impact-stories-stage .fk-container.upload-review .action .back,
      .impact-stories-app .fk-container.upload-review .action .back {
        width: 100px; }
        .impact-stories .fk-container.upload-review .action .back .icon-fk,
        .impact-stories-stage .fk-container.upload-review .action .back .icon-fk,
        .impact-stories-app .fk-container.upload-review .action .back .icon-fk {
          display: none;
          font-size: 1.25em;
          padding-top: 0.25em; }
        .impact-stories .fk-container.upload-review .action .back .image.back-icon-fk,
        .impact-stories-stage .fk-container.upload-review .action .back .image.back-icon-fk,
        .impact-stories-app .fk-container.upload-review .action .back .image.back-icon-fk {
          background-image: url(./../assets/impact21/images/back-icon.svg);
          width: 20px;
          height: 14px;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 5px; }
        .impact-stories .fk-container.upload-review .action .back .text,
        .impact-stories-stage .fk-container.upload-review .action .back .text,
        .impact-stories-app .fk-container.upload-review .action .back .text {
          padding-top: 2px;
          color: #0076d5; }
      .impact-stories .fk-container.upload-review .action .continue,
      .impact-stories-stage .fk-container.upload-review .action .continue,
      .impact-stories-app .fk-container.upload-review .action .continue {
        width: 235px; }
        .impact-stories .fk-container.upload-review .action .continue .text,
        .impact-stories-stage .fk-container.upload-review .action .continue .text,
        .impact-stories-app .fk-container.upload-review .action .continue .text {
          padding-top: 2px; }
    .impact-stories .fk-container.upload-download,
    .impact-stories-stage .fk-container.upload-download,
    .impact-stories-app .fk-container.upload-download {
      align-items: flex-start; }
      .impact-stories .fk-container.upload-download .action,
      .impact-stories-stage .fk-container.upload-download .action,
      .impact-stories-app .fk-container.upload-download .action {
        margin-top: 30px; }
    .impact-stories .fk-container .action,
    .impact-stories-stage .fk-container .action,
    .impact-stories-app .fk-container .action {
      display: flex;
      flex-direction: row;
      margin: 0%;
      justify-content: flex-start;
      background-color: transparent;
      background-image: none;
      max-width: 335px;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 25px; }
      .impact-stories .fk-container .action .back,
      .impact-stories-stage .fk-container .action .back,
      .impact-stories-app .fk-container .action .back {
        width: 100px;
        margin-right: 20px;
        margin-top: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 38px;
        font-size: 1em;
        font-family: CiscoSansTT;
        border: solid 1px #0076d5;
        background-color: #fff;
        border-radius: 24px;
        cursor: pointer; }
        .impact-stories .fk-container .action .back .icon-fk,
        .impact-stories-stage .fk-container .action .back .icon-fk,
        .impact-stories-app .fk-container .action .back .icon-fk {
          margin-right: 1px; }
      .impact-stories .fk-container .action .continue,
      .impact-stories-stage .fk-container .action .continue,
      .impact-stories-app .fk-container .action .continue {
        width: 256px;
        margin-top: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0076d5;
        flex: 1;
        min-height: 40px;
        font-size: 1em;
        font-family: CiscoSansTT;
        color: #fff;
        border-radius: 24px;
        cursor: pointer; }
  .impact-stories .action-container,
  .impact-stories-stage .action-container,
  .impact-stories-app .action-container {
    display: flex;
    flex-direction: column; }
    .impact-stories .action-container .spinner:after,
    .impact-stories-stage .action-container .spinner:after,
    .impact-stories-app .action-container .spinner:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #fff;
      border-color: #0d274d transparent #0d274d transparent;
      animation: load-spinner 1.2s linear infinite; }
    .impact-stories .action-container .auth-error,
    .impact-stories-stage .action-container .auth-error,
    .impact-stories-app .action-container .auth-error {
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: CiscoSansTT;
      color: #0d274d; }
    .impact-stories .action-container .top-left-logo,
    .impact-stories-stage .action-container .top-left-logo,
    .impact-stories-app .action-container .top-left-logo {
      height: 150px;
      width: 100%;
      background: url(./../assets/impact21/images/top-ribbon-image-desktop.png) no-repeat center center/cover;
      display: flex;
      align-items: center; }
      .impact-stories .action-container .top-left-logo .logo,
      .impact-stories-stage .action-container .top-left-logo .logo,
      .impact-stories-app .action-container .top-left-logo .logo {
        margin-left: 100px; }
        .impact-stories .action-container .top-left-logo .logo img,
        .impact-stories-stage .action-container .top-left-logo .logo img,
        .impact-stories-app .action-container .top-left-logo .logo img {
          width: 150px; }
    .impact-stories .action-container .title,
    .impact-stories-stage .action-container .title,
    .impact-stories-app .action-container .title {
      font-family: CiscoSansTTLight;
      color: #0d274d;
      font-size: 40px;
      position: relative;
      margin: 52px 0 16px 100px;
      line-height: 48px; }
    .impact-stories .action-container .tagline,
    .impact-stories-stage .action-container .tagline,
    .impact-stories-app .action-container .tagline {
      margin: 0 0 55px 100px;
      font-family: CiscoSansTT;
      font-size: 16px;
      color: #0d274d;
      line-height: 0.63; }
    .impact-stories .action-container .action-items,
    .impact-stories-stage .action-container .action-items,
    .impact-stories-app .action-container .action-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
      margin-left: 100px;
      margin-right: 10px;
      z-index: 2; }
      .impact-stories .action-container .action-items .action-card,
      .impact-stories-stage .action-container .action-items .action-card,
      .impact-stories-app .action-container .action-items .action-card {
        align-items: center;
        background-color: #fff;
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
        width: 288px;
        z-index: 2;
        position: relative;
        box-sizing: border-box;
        padding: 21px 11px 15px 31px; }
        .impact-stories .action-container .action-items .action-card .top-line,
        .impact-stories-stage .action-container .action-items .action-card .top-line,
        .impact-stories-app .action-container .action-items .action-card .top-line {
          height: 4px;
          background-color: #0076d5;
          position: absolute;
          right: 0;
          left: 0;
          top: 0; }
        .impact-stories .action-container .action-items .action-card.fourth .action-description.twitter,
        .impact-stories-stage .action-container .action-items .action-card.fourth .action-description.twitter,
        .impact-stories-app .action-container .action-items .action-card.fourth .action-description.twitter {
          padding-right: 10px; }
        .impact-stories .action-container .action-items .action-card.fourth .action-btn-bg,
        .impact-stories-stage .action-container .action-items .action-card.fourth .action-btn-bg,
        .impact-stories-app .action-container .action-items .action-card.fourth .action-btn-bg {
          display: none; }
        .impact-stories .action-container .action-items .action-card .action-pic,
        .impact-stories-stage .action-container .action-items .action-card .action-pic,
        .impact-stories-app .action-container .action-items .action-card .action-pic {
          margin-bottom: 10px;
          height: 33px; }
          .impact-stories .action-container .action-items .action-card .action-pic .icon-fk,
          .impact-stories-stage .action-container .action-items .action-card .action-pic .icon-fk,
          .impact-stories-app .action-container .action-items .action-card .action-pic .icon-fk {
            display: none;
            color: #74bf4b;
            font-size: 2em; }
          .impact-stories .action-container .action-items .action-card .action-pic .image,
          .impact-stories-stage .action-container .action-items .action-card .action-pic .image,
          .impact-stories-app .action-container .action-items .action-card .action-pic .image {
            background-size: contain;
            background-repeat: no-repeat; }
            .impact-stories .action-container .action-items .action-card .action-pic .image.camera,
            .impact-stories-stage .action-container .action-items .action-card .action-pic .image.camera,
            .impact-stories-app .action-container .action-items .action-card .action-pic .image.camera {
              background-image: url(./../assets/impact21/images/capture-img-icon.svg);
              background-position: center;
              width: 40px;
              height: 29.6px; }
            .impact-stories .action-container .action-items .action-card .action-pic .image.video,
            .impact-stories-stage .action-container .action-items .action-card .action-pic .image.video,
            .impact-stories-app .action-container .action-items .action-card .action-pic .image.video {
              background-image: url(./../assets/impact21/images/video-recorder-icon.svg);
              background-position: center;
              width: 47px;
              height: 26px; }
            .impact-stories .action-container .action-items .action-card .action-pic .image.upload,
            .impact-stories-stage .action-container .action-items .action-card .action-pic .image.upload,
            .impact-stories-app .action-container .action-items .action-card .action-pic .image.upload {
              background-image: url(./../assets/impact21/images/upload-img-icon.svg);
              background-position: center;
              width: 43.6px;
              height: 29px; }
            .impact-stories .action-container .action-items .action-card .action-pic .image.twitter,
            .impact-stories-stage .action-container .action-items .action-card .action-pic .image.twitter,
            .impact-stories-app .action-container .action-items .action-card .action-pic .image.twitter {
              background-image: url(./../assets/impact21/images/twitter-img.svg);
              background-position: center;
              width: 40px;
              height: 32.5px; }
        .impact-stories .action-container .action-items .action-card .action-heading,
        .impact-stories-stage .action-container .action-items .action-card .action-heading,
        .impact-stories-app .action-container .action-items .action-card .action-heading {
          font-family: CiscoSansTTLight;
          font-size: 1.5em;
          line-height: 32px;
          color: #0d274d;
          margin-bottom: 10px; }
        .impact-stories .action-container .action-items .action-card .action-description,
        .impact-stories-stage .action-container .action-items .action-card .action-description,
        .impact-stories-app .action-container .action-items .action-card .action-description {
          font-family: CiscoSansTT;
          font-size: 0.75em;
          line-height: 1.25;
          color: #0d274d;
          margin: 3px 0 25px; }
        .impact-stories .action-container .action-items .action-card .action-btn-bg .action-btn,
        .impact-stories-stage .action-container .action-items .action-card .action-btn-bg .action-btn,
        .impact-stories-app .action-container .action-items .action-card .action-btn-bg .action-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #0076d5;
          width: 75%;
          max-width: 107px;
          font-size: 12px;
          font-family: CiscoSansTT;
          padding: 0.9em 0;
          color: #fff;
          border-radius: 24px;
          cursor: pointer; }
  .impact-stories .image-capture-container,
  .impact-stories-stage .image-capture-container,
  .impact-stories-app .image-capture-container {
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 459px;
    min-height: 722px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 32px 0 44px 0;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
    background-color: #fff;
    margin-top: 78px;
    z-index: 1;
    height: 100%;
    position: relative; }
    .impact-stories .image-capture-container .heading,
    .impact-stories-stage .image-capture-container .heading,
    .impact-stories-app .image-capture-container .heading {
      font-family: CiscoSansTTLight;
      font-size: 32px; }
    .impact-stories .image-capture-container .image-live-preview,
    .impact-stories-stage .image-capture-container .image-live-preview,
    .impact-stories-app .image-capture-container .image-live-preview {
      z-index: 10;
      margin-top: 25px;
      position: relative;
      width: 90%;
      max-width: 361px;
      height: 100%; }
      .impact-stories .image-capture-container .image-live-preview .camera-error,
      .impact-stories-stage .image-capture-container .image-live-preview .camera-error,
      .impact-stories-app .image-capture-container .image-live-preview .camera-error {
        font-family: CiscoSansTT;
        text-align: center;
        color: #c10707; }
      .impact-stories .image-capture-container .image-live-preview .web-cam-container,
      .impact-stories-stage .image-capture-container .image-live-preview .web-cam-container,
      .impact-stories-app .image-capture-container .image-live-preview .web-cam-container {
        position: relative;
        margin-left: auto;
        margin-right: auto; }
      .impact-stories .image-capture-container .image-live-preview .capture-overlay,
      .impact-stories-stage .image-capture-container .image-live-preview .capture-overlay,
      .impact-stories-app .image-capture-container .image-live-preview .capture-overlay {
        position: absolute;
        font-size: 8em;
        color: #0076d5;
        font-family: CiscoSansTTBold;
        height: 100%;
        width: 100%;
        max-width: 361px;
        max-height: 487px;
        background-color: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -2px;
        border-radius: 10px;
        z-index: 10; }
        .impact-stories .image-capture-container .image-live-preview .capture-overlay.hide,
        .impact-stories-stage .image-capture-container .image-live-preview .capture-overlay.hide,
        .impact-stories-app .image-capture-container .image-live-preview .capture-overlay.hide {
          display: none; }
        .impact-stories .image-capture-container .image-live-preview .capture-overlay.error,
        .impact-stories-stage .image-capture-container .image-live-preview .capture-overlay.error,
        .impact-stories-app .image-capture-container .image-live-preview .capture-overlay.error {
          font-size: 1em;
          left: 10%;
          text-align: center; }
      .impact-stories .image-capture-container .image-live-preview video,
      .impact-stories-stage .image-capture-container .image-live-preview video,
      .impact-stories-app .image-capture-container .image-live-preview video {
        width: 100%;
        height: 100%;
        border: #fff 4px solid;
        box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
        border-radius: 10px;
        position: relative; }
      .impact-stories .image-capture-container .image-live-preview .overlay.error,
      .impact-stories-stage .image-capture-container .image-live-preview .overlay.error,
      .impact-stories-app .image-capture-container .image-live-preview .overlay.error {
        margin-top: 20px;
        position: relative;
        width: 100%;
        max-width: 330px;
        height: 100%;
        max-height: 600px;
        border-radius: 8px;
        text-align: center; }
    .impact-stories .image-capture-container .capture-action,
    .impact-stories-stage .image-capture-container .capture-action,
    .impact-stories-app .image-capture-container .capture-action {
      margin-top: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #0076d5;
      width: 100%;
      min-height: 40px;
      margin-left: auto;
      margin-right: auto;
      font-size: 1em;
      line-height: 1.33;
      font-family: CiscoSansTT;
      color: #fff;
      border-radius: 24px;
      cursor: pointer; }
      .impact-stories .image-capture-container .capture-action .icon-fk,
      .impact-stories-stage .image-capture-container .capture-action .icon-fk,
      .impact-stories-app .image-capture-container .capture-action .icon-fk {
        font-size: 1.25em;
        padding-top: 0.25em;
        margin-right: 0.25em;
        display: none; }
      .impact-stories .image-capture-container .capture-action .image.camera,
      .impact-stories-stage .image-capture-container .capture-action .image.camera,
      .impact-stories-app .image-capture-container .capture-action .image.camera {
        background-image: url(./../assets/impact21/images/capture-camera-img.svg);
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 5px 10px 0; }
      .impact-stories .image-capture-container .capture-action .text,
      .impact-stories-stage .image-capture-container .capture-action .text,
      .impact-stories-app .image-capture-container .capture-action .text {
        padding-top: 4px; }
    .impact-stories .image-capture-container .info,
    .impact-stories-stage .image-capture-container .info,
    .impact-stories-app .image-capture-container .info {
      text-align: center;
      margin-top: 40px; }
  .impact-stories .image-preview-container,
  .impact-stories-stage .image-preview-container,
  .impact-stories-app .image-preview-container {
    z-index: 2;
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 33px 50px 37px 50px;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
    background-color: #fff;
    margin-top: 78px;
    position: relative; }
    .impact-stories .image-preview-container .heading,
    .impact-stories-stage .image-preview-container .heading,
    .impact-stories-app .image-preview-container .heading {
      text-align: center;
      font-size: 32px;
      font-family: CiscoSansTTLight; }
    .impact-stories .image-preview-container .image-preview,
    .impact-stories-stage .image-preview-container .image-preview,
    .impact-stories-app .image-preview-container .image-preview {
      margin-top: 25px;
      position: relative;
      width: 100%;
      height: 100%; }
      .impact-stories .image-preview-container .image-preview img,
      .impact-stories-stage .image-preview-container .image-preview img,
      .impact-stories-app .image-preview-container .image-preview img {
        display: none;
        width: 100%;
        height: 100%;
        border: 4px solid #f5f5f5;
        box-shadow: 16px 14px 20px 0px #00000033;
        border-radius: 10px; }
      .impact-stories .image-preview-container .image-preview .sticker-container,
      .impact-stories-stage .image-preview-container .image-preview .sticker-container,
      .impact-stories-app .image-preview-container .image-preview .sticker-container {
        width: 100%; }
        .impact-stories .image-preview-container .image-preview .sticker-container.hide-stickers,
        .impact-stories-stage .image-preview-container .image-preview .sticker-container.hide-stickers,
        .impact-stories-app .image-preview-container .image-preview .sticker-container.hide-stickers {
          display: none; }
        .impact-stories .image-preview-container .image-preview .sticker-container .spinner.show,
        .impact-stories-stage .image-preview-container .image-preview .sticker-container .spinner.show,
        .impact-stories-app .image-preview-container .image-preview .sticker-container .spinner.show {
          left: 35%; }
        .impact-stories .image-preview-container .image-preview .sticker-container .canvas-container,
        .impact-stories-stage .image-preview-container .image-preview .sticker-container .canvas-container,
        .impact-stories-app .image-preview-container .image-preview .sticker-container .canvas-container {
          border-radius: 10px;
          max-width: 350px;
          margin: auto; }
          .impact-stories .image-preview-container .image-preview .sticker-container .canvas-container canvas,
          .impact-stories-stage .image-preview-container .image-preview .sticker-container .canvas-container canvas,
          .impact-stories-app .image-preview-container .image-preview .sticker-container .canvas-container canvas {
            border-radius: 18px;
            border: #f4f4f4 4px solid;
            box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23); }
          .impact-stories .image-preview-container .image-preview .sticker-container .canvas-container .upper-canvas,
          .impact-stories-stage .image-preview-container .image-preview .sticker-container .canvas-container .upper-canvas,
          .impact-stories-app .image-preview-container .image-preview .sticker-container .canvas-container .upper-canvas {
            position: relative !important; }
          .impact-stories .image-preview-container .image-preview .sticker-container .canvas-container .hide-stickers,
          .impact-stories-stage .image-preview-container .image-preview .sticker-container .canvas-container .hide-stickers,
          .impact-stories-app .image-preview-container .image-preview .sticker-container .canvas-container .hide-stickers {
            display: none !important; }
          .impact-stories .image-preview-container .image-preview .sticker-container .canvas-container #fabricCanvas,
          .impact-stories-stage .image-preview-container .image-preview .sticker-container .canvas-container #fabricCanvas,
          .impact-stories-app .image-preview-container .image-preview .sticker-container .canvas-container #fabricCanvas {
            width: 340px;
            height: 450px;
            border-radius: 10px; }
          .impact-stories .image-preview-container .image-preview .sticker-container .canvas-container #imageCanvas,
          .impact-stories-stage .image-preview-container .image-preview .sticker-container .canvas-container #imageCanvas,
          .impact-stories-app .image-preview-container .image-preview .sticker-container .canvas-container #imageCanvas {
            width: 340px;
            height: 450px; }
        .impact-stories .image-preview-container .image-preview .sticker-container .select-sticker-container,
        .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-sticker-container,
        .impact-stories-app .image-preview-container .image-preview .sticker-container .select-sticker-container {
          margin-top: 25px;
          border-bottom: 2px solid #f0f0f0;
          border-top: 2px solid #f0f0f0;
          padding: 17px 0; }
          .impact-stories .image-preview-container .image-preview .sticker-container .select-sticker-container .heading,
          .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-sticker-container .heading,
          .impact-stories-app .image-preview-container .image-preview .sticker-container .select-sticker-container .heading {
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .impact-stories .image-preview-container .image-preview .sticker-container .select-sticker-container .heading .heading-title,
            .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-sticker-container .heading .heading-title,
            .impact-stories-app .image-preview-container .image-preview .sticker-container .select-sticker-container .heading .heading-title {
              text-align: left;
              font-size: 24px;
              color: #1a3d69;
              font-family: CiscoSansTTLight;
              line-height: 1.33; }
            .impact-stories .image-preview-container .image-preview .sticker-container .select-sticker-container .heading .clear-btn,
            .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-sticker-container .heading .clear-btn,
            .impact-stories-app .image-preview-container .image-preview .sticker-container .select-sticker-container .heading .clear-btn {
              cursor: pointer;
              background: #f6be00;
              display: flex;
              padding: 4px 15px;
              border-radius: 30px;
              font-family: 'CiscoSansTTLight'; }
              .impact-stories .image-preview-container .image-preview .sticker-container .select-sticker-container .heading .clear-btn span,
              .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-sticker-container .heading .clear-btn span,
              .impact-stories-app .image-preview-container .image-preview .sticker-container .select-sticker-container .heading .clear-btn span {
                font-size: 12px;
                padding-top: 2px; }
          .impact-stories .image-preview-container .image-preview .sticker-container .select-sticker-container .sub-heading,
          .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-sticker-container .sub-heading,
          .impact-stories-app .image-preview-container .image-preview .sticker-container .select-sticker-container .sub-heading {
            text-align: left;
            line-height: 1.33;
            font-size: 1em;
            color: #1a3d69;
            font-family: CiscoSansTTLight;
            line-height: 1.33;
            margin-bottom: 13px; }
          .impact-stories .image-preview-container .image-preview .sticker-container .select-sticker-container .stickers-preview,
          .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-sticker-container .stickers-preview,
          .impact-stories-app .image-preview-container .image-preview .sticker-container .select-sticker-container .stickers-preview {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center; }
            .impact-stories .image-preview-container .image-preview .sticker-container .select-sticker-container .stickers-preview .sticker,
            .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-sticker-container .stickers-preview .sticker,
            .impact-stories-app .image-preview-container .image-preview .sticker-container .select-sticker-container .stickers-preview .sticker {
              cursor: pointer;
              background-color: #0076d5;
              box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.25);
              border-radius: 10px;
              margin: 5px 16px 5px 0;
              width: 55px;
              height: 55px;
              min-width: 55px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center; }
              .impact-stories .image-preview-container .image-preview .sticker-container .select-sticker-container .stickers-preview .sticker.clear,
              .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-sticker-container .stickers-preview .sticker.clear,
              .impact-stories-app .image-preview-container .image-preview .sticker-container .select-sticker-container .stickers-preview .sticker.clear {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-width: 55px;
                text-align: center;
                font-size: 0.5em;
                color: #0076d5;
                font-family: CiscoSansTT;
                background-color: #fff;
                box-shadow: 3px 3px 3px 0px #0000002b, inset 3px 3px 3px 0px white; }
                .impact-stories .image-preview-container .image-preview .sticker-container .select-sticker-container .stickers-preview .sticker.clear .icon-fk,
                .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-sticker-container .stickers-preview .sticker.clear .icon-fk,
                .impact-stories-app .image-preview-container .image-preview .sticker-container .select-sticker-container .stickers-preview .sticker.clear .icon-fk {
                  font-size: 2em;
                  color: #0076d5;
                  margin-bottom: 5px; }
        .impact-stories .image-preview-container .image-preview .sticker-container .select-bg-container,
        .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-bg-container,
        .impact-stories-app .image-preview-container .image-preview .sticker-container .select-bg-container {
          border-bottom: 2px solid #f0f0f0;
          padding: 17px 0; }
          .impact-stories .image-preview-container .image-preview .sticker-container .select-bg-container .heading,
          .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-bg-container .heading,
          .impact-stories-app .image-preview-container .image-preview .sticker-container .select-bg-container .heading {
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .impact-stories .image-preview-container .image-preview .sticker-container .select-bg-container .heading .heading-title,
            .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-bg-container .heading .heading-title,
            .impact-stories-app .image-preview-container .image-preview .sticker-container .select-bg-container .heading .heading-title {
              text-align: left;
              font-size: 24px;
              color: #1a3d69;
              font-family: CiscoSansTTLight;
              line-height: 1.33; }
            .impact-stories .image-preview-container .image-preview .sticker-container .select-bg-container .heading .clear-btn,
            .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-bg-container .heading .clear-btn,
            .impact-stories-app .image-preview-container .image-preview .sticker-container .select-bg-container .heading .clear-btn {
              cursor: pointer;
              background: #f6be00;
              display: flex;
              padding: 4px 15px;
              border-radius: 30px;
              font-family: 'CiscoSansTTLight'; }
              .impact-stories .image-preview-container .image-preview .sticker-container .select-bg-container .heading .clear-btn span,
              .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-bg-container .heading .clear-btn span,
              .impact-stories-app .image-preview-container .image-preview .sticker-container .select-bg-container .heading .clear-btn span {
                font-size: 12px;
                padding-top: 2px; }
          .impact-stories .image-preview-container .image-preview .sticker-container .select-bg-container .sub-heading,
          .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-bg-container .sub-heading,
          .impact-stories-app .image-preview-container .image-preview .sticker-container .select-bg-container .sub-heading {
            text-align: left;
            line-height: 1.33;
            font-size: 1em;
            color: #1a3d69;
            font-family: CiscoSansTTLight;
            line-height: 1.33;
            margin-bottom: 13px; }
          .impact-stories .image-preview-container .image-preview .sticker-container .select-bg-container .bgs-preview,
          .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-bg-container .bgs-preview,
          .impact-stories-app .image-preview-container .image-preview .sticker-container .select-bg-container .bgs-preview {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center; }
            .impact-stories .image-preview-container .image-preview .sticker-container .select-bg-container .bgs-preview .bg,
            .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-bg-container .bgs-preview .bg,
            .impact-stories-app .image-preview-container .image-preview .sticker-container .select-bg-container .bgs-preview .bg {
              cursor: pointer;
              box-shadow: 3px 3px 3px 0px #0000002b;
              border-radius: 10px;
              margin: 5px 16px 5px 0px;
              width: 55px;
              height: 55px;
              min-width: 55px;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center; }
              .impact-stories .image-preview-container .image-preview .sticker-container .select-bg-container .bgs-preview .bg.clear,
              .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-bg-container .bgs-preview .bg.clear,
              .impact-stories-app .image-preview-container .image-preview .sticker-container .select-bg-container .bgs-preview .bg.clear {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 0.5em;
                font-family: CiscoSansTT;
                box-shadow: 3px 3px 3px 0px #0000002b, inset 3px 3px 3px 0px white;
                background-color: #fff;
                color: #0076d5; }
                .impact-stories .image-preview-container .image-preview .sticker-container .select-bg-container .bgs-preview .bg.clear .icon-fk,
                .impact-stories-stage .image-preview-container .image-preview .sticker-container .select-bg-container .bgs-preview .bg.clear .icon-fk,
                .impact-stories-app .image-preview-container .image-preview .sticker-container .select-bg-container .bgs-preview .bg.clear .icon-fk {
                  font-size: 2em;
                  color: #0076d5;
                  margin-bottom: 5px; }
    .impact-stories .image-preview-container .action,
    .impact-stories-stage .image-preview-container .action,
    .impact-stories-app .image-preview-container .action {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 88%;
      margin-bottom: 0px; }
      .impact-stories .image-preview-container .action .redo,
      .impact-stories-stage .image-preview-container .action .redo,
      .impact-stories-app .image-preview-container .action .redo {
        margin-right: 20px;
        margin-top: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        font-size: 1em;
        line-height: 1.33;
        font-family: CiscoSansTT;
        color: #0076d5;
        border: 1px solid #0076d5;
        background-color: #fff;
        border-radius: 24px;
        padding: 0 20px;
        cursor: pointer; }
        .impact-stories .image-preview-container .action .redo .icon-fk,
        .impact-stories-stage .image-preview-container .action .redo .icon-fk,
        .impact-stories-app .image-preview-container .action .redo .icon-fk {
          display: none;
          font-size: 1.25em;
          padding-top: 0.25em; }
        .impact-stories .image-preview-container .action .redo .image.camera,
        .impact-stories-stage .image-preview-container .action .redo .image.camera,
        .impact-stories-app .image-preview-container .action .redo .image.camera {
          background-image: url(./../assets/impact21/images/back-icon.svg);
          width: 20px;
          height: 14px;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 5px; }
        .impact-stories .image-preview-container .action .redo .text,
        .impact-stories-stage .image-preview-container .action .redo .text,
        .impact-stories-app .image-preview-container .action .redo .text {
          padding-top: 3px; }
      .impact-stories .image-preview-container .action .continue,
      .impact-stories-stage .image-preview-container .action .continue,
      .impact-stories-app .image-preview-container .action .continue {
        margin-top: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0076d5;
        flex: 1;
        min-height: 40px;
        border: none;
        font-size: 1em;
        line-height: 1.33;
        font-family: CiscoSansTT;
        color: #fff;
        border-radius: 24px;
        cursor: pointer;
        background-image: none;
        box-shadow: none; }
        .impact-stories .image-preview-container .action .continue .text,
        .impact-stories-stage .image-preview-container .action .continue .text,
        .impact-stories-app .image-preview-container .action .continue .text {
          padding-top: 3px; }
    .impact-stories .image-preview-container .capture-action,
    .impact-stories-stage .image-preview-container .capture-action,
    .impact-stories-app .image-preview-container .capture-action {
      cursor: pointer;
      text-align: center;
      background-color: #1a3d69;
      color: #ffffff;
      border-radius: 10px;
      padding: 1em;
      margin: 1em; }
  .impact-stories .full-image-container,
  .impact-stories-stage .full-image-container,
  .impact-stories-app .full-image-container {
    z-index: 11;
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 29px 37px 29px;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
    background-color: #fff;
    margin-top: 119px;
    width: 90%;
    max-width: 913px;
    position: relative; }
    .impact-stories .full-image-container .heading,
    .impact-stories-stage .full-image-container .heading,
    .impact-stories-app .full-image-container .heading {
      text-align: center;
      font-size: 32px;
      font-family: CiscoSansTTLight; }
    .impact-stories .full-image-container .add-post-container,
    .impact-stories-stage .full-image-container .add-post-container,
    .impact-stories-app .full-image-container .add-post-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 30px;
      width: 100%; }
      .impact-stories .full-image-container .add-post-container .image-preview-and-retake,
      .impact-stories-stage .full-image-container .add-post-container .image-preview-and-retake,
      .impact-stories-app .full-image-container .add-post-container .image-preview-and-retake {
        position: relative;
        width: 100%;
        max-width: 370px;
        height: 100%;
        margin-right: 27px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .impact-stories .full-image-container .add-post-container .image-preview-and-retake img,
        .impact-stories-stage .full-image-container .add-post-container .image-preview-and-retake img,
        .impact-stories-app .full-image-container .add-post-container .image-preview-and-retake img {
          border: 4px solid #f4f4f4;
          box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
          border-radius: 10px; }
        .impact-stories .full-image-container .add-post-container .image-preview-and-retake .action-retake,
        .impact-stories-stage .full-image-container .add-post-container .image-preview-and-retake .action-retake,
        .impact-stories-app .full-image-container .add-post-container .image-preview-and-retake .action-retake {
          margin-top: 20px;
          font-size: 1em;
          line-height: 1.33;
          font-family: CiscoSansTT;
          cursor: pointer;
          color: #0076d5;
          display: flex;
          justify-content: center; }
          .impact-stories .full-image-container .add-post-container .image-preview-and-retake .action-retake .btn-redo,
          .impact-stories-stage .full-image-container .add-post-container .image-preview-and-retake .action-retake .btn-redo,
          .impact-stories-app .full-image-container .add-post-container .image-preview-and-retake .action-retake .btn-redo {
            border-bottom: 2px solid; }
      .impact-stories .full-image-container .add-post-container .add-story-container,
      .impact-stories-stage .full-image-container .add-post-container .add-story-container,
      .impact-stories-app .full-image-container .add-post-container .add-story-container {
        flex: 1; }
        .impact-stories .full-image-container .add-post-container .add-story-container .user-info,
        .impact-stories-stage .full-image-container .add-post-container .add-story-container .user-info,
        .impact-stories-app .full-image-container .add-post-container .add-story-container .user-info {
          display: flex;
          align-items: center; }
          .impact-stories .full-image-container .add-post-container .add-story-container .user-info .user-image,
          .impact-stories-stage .full-image-container .add-post-container .add-story-container .user-info .user-image,
          .impact-stories-app .full-image-container .add-post-container .add-story-container .user-info .user-image {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #0d274d;
            width: 41px;
            height: 41px;
            border-radius: 50%; }
          .impact-stories .full-image-container .add-post-container .add-story-container .user-info img,
          .impact-stories-stage .full-image-container .add-post-container .add-story-container .user-info img,
          .impact-stories-app .full-image-container .add-post-container .add-story-container .user-info img {
            width: 36px;
            height: 36px;
            border-radius: 50%; }
          .impact-stories .full-image-container .add-post-container .add-story-container .user-info .username,
          .impact-stories-stage .full-image-container .add-post-container .add-story-container .user-info .username,
          .impact-stories-app .full-image-container .add-post-container .add-story-container .user-info .username {
            margin-left: 14px;
            display: flex;
            justify-content: flex-start;
            font-size: 14px;
            line-height: 1.5;
            font-family: CiscoSans; }
            .impact-stories .full-image-container .add-post-container .add-story-container .user-info .username span,
            .impact-stories-stage .full-image-container .add-post-container .add-story-container .user-info .username span,
            .impact-stories-app .full-image-container .add-post-container .add-story-container .user-info .username span {
              padding: 0.15em; }
            .impact-stories .full-image-container .add-post-container .add-story-container .user-info .username .firstname,
            .impact-stories-stage .full-image-container .add-post-container .add-story-container .user-info .username .firstname,
            .impact-stories-app .full-image-container .add-post-container .add-story-container .user-info .username .firstname {
              text-transform: capitalize; }
            .impact-stories .full-image-container .add-post-container .add-story-container .user-info .username .lastname,
            .impact-stories-stage .full-image-container .add-post-container .add-story-container .user-info .username .lastname,
            .impact-stories-app .full-image-container .add-post-container .add-story-container .user-info .username .lastname {
              text-transform: capitalize; }
        .impact-stories .full-image-container .add-post-container .add-story-container .story-container,
        .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container,
        .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container {
          margin-top: 25px; }
          .impact-stories .full-image-container .add-post-container .add-story-container .story-container .heading,
          .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container .heading,
          .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container .heading {
            font-size: 24px;
            font-family: CiscoSansLight;
            line-height: 1.33;
            text-align: left; }
          .impact-stories .full-image-container .add-post-container .add-story-container .story-container .sub-heading,
          .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container .sub-heading,
          .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container .sub-heading {
            font-size: 13px;
            font-family: CiscoSansTT;
            line-height: 1.33;
            text-align: left;
            margin-bottom: 18px;
            margin-top: 5px; }
          .impact-stories .full-image-container .add-post-container .add-story-container .story-container .text-area,
          .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container .text-area,
          .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container .text-area {
            display: flex;
            flex-direction: column;
            cursor: text; }
            .impact-stories .full-image-container .add-post-container .add-story-container .story-container .text-area.error textarea,
            .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container .text-area.error textarea,
            .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container .text-area.error textarea {
              border: 1px solid #c10707; }
            .impact-stories .full-image-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta,
            .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta,
            .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta {
              font-family: CiscoSansTT;
              justify-content: space-between; }
              .impact-stories .full-image-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta .error-msg,
              .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta .error-msg,
              .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta .error-msg {
                display: inline;
                color: #c10707;
                font-family: CiscoSansTTBold;
                margin-top: 5px; }
            .impact-stories .full-image-container .add-post-container .add-story-container .story-container .text-area textarea,
            .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container .text-area textarea,
            .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container .text-area textarea {
              height: 212px;
              width: 100%;
              font-size: 13px;
              border-radius: 5px;
              box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.24);
              background-color: #f9f9f9;
              border: none;
              padding-top: 1em;
              padding-left: 1em;
              resize: none;
              font-family: CiscoSansTT;
              cursor: text; }
              .impact-stories .full-image-container .add-post-container .add-story-container .story-container .text-area textarea:focus-visible,
              .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container .text-area textarea:focus-visible,
              .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container .text-area textarea:focus-visible {
                outline: none; }
              .impact-stories .full-image-container .add-post-container .add-story-container .story-container .text-area textarea:active,
              .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container .text-area textarea:active,
              .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container .text-area textarea:active {
                outline: none; }
            .impact-stories .full-image-container .add-post-container .add-story-container .story-container .text-area .text-area-meta,
            .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container .text-area .text-area-meta,
            .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container .text-area .text-area-meta {
              display: flex;
              justify-content: flex-end;
              font-size: 0.625em;
              padding: 0.1em;
              font-family: CiscoSansTT; }
              .impact-stories .full-image-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .error-msg,
              .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .error-msg,
              .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .error-msg {
                display: none; }
              .impact-stories .full-image-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .character-count,
              .impact-stories-stage .full-image-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .character-count,
              .impact-stories-app .full-image-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .character-count {
                margin-top: 5px; }
        .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container,
        .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container,
        .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container {
          margin-top: 25px; }
          .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .heading,
          .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .heading,
          .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .heading {
            font-size: 24px;
            font-family: CiscoSansLight;
            text-align: left;
            line-height: 1.33; }
          .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .sub-heading,
          .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .sub-heading,
          .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .sub-heading {
            text-align: left;
            font-family: CiscoSansTT;
            font-size: 13px;
            line-height: 1.33;
            margin-top: 5px; }
          .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags,
          .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags,
          .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags {
            margin-top: 20px;
            padding: 0.4em;
            border: none;
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            flex-wrap: wrap;
            border-radius: 10px;
            box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.24);
            background-color: #f9f9f9;
            min-height: 50px; }
            .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags.hideinput input,
            .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags.hideinput input,
            .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags.hideinput input {
              display: none; }
            .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper,
            .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper,
            .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper {
              display: flex;
              color: #525354;
              border-radius: 8px;
              background-color: #e2e2e2;
              height: 31px;
              padding-right: 4px;
              align-items: center; }
              .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .hashtag,
              .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .hashtag,
              .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .hashtag {
                font-family: CiscoSansTT;
                font-size: 13px;
                padding: 1px 0.75em;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: alias; }
              .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .icon-fk,
              .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .icon-fk,
              .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .icon-fk {
                cursor: pointer;
                margin-left: -7px;
                color: #0076D5; }
            .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags input,
            .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags input,
            .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags input {
              border: none;
              font-size: 16px;
              background-color: transparent;
              color: rgba(13, 39, 77, 0.5); }
              .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags input:active,
              .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags input:active,
              .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags input:active {
                border: none; }
              .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags input:focus-visible,
              .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags input:focus-visible,
              .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags input:focus-visible {
                border: none;
                outline: none; }
          .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion,
          .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion,
          .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            max-width: 450px;
            margin-top: 10px; }
            .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper,
            .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper,
            .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper {
              display: flex;
              background-color: #ffffff;
              margin: 0.15em;
              color: #525354;
              border-radius: 8px;
              background-color: #e2e2e2;
              align-items: center;
              height: 32px; }
              .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .hashtag,
              .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .hashtag,
              .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .hashtag {
                font-family: CiscoSansTT;
                font-size: 13px;
                padding: 0.05em 0.75em;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer; }
              .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .icon-fk,
              .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .icon-fk,
              .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .icon-fk {
                cursor: pointer;
                display: none; }
              .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image,
              .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image,
              .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image {
                cursor: pointer; }
                .impact-stories .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image.icon-fk-add,
                .impact-stories-stage .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image.icon-fk-add,
                .impact-stories-app .full-image-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image.icon-fk-add {
                  background-image: url(./../assets/impact21/images/icon-add.svg);
                  width: 20px;
                  height: 20px;
                  background-repeat: no-repeat;
                  background-size: contain;
                  margin-right: 5px; }
    .impact-stories .full-image-container .action-container,
    .impact-stories-stage .full-image-container .action-container,
    .impact-stories-app .full-image-container .action-container {
      flex-direction: row;
      margin: 0%;
      justify-content: space-between;
      background-color: transparent;
      background-image: none;
      width: 100%;
      max-width: 380px; }
      .impact-stories .full-image-container .action-container .back,
      .impact-stories-stage .full-image-container .action-container .back,
      .impact-stories-app .full-image-container .action-container .back {
        width: 100px;
        margin-right: 20px;
        margin-top: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 36px;
        font-size: 1em;
        line-height: 1.33;
        font-family: CiscoSansTT;
        color: #0076d5;
        border: 1px solid #0076d5;
        border-radius: 24px;
        cursor: pointer; }
        .impact-stories .full-image-container .action-container .back .icon-fk,
        .impact-stories-stage .full-image-container .action-container .back .icon-fk,
        .impact-stories-app .full-image-container .action-container .back .icon-fk {
          display: none; }
        .impact-stories .full-image-container .action-container .back .image.back-icon-fk,
        .impact-stories-stage .full-image-container .action-container .back .image.back-icon-fk,
        .impact-stories-app .full-image-container .action-container .back .image.back-icon-fk {
          background: url(./../assets/impact21/images/back-icon.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 20px;
          height: 14px; }
        .impact-stories .full-image-container .action-container .back .text,
        .impact-stories-stage .full-image-container .action-container .back .text,
        .impact-stories-app .full-image-container .action-container .back .text {
          margin-top: 3px;
          margin-left: 3px; }
      .impact-stories .full-image-container .action-container .continue,
      .impact-stories-stage .full-image-container .action-container .continue,
      .impact-stories-app .full-image-container .action-container .continue {
        flex: 1;
        max-width: 256px;
        margin-top: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0076d5;
        min-height: 40px;
        margin-left: auto;
        margin-right: auto;
        font-size: 1em;
        line-height: 1.33;
        font-family: CiscoSansTT;
        color: #fff;
        border-radius: 24px;
        cursor: pointer; }
        .impact-stories .full-image-container .action-container .continue .text,
        .impact-stories-stage .full-image-container .action-container .continue .text,
        .impact-stories-app .full-image-container .action-container .continue .text {
          padding-top: 2px; }
  .impact-stories .image-post-preview-container,
  .impact-stories-stage .image-post-preview-container,
  .impact-stories-app .image-post-preview-container {
    z-index: 2;
    color: #0d274d;
    display: flex;
    flex-direction: column;
    max-width: 380px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
    background-color: #fff;
    padding: 16px 0 19px; }
    .impact-stories .image-post-preview-container .heading,
    .impact-stories-stage .image-post-preview-container .heading,
    .impact-stories-app .image-post-preview-container .heading {
      text-align: center;
      font-size: 1em;
      font-family: CiscoSansTTBold;
      line-height: 1.5; }
    .impact-stories .image-post-preview-container .image-preview,
    .impact-stories-stage .image-post-preview-container .image-preview,
    .impact-stories-app .image-post-preview-container .image-preview {
      position: relative;
      width: 100%;
      max-width: 380px;
      height: 100%;
      max-height: 600px;
      text-align: center; }
      .impact-stories .image-post-preview-container .image-preview img,
      .impact-stories-stage .image-post-preview-container .image-preview img,
      .impact-stories-app .image-post-preview-container .image-preview img {
        margin: auto;
        width: 90%;
        height: 100%;
        border: solid 4px #f4f4f4;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
        border-radius: 10px; }
    .impact-stories .image-post-preview-container .story,
    .impact-stories-stage .image-post-preview-container .story,
    .impact-stories-app .image-post-preview-container .story {
      margin: 40px auto 0 auto;
      max-width: 330px;
      min-height: 40px;
      max-height: 160px;
      font-size: 0.75em;
      font-family: CiscoSans;
      line-height: 1.33;
      word-break: break-word;
      word-wrap: break-word;
      color: #000;
      width: 90%; }
    .impact-stories .image-post-preview-container .hashtags,
    .impact-stories-stage .image-post-preview-container .hashtags,
    .impact-stories-app .image-post-preview-container .hashtags {
      display: flex;
      flex-wrap: wrap;
      width: 90%;
      margin: 25px auto 0 auto; }
      .impact-stories .image-post-preview-container .hashtags .hashtag-wrapper,
      .impact-stories-stage .image-post-preview-container .hashtags .hashtag-wrapper,
      .impact-stories-app .image-post-preview-container .hashtags .hashtag-wrapper {
        margin: 0.25em;
        font-size: 0.75em;
        font-family: CiscoSansTTBold;
        line-height: 1.33; }
        .impact-stories .image-post-preview-container .hashtags .hashtag-wrapper .hashtag,
        .impact-stories-stage .image-post-preview-container .hashtags .hashtag-wrapper .hashtag,
        .impact-stories-app .image-post-preview-container .hashtags .hashtag-wrapper .hashtag {
          font-weight: bold;
          color: #0076d5; }
    .impact-stories .image-post-preview-container .user-info,
    .impact-stories-stage .image-post-preview-container .user-info,
    .impact-stories-app .image-post-preview-container .user-info {
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 20px auto 0 auto; }
      .impact-stories .image-post-preview-container .user-info .user-image,
      .impact-stories-stage .image-post-preview-container .user-info .user-image,
      .impact-stories-app .image-post-preview-container .user-info .user-image {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: solid 1px #1485ee;
        display: flex;
        align-items: center;
        justify-content: center; }
        .impact-stories .image-post-preview-container .user-info .user-image img,
        .impact-stories-stage .image-post-preview-container .user-info .user-image img,
        .impact-stories-app .image-post-preview-container .user-info .user-image img {
          width: 40px;
          height: 40px;
          border-radius: 50%; }
      .impact-stories .image-post-preview-container .user-info .username,
      .impact-stories-stage .image-post-preview-container .user-info .username,
      .impact-stories-app .image-post-preview-container .user-info .username {
        margin-left: 14px;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 2;
        font-family: CiscoSansTT;
        color: #0076D5; }
        .impact-stories .image-post-preview-container .user-info .username span,
        .impact-stories-stage .image-post-preview-container .user-info .username span,
        .impact-stories-app .image-post-preview-container .user-info .username span {
          padding: 0.15em; }
        .impact-stories .image-post-preview-container .user-info .username .firstname,
        .impact-stories-stage .image-post-preview-container .user-info .username .firstname,
        .impact-stories-app .image-post-preview-container .user-info .username .firstname {
          text-transform: capitalize; }
        .impact-stories .image-post-preview-container .user-info .username .lastname,
        .impact-stories-stage .image-post-preview-container .user-info .username .lastname,
        .impact-stories-app .image-post-preview-container .user-info .username .lastname {
          text-transform: capitalize;
          overflow: hidden;
          width: 120px;
          text-overflow: ellipsis; }
  .impact-stories .image-download-container,
  .impact-stories-stage .image-download-container,
  .impact-stories-app .image-download-container {
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 914px;
    width: 90%; }
    .impact-stories .image-download-container .image-download-preview,
    .impact-stories-stage .image-download-container .image-download-preview,
    .impact-stories-app .image-download-container .image-download-preview {
      width: 100%;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 35px;
      background-color: #fff;
      box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
      padding: 40px 16px; }
      .impact-stories .image-download-container .image-download-preview .download-video-hashtag,
      .impact-stories-stage .image-download-container .image-download-preview .download-video-hashtag,
      .impact-stories-app .image-download-container .image-download-preview .download-video-hashtag {
        font-size: 32px;
        font-family: CiscoSansTTLight;
        color: #1a3d69;
        margin-bottom: 8px; }
      .impact-stories .image-download-container .image-download-preview .thank-you,
      .impact-stories-stage .image-download-container .image-download-preview .thank-you,
      .impact-stories-app .image-download-container .image-download-preview .thank-you {
        font-size: 16px;
        color: #1a3d69;
        margin-bottom: 9px;
        font-family: CiscoSans;
        height: 40px;
        display: flex;
        align-items: center; }
      .impact-stories .image-download-container .image-download-preview a,
      .impact-stories-stage .image-download-container .image-download-preview a,
      .impact-stories-app .image-download-container .image-download-preview a {
        color: white;
        text-decoration: none; }
        .impact-stories .image-download-container .image-download-preview a:active,
        .impact-stories-stage .image-download-container .image-download-preview a:active,
        .impact-stories-app .image-download-container .image-download-preview a:active {
          color: white; }
        .impact-stories .image-download-container .image-download-preview a:visited,
        .impact-stories-stage .image-download-container .image-download-preview a:visited,
        .impact-stories-app .image-download-container .image-download-preview a:visited {
          color: white; }
      .impact-stories .image-download-container .image-download-preview img,
      .impact-stories-stage .image-download-container .image-download-preview img,
      .impact-stories-app .image-download-container .image-download-preview img {
        max-width: 370px;
        border: 4px solid #f4f4f4;
        border-radius: 18px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
        box-sizing: border-box;
        margin-bottom: 25px; }
      .impact-stories .image-download-container .image-download-preview .download,
      .impact-stories-stage .image-download-container .image-download-preview .download,
      .impact-stories-app .image-download-container .image-download-preview .download {
        width: 174px;
        display: flex;
        min-height: 40px;
        font-size: 1em;
        font-family: CiscoSansTT;
        color: #fff;
        background-color: #0076d5;
        border-radius: 50px;
        cursor: pointer; }
        .impact-stories .image-download-container .image-download-preview .download a,
        .impact-stories-stage .image-download-container .image-download-preview .download a,
        .impact-stories-app .image-download-container .image-download-preview .download a {
          color: white;
          text-decoration: none; }
          .impact-stories .image-download-container .image-download-preview .download a:active,
          .impact-stories-stage .image-download-container .image-download-preview .download a:active,
          .impact-stories-app .image-download-container .image-download-preview .download a:active {
            color: white; }
          .impact-stories .image-download-container .image-download-preview .download a:visited,
          .impact-stories-stage .image-download-container .image-download-preview .download a:visited,
          .impact-stories-app .image-download-container .image-download-preview .download a:visited {
            color: white; }
        .impact-stories .image-download-container .image-download-preview .download .icon-fk,
        .impact-stories-stage .image-download-container .image-download-preview .download .icon-fk,
        .impact-stories-app .image-download-container .image-download-preview .download .icon-fk {
          display: none; }
        .impact-stories .image-download-container .image-download-preview .download .dwnld-img,
        .impact-stories-stage .image-download-container .image-download-preview .download .dwnld-img,
        .impact-stories-app .image-download-container .image-download-preview .download .dwnld-img {
          display: flex;
          align-items: center;
          padding: 0 20px;
          justify-content: space-between;
          width: 100%; }
        .impact-stories .image-download-container .image-download-preview .download .download-icon-bg .image.icdownload,
        .impact-stories-stage .image-download-container .image-download-preview .download .download-icon-bg .image.icdownload,
        .impact-stories-app .image-download-container .image-download-preview .download .download-icon-bg .image.icdownload {
          background-image: url(./../assets/impact21/images/download-icon.png);
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: contain; }
        .impact-stories .image-download-container .image-download-preview .download p,
        .impact-stories-stage .image-download-container .image-download-preview .download p,
        .impact-stories-app .image-download-container .image-download-preview .download p {
          font-size: 14px; }
    .impact-stories .image-download-container .action,
    .impact-stories-stage .image-download-container .action,
    .impact-stories-app .image-download-container .action {
      display: flex;
      flex-wrap: wrap;
      margin: 0%;
      justify-content: center;
      background-color: transparent;
      background-image: none;
      gap: 20px;
      max-width: 420px; }
      .impact-stories .image-download-container .action .close,
      .impact-stories-stage .image-download-container .action .close,
      .impact-stories-app .image-download-container .action .close {
        background-color: #f5f5f5;
        color: #0076d5;
        width: 190px;
        min-width: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #0076d5;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        line-height: 1.33;
        font-family: CiscoSansTT;
        border-radius: 50px;
        cursor: pointer;
        padding-top: 3px; }
      .impact-stories .image-download-container .action .redo,
      .impact-stories-stage .image-download-container .action .redo,
      .impact-stories-app .image-download-container .action .redo {
        width: 204px;
        min-width: 204px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0076d5;
        background-color: #0076d5;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        color: #fff;
        line-height: 1.33;
        font-family: CiscoSansTT;
        border-radius: 50px;
        cursor: pointer;
        padding-top: 2px; }
  .impact-stories .record-video-container,
  .impact-stories-stage .record-video-container,
  .impact-stories-app .record-video-container {
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 459px;
    margin-left: auto;
    margin-right: auto;
    padding: 37px 0 10px;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
    background-color: #fff;
    margin-top: 78px;
    z-index: 1;
    position: relative; }
    .impact-stories .record-video-container .heading,
    .impact-stories-stage .record-video-container .heading,
    .impact-stories-app .record-video-container .heading {
      text-align: center;
      font-size: 32px;
      font-family: CiscoSansTTLight;
      line-height: 1;
      z-index: 10; }
    .impact-stories .record-video-container .video-live-preview,
    .impact-stories-stage .record-video-container .video-live-preview,
    .impact-stories-app .record-video-container .video-live-preview {
      z-index: 10;
      margin-top: 17px;
      position: relative;
      z-index: 10; }
      .impact-stories .record-video-container .video-live-preview .camera-error,
      .impact-stories-stage .record-video-container .video-live-preview .camera-error,
      .impact-stories-app .record-video-container .video-live-preview .camera-error {
        font-family: CiscoSansTT;
        text-align: center;
        color: #c10707; }
      .impact-stories .record-video-container .video-live-preview .capture-overlay,
      .impact-stories-stage .record-video-container .video-live-preview .capture-overlay,
      .impact-stories-app .record-video-container .video-live-preview .capture-overlay {
        position: absolute;
        font-size: 8em;
        color: #0076d5;
        font-family: CiscoSansTTBold;
        top: 30%;
        left: 40%;
        z-index: 10; }
        .impact-stories .record-video-container .video-live-preview .capture-overlay.hide,
        .impact-stories-stage .record-video-container .video-live-preview .capture-overlay.hide,
        .impact-stories-app .record-video-container .video-live-preview .capture-overlay.hide {
          display: none; }
        .impact-stories .record-video-container .video-live-preview .capture-overlay.error,
        .impact-stories-stage .record-video-container .video-live-preview .capture-overlay.error,
        .impact-stories-app .record-video-container .video-live-preview .capture-overlay.error {
          font-size: 1em;
          left: 10%;
          text-align: center; }
      .impact-stories .record-video-container .video-live-preview .video-recorder-wrapper figure,
      .impact-stories-stage .record-video-container .video-live-preview .video-recorder-wrapper figure,
      .impact-stories-app .record-video-container .video-live-preview .video-recorder-wrapper figure {
        margin-top: 8px; }
      .impact-stories .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .timer-overlay,
      .impact-stories-stage .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .timer-overlay,
      .impact-stories-app .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .timer-overlay {
        font-family: "CiscoSansTTLight";
        color: #fff;
        font-size: 1em;
        left: 75%;
        top: 6%;
        border-radius: 5px;
        padding-top: 5px;
        padding-left: 5px;
        padding-right: 5px; }
      .impact-stories .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .stop-button-container,
      .impact-stories-stage .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .stop-button-container,
      .impact-stories-app .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .stop-button-container {
        display: flex;
        align-items: center;
        justify-content: center; }
        .impact-stories .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .stop-button-container .stop-button,
        .impact-stories-stage .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .stop-button-container .stop-button,
        .impact-stories-app .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .stop-button-container .stop-button {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0);
          box-sizing: none;
          border: none; }
      .impact-stories .record-video-container .video-live-preview .video-recorder-wrapper .button-container,
      .impact-stories-stage .record-video-container .video-live-preview .video-recorder-wrapper .button-container,
      .impact-stories-app .record-video-container .video-live-preview .video-recorder-wrapper .button-container {
        display: flex;
        align-items: center;
        justify-content: center; }
        .impact-stories .record-video-container .video-live-preview .video-recorder-wrapper .button-container .record-button,
        .impact-stories-stage .record-video-container .video-live-preview .video-recorder-wrapper .button-container .record-button,
        .impact-stories-app .record-video-container .video-live-preview .video-recorder-wrapper .button-container .record-button {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0);
          box-sizing: none;
          border: none; }
        .impact-stories .record-video-container .video-live-preview .video-recorder-wrapper .button-container .info,
        .impact-stories-stage .record-video-container .video-live-preview .video-recorder-wrapper .button-container .info,
        .impact-stories-app .record-video-container .video-live-preview .video-recorder-wrapper .button-container .info {
          margin-top: -5px;
          font-size: 1em;
          font-family: CiscoSans;
          text-align: center;
          color: #0d274d; }
      .impact-stories .record-video-container .video-live-preview video,
      .impact-stories-stage .record-video-container .video-live-preview video,
      .impact-stories-app .record-video-container .video-live-preview video {
        border: 4px solid #f4f4f4;
        border-radius: 10px;
        height: 100%;
        max-height: 490px;
        width: 100%;
        max-width: 370px; }
      .impact-stories .record-video-container .video-live-preview .overlay.error,
      .impact-stories-stage .record-video-container .video-live-preview .overlay.error,
      .impact-stories-app .record-video-container .video-live-preview .overlay.error {
        margin-top: 20px;
        position: relative;
        width: 100%;
        max-width: 330px;
        height: 100%;
        max-height: 600px;
        border-radius: 8px;
        text-align: center; }
      .impact-stories .record-video-container .video-live-preview .timer-overlay,
      .impact-stories-stage .record-video-container .video-live-preview .timer-overlay,
      .impact-stories-app .record-video-container .video-live-preview .timer-overlay {
        position: absolute;
        font-size: 1.25em;
        padding: 0.25em 0.50em;
        color: white;
        top: 4% !important;
        left: 75%;
        background-color: #0076d5;
        border-radius: 10px; }
        .impact-stories .record-video-container .video-live-preview .timer-overlay .react-stopwatch-timer__table,
        .impact-stories-stage .record-video-container .video-live-preview .timer-overlay .react-stopwatch-timer__table,
        .impact-stories-app .record-video-container .video-live-preview .timer-overlay .react-stopwatch-timer__table {
          width: 40px;
          height: 20px;
          font-size: 0.75em; }
        .impact-stories .record-video-container .video-live-preview .timer-overlay.hide,
        .impact-stories-stage .record-video-container .video-live-preview .timer-overlay.hide,
        .impact-stories-app .record-video-container .video-live-preview .timer-overlay.hide {
          display: none; }
      .impact-stories .record-video-container .video-live-preview .video-actions,
      .impact-stories-stage .record-video-container .video-live-preview .video-actions,
      .impact-stories-app .record-video-container .video-live-preview .video-actions {
        display: flex;
        justify-content: center;
        align-items: center; }
    .impact-stories .record-video-container .capture-action,
    .impact-stories-stage .record-video-container .capture-action,
    .impact-stories-app .record-video-container .capture-action {
      margin-top: 1.5em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 40px;
      margin-left: auto;
      margin-right: auto;
      font-size: 0.75em;
      line-height: 1.33;
      font-family: CiscoSansTT; }
      .impact-stories .record-video-container .capture-action.record .icon-fk,
      .impact-stories-stage .record-video-container .capture-action.record .icon-fk,
      .impact-stories-app .record-video-container .capture-action.record .icon-fk {
        font-size: 1.5em;
        padding: 0.75em;
        color: #1a3d69;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        display: none; }
      .impact-stories .record-video-container .capture-action.record .image,
      .impact-stories-stage .record-video-container .capture-action.record .image,
      .impact-stories-app .record-video-container .capture-action.record .image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 63px;
        height: 63px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.26);
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        margin-bottom: 6px; }
        .impact-stories .record-video-container .capture-action.record .image .video-icon-fk,
        .impact-stories-stage .record-video-container .capture-action.record .image .video-icon-fk,
        .impact-stories-app .record-video-container .capture-action.record .image .video-icon-fk {
          background-image: url(./../assets/impact21/images/video-img.svg);
          width: 31.3px;
          height: 20.6px;
          background-repeat: no-repeat;
          background-size: contain;
          cursor: pointer; }
      .impact-stories .record-video-container .capture-action.record p,
      .impact-stories-stage .record-video-container .capture-action.record p,
      .impact-stories-app .record-video-container .capture-action.record p {
        color: #1a3d69;
        line-height: 1.17;
        font-size: 1.6em;
        width: 100%; }
      .impact-stories .record-video-container .capture-action.stop .icon-fk,
      .impact-stories-stage .record-video-container .capture-action.stop .icon-fk,
      .impact-stories-app .record-video-container .capture-action.stop .icon-fk {
        font-size: 2.25em;
        padding: 0.25em;
        background-color: #1a3d69;
        background-image: linear-gradient(to bottom, #bcbcbc, #ffffff);
        box-shadow: 3px 3px 6px 2px rgba(0, 0, 0, 0.33);
        color: #c10707;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        display: none; }
      .impact-stories .record-video-container .capture-action.stop .image,
      .impact-stories-stage .record-video-container .capture-action.stop .image,
      .impact-stories-app .record-video-container .capture-action.stop .image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 61px;
        height: 61px;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.26);
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        margin-bottom: 6px; }
        .impact-stories .record-video-container .capture-action.stop .image .stop-icon-fk,
        .impact-stories-stage .record-video-container .capture-action.stop .image .stop-icon-fk,
        .impact-stories-app .record-video-container .capture-action.stop .image .stop-icon-fk {
          background-image: url(./../assets/impact21/images/stop-icon.png);
          width: 40px;
          height: 40px;
          background-repeat: no-repeat;
          background-size: cover;
          cursor: pointer; }
      .impact-stories .record-video-container .capture-action.stop p,
      .impact-stories-stage .record-video-container .capture-action.stop p,
      .impact-stories-app .record-video-container .capture-action.stop p {
        color: #1a3d69;
        line-height: 1.17;
        font-size: 1.6em;
        width: 150px; }
      .impact-stories .record-video-container .capture-action.continue,
      .impact-stories-stage .record-video-container .capture-action.continue,
      .impact-stories-app .record-video-container .capture-action.continue {
        text-align: center; }
  .impact-stories .video-preview-container,
  .impact-stories-stage .video-preview-container,
  .impact-stories-app .video-preview-container {
    z-index: 2;
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 459px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 39px 0 40px;
    background-color: #fff;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
    margin-top: 78px;
    position: relative; }
    .impact-stories .video-preview-container .heading,
    .impact-stories-stage .video-preview-container .heading,
    .impact-stories-app .video-preview-container .heading {
      text-align: center;
      font-size: 32px;
      font-family: CiscoSansTTLight;
      line-height: 1; }
    .impact-stories .video-preview-container .video-preview,
    .impact-stories-stage .video-preview-container .video-preview,
    .impact-stories-app .video-preview-container .video-preview {
      margin-top: 25px;
      position: relative;
      height: 100%;
      width: 90%;
      max-width: 370px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center; }
      .impact-stories .video-preview-container .video-preview .player-container:hover .pause-overlay-container,
      .impact-stories-stage .video-preview-container .video-preview .player-container:hover .pause-overlay-container,
      .impact-stories-app .video-preview-container .video-preview .player-container:hover .pause-overlay-container {
        display: none; }
      .impact-stories .video-preview-container .video-preview .player-container .play-overlay-container,
      .impact-stories-stage .video-preview-container .video-preview .player-container .play-overlay-container,
      .impact-stories-app .video-preview-container .video-preview .player-container .play-overlay-container {
        position: absolute;
        top: 40%;
        left: 40%;
        background-color: #ffffff;
        border-radius: 100%; }
        .impact-stories .video-preview-container .video-preview .player-container .play-overlay-container .play-overlay,
        .impact-stories-stage .video-preview-container .video-preview .player-container .play-overlay-container .play-overlay,
        .impact-stories-app .video-preview-container .video-preview .player-container .play-overlay-container .play-overlay {
          background-image: url(./../assets/impact21/images/play-circle.svg);
          width: 60px;
          height: 60px;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer; }
      .impact-stories .video-preview-container .video-preview .player-container .pause-overlay-container,
      .impact-stories-stage .video-preview-container .video-preview .player-container .pause-overlay-container,
      .impact-stories-app .video-preview-container .video-preview .player-container .pause-overlay-container {
        position: absolute;
        top: 45%;
        left: 45%;
        background-color: #ffffff;
        border-radius: 100%;
        display: none; }
        .impact-stories .video-preview-container .video-preview .player-container .pause-overlay-container .pause-overlay,
        .impact-stories-stage .video-preview-container .video-preview .player-container .pause-overlay-container .pause-overlay,
        .impact-stories-app .video-preview-container .video-preview .player-container .pause-overlay-container .pause-overlay {
          background-image: url(./../assets/impact21/images/control-pause-btn.png);
          width: 60px;
          height: 60px;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer; }
      .impact-stories .video-preview-container .video-preview .player-container video,
      .impact-stories-stage .video-preview-container .video-preview .player-container video,
      .impact-stories-app .video-preview-container .video-preview .player-container video {
        object-fit: cover;
        border: 4px solid #f5f5f5;
        box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
        border-radius: 10px;
        cursor: pointer; }
      .impact-stories .video-preview-container .video-preview .player-container .video-controls,
      .impact-stories-stage .video-preview-container .video-preview .player-container .video-controls,
      .impact-stories-app .video-preview-container .video-preview .player-container .video-controls {
        margin-top: 0.75em;
        margin-left: 1%; }
        .impact-stories .video-preview-container .video-preview .player-container .video-controls .video-actions,
        .impact-stories-stage .video-preview-container .video-preview .player-container .video-controls .video-actions,
        .impact-stories-app .video-preview-container .video-preview .player-container .video-controls .video-actions {
          display: flex;
          width: 100%;
          height: 10px; }
          .impact-stories .video-preview-container .video-preview .player-container .video-controls .video-actions .play,
          .impact-stories-stage .video-preview-container .video-preview .player-container .video-controls .video-actions .play,
          .impact-stories-app .video-preview-container .video-preview .player-container .video-controls .video-actions .play {
            margin-left: 5px;
            background-image: url(./../assets/impact21/images/control-play-btn.png);
            width: 10px;
            height: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer; }
          .impact-stories .video-preview-container .video-preview .player-container .video-controls .video-actions .pause,
          .impact-stories-stage .video-preview-container .video-preview .player-container .video-controls .video-actions .pause,
          .impact-stories-app .video-preview-container .video-preview .player-container .video-controls .video-actions .pause {
            margin-left: 5px;
            background-image: url(./../assets/impact21/images/control-pause-btn.png);
            width: 10px;
            height: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer; }
          .impact-stories .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container,
          .impact-stories-stage .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container,
          .impact-stories-app .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container {
            background-color: #f5f5f5;
            border-radius: 2px;
            height: 3px;
            width: 100%;
            margin-left: 0.5em;
            margin-top: 3px;
            box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11); }
            .impact-stories .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek,
            .impact-stories-stage .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek,
            .impact-stories-app .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek {
              width: 1%;
              max-width: 100%;
              height: 3px;
              background-color: #0d274d;
              border-radius: 2px; }
        .impact-stories .video-preview-container .video-preview .player-container .video-controls .video-info,
        .impact-stories-stage .video-preview-container .video-preview .player-container .video-controls .video-info,
        .impact-stories-app .video-preview-container .video-preview .player-container .video-controls .video-info {
          font-family: "CiscoSansTTLight";
          margin-top: 1em;
          padding-left: 20px;
          font-size: 7px;
          display: flex;
          justify-content: space-between;
          align-items: center; }
    .impact-stories .video-preview-container .action,
    .impact-stories-stage .video-preview-container .action,
    .impact-stories-app .video-preview-container .action {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 88%;
      max-width: 378px;
      margin-top: 15px; }
      .impact-stories .video-preview-container .action .redo,
      .impact-stories-stage .video-preview-container .action .redo,
      .impact-stories-app .video-preview-container .action .redo {
        margin-right: 20px;
        margin-top: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        width: 128px;
        height: 36px;
        font-size: 16px;
        font-family: CiscoSansTT;
        border-radius: 24px;
        cursor: pointer;
        border: solid 1px #0076d5;
        color: #0076d5; }
        .impact-stories .video-preview-container .action .redo .icon-fk,
        .impact-stories-stage .video-preview-container .action .redo .icon-fk,
        .impact-stories-app .video-preview-container .action .redo .icon-fk {
          font-size: 1.25em;
          padding-top: 0.25em;
          margin-right: 0.5em;
          display: none; }
        .impact-stories .video-preview-container .action .redo span,
        .impact-stories-stage .video-preview-container .action .redo span,
        .impact-stories-app .video-preview-container .action .redo span {
          height: 14px; }
      .impact-stories .video-preview-container .action .continue,
      .impact-stories-stage .video-preview-container .action .continue,
      .impact-stories-app .video-preview-container .action .continue {
        margin-top: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0076d5;
        width: 160px;
        min-height: 40px;
        font-size: 16px;
        font-family: CiscoSansTT;
        color: #fff;
        border-radius: 24px;
        cursor: pointer; }
        .impact-stories .video-preview-container .action .continue span,
        .impact-stories-stage .video-preview-container .action .continue span,
        .impact-stories-app .video-preview-container .action .continue span {
          height: 14px; }
    .impact-stories .video-preview-container .capture-action,
    .impact-stories-stage .video-preview-container .capture-action,
    .impact-stories-app .video-preview-container .capture-action {
      cursor: pointer;
      text-align: center;
      background-color: #1a3d69;
      color: #ffffff;
      border-radius: 10px;
      padding: 1em;
      margin: 1em; }
  .impact-stories .full-video-container,
  .impact-stories-stage .full-video-container,
  .impact-stories-app .full-video-container {
    z-index: 11;
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 29px 37px 29px;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
    background-color: #fff;
    margin-top: 119px;
    width: 90%;
    max-width: 913px;
    position: relative; }
    .impact-stories .full-video-container .heading,
    .impact-stories-stage .full-video-container .heading,
    .impact-stories-app .full-video-container .heading {
      text-align: center;
      font-size: 32px;
      font-family: CiscoSansTTLight;
      line-height: 1; }
    .impact-stories .full-video-container .add-post-container,
    .impact-stories-stage .full-video-container .add-post-container,
    .impact-stories-app .full-video-container .add-post-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 30px;
      width: 100%; }
      .impact-stories .full-video-container .add-post-container .video-preview-and-retake,
      .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake,
      .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake {
        position: relative;
        width: 100%;
        max-width: 370px;
        height: 100%;
        margin-right: 27px; }
        .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container:hover .pause-overlay-container,
        .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container:hover .pause-overlay-container,
        .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container:hover .pause-overlay-container {
          display: none; }
        .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .play-overlay-container,
        .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .play-overlay-container,
        .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .play-overlay-container {
          position: absolute;
          top: 36%;
          left: 40%;
          background-color: #ffffff;
          border-radius: 100%; }
          .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .play-overlay-container .play-overlay,
          .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .play-overlay-container .play-overlay,
          .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .play-overlay-container .play-overlay {
            background-image: url(./../assets/impact21/images/play-circle.svg);
            width: 60px;
            height: 60px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer; }
        .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .pause-overlay-container,
        .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .pause-overlay-container,
        .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .pause-overlay-container {
          position: absolute;
          top: 40%;
          left: 40%;
          background-color: #ffffff;
          border-radius: 100%;
          display: none; }
          .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .pause-overlay-container .pause-overlay,
          .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .pause-overlay-container .pause-overlay,
          .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .pause-overlay-container .pause-overlay {
            background-image: url(./../assets/impact21/images/control-pause-btn.png);
            width: 60px;
            height: 60px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer; }
        .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container video,
        .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container video,
        .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container video {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border: 4px solid #f4f4f4;
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          cursor: pointer; }
        .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls,
        .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls,
        .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls {
          margin-top: 0.75em;
          margin-left: 2%; }
          .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions,
          .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions,
          .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions {
            display: flex;
            width: 100%;
            height: 10px; }
            .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .play,
            .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .play,
            .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .play {
              margin-left: 5px;
              background-image: url(./../assets/impact21/images/control-play-btn.png);
              width: 10px;
              height: 10px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              cursor: pointer; }
            .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .pause,
            .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .pause,
            .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .pause {
              margin-left: 5px;
              background-image: url(./../assets/impact21/images/control-pause-btn.png);
              width: 10px;
              height: 10px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              cursor: pointer; }
            .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container,
            .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container,
            .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container {
              background-color: #f5f5f5;
              border-radius: 2px;
              height: 3px;
              width: 100%;
              margin-left: 0.5em;
              margin-top: 3px;
              box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11); }
              .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container .seek,
              .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container .seek,
              .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container .seek {
                width: 1%;
                max-width: 100%;
                height: 3px;
                background-color: #0d274d;
                border-radius: 2px; }
          .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-info,
          .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-info,
          .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-info {
            font-family: "CiscoSansTT";
            margin-top: 1em;
            padding-left: 20px;
            font-size: 7px;
            display: flex;
            justify-content: space-between;
            align-items: center; }
        .impact-stories .full-video-container .add-post-container .video-preview-and-retake .action-retake,
        .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .action-retake,
        .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .action-retake {
          margin-right: 20px;
          margin-top: .5em;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90%;
          max-width: 265px;
          min-height: 38px;
          margin-left: auto;
          margin-right: auto;
          font-size: 1em;
          line-height: 1.33;
          font-family: CiscoSansTT;
          color: #0076d5;
          cursor: pointer; }
          .impact-stories .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo,
          .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo,
          .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo {
            display: flex;
            width: 50%;
            justify-content: space-around;
            align-items: center; }
            .impact-stories .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo .icon-fk,
            .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo .icon-fk,
            .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo .icon-fk {
              font-size: 1.25em;
              padding-top: 0.25em;
              display: none; }
            .impact-stories .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo .text,
            .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo .text,
            .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo .text {
              border-bottom: 2px solid; }
      .impact-stories .full-video-container .add-post-container .add-story-container,
      .impact-stories-stage .full-video-container .add-post-container .add-story-container,
      .impact-stories-app .full-video-container .add-post-container .add-story-container {
        flex: 1; }
        .impact-stories .full-video-container .add-post-container .add-story-container .user-info,
        .impact-stories-stage .full-video-container .add-post-container .add-story-container .user-info,
        .impact-stories-app .full-video-container .add-post-container .add-story-container .user-info {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center; }
          .impact-stories .full-video-container .add-post-container .add-story-container .user-info .user-image,
          .impact-stories-stage .full-video-container .add-post-container .add-story-container .user-info .user-image,
          .impact-stories-app .full-video-container .add-post-container .add-story-container .user-info .user-image {
            width: 41px;
            height: 41px;
            border-radius: 50%;
            border: 1px solid #0d274d;
            display: flex;
            align-items: center;
            justify-content: center; }
          .impact-stories .full-video-container .add-post-container .add-story-container .user-info img,
          .impact-stories-stage .full-video-container .add-post-container .add-story-container .user-info img,
          .impact-stories-app .full-video-container .add-post-container .add-story-container .user-info img {
            width: 36px;
            height: 36px;
            border-radius: 50%; }
          .impact-stories .full-video-container .add-post-container .add-story-container .user-info .username,
          .impact-stories-stage .full-video-container .add-post-container .add-story-container .user-info .username,
          .impact-stories-app .full-video-container .add-post-container .add-story-container .user-info .username {
            margin-left: 14px;
            display: flex;
            justify-content: flex-start;
            font-size: 14px;
            line-height: 1.5;
            font-family: CiscoSansTT; }
            .impact-stories .full-video-container .add-post-container .add-story-container .user-info .username span,
            .impact-stories-stage .full-video-container .add-post-container .add-story-container .user-info .username span,
            .impact-stories-app .full-video-container .add-post-container .add-story-container .user-info .username span {
              padding: 0.15em; }
            .impact-stories .full-video-container .add-post-container .add-story-container .user-info .username .firstname,
            .impact-stories-stage .full-video-container .add-post-container .add-story-container .user-info .username .firstname,
            .impact-stories-app .full-video-container .add-post-container .add-story-container .user-info .username .firstname {
              text-transform: capitalize; }
            .impact-stories .full-video-container .add-post-container .add-story-container .user-info .username .lastname,
            .impact-stories-stage .full-video-container .add-post-container .add-story-container .user-info .username .lastname,
            .impact-stories-app .full-video-container .add-post-container .add-story-container .user-info .username .lastname {
              text-transform: capitalize; }
        .impact-stories .full-video-container .add-post-container .add-story-container .story-container,
        .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container,
        .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container {
          margin-top: 20px; }
          .impact-stories .full-video-container .add-post-container .add-story-container .story-container .heading,
          .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container .heading,
          .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container .heading {
            font-size: 24px;
            font-family: CiscoSansTTLight;
            line-height: 1.33;
            text-align: left; }
          .impact-stories .full-video-container .add-post-container .add-story-container .story-container .sub-heading,
          .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container .sub-heading,
          .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container .sub-heading {
            font-size: 13px;
            font-family: CiscoSansTT;
            line-height: 1.33;
            text-align: left;
            margin-bottom: 8px;
            margin-top: 5px; }
          .impact-stories .full-video-container .add-post-container .add-story-container .story-container .text-area,
          .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container .text-area,
          .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container .text-area {
            display: flex;
            flex-direction: column;
            cursor: text; }
            .impact-stories .full-video-container .add-post-container .add-story-container .story-container .text-area.error textarea,
            .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container .text-area.error textarea,
            .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container .text-area.error textarea {
              border: 1px solid #c10707; }
            .impact-stories .full-video-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta,
            .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta,
            .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta {
              font-family: CiscoSansTT;
              justify-content: space-between; }
              .impact-stories .full-video-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta .error-msg,
              .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta .error-msg,
              .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta .error-msg {
                display: inline;
                color: #c10707;
                font-family: CiscoSansTTBold;
                margin-top: 5px; }
            .impact-stories .full-video-container .add-post-container .add-story-container .story-container .text-area textarea,
            .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container .text-area textarea,
            .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container .text-area textarea {
              width: 100%;
              min-width: 250px;
              height: 212px;
              font-size: 13px;
              border-radius: 5px;
              box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
              background-color: #f9f9f9;
              border: none;
              padding: 1em;
              resize: none;
              font-family: CiscoSansTT;
              cursor: text; }
              .impact-stories .full-video-container .add-post-container .add-story-container .story-container .text-area textarea:focus-visible,
              .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container .text-area textarea:focus-visible,
              .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container .text-area textarea:focus-visible {
                outline: none; }
              .impact-stories .full-video-container .add-post-container .add-story-container .story-container .text-area textarea:active,
              .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container .text-area textarea:active,
              .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container .text-area textarea:active {
                outline: none; }
            .impact-stories .full-video-container .add-post-container .add-story-container .story-container .text-area .text-area-meta,
            .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container .text-area .text-area-meta,
            .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container .text-area .text-area-meta {
              display: flex;
              justify-content: flex-end;
              font-size: 0.625em;
              padding: 0.1em;
              font-family: CiscoSansTT; }
              .impact-stories .full-video-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .error-msg,
              .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .error-msg,
              .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .error-msg {
                display: none; }
              .impact-stories .full-video-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .character-count,
              .impact-stories-stage .full-video-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .character-count,
              .impact-stories-app .full-video-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .character-count {
                margin-top: 5px; }
        .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container,
        .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container,
        .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container {
          margin-top: 18px; }
          .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .heading,
          .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .heading,
          .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .heading {
            font-size: 24px;
            font-family: CiscoSansTTLight;
            text-align: left;
            line-height: 1.33; }
          .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .sub-heading,
          .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .sub-heading,
          .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .sub-heading {
            text-align: left;
            font-family: CiscoSansTT;
            font-size: 13px;
            line-height: 1.33;
            margin-top: 5px; }
          .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags,
          .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags,
          .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags {
            margin-top: 10px;
            padding: 0.5em;
            border: none;
            display: flex;
            gap: 10px;
            max-width: 480px;
            flex-wrap: wrap;
            border-radius: 5px;
            box-shadow: inset 1px 1px 7px 0 rgba(0, 0, 0, 0.25);
            background-color: #f9f9f9;
            align-items: center;
            min-height: 50px; }
            .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags.hideinput input,
            .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags.hideinput input,
            .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags.hideinput input {
              display: none; }
            .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper,
            .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper,
            .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper {
              display: flex;
              align-items: center;
              border-radius: 8px;
              background-color: #e2e2e2;
              color: #525354;
              height: 31px; }
              .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .hashtag,
              .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .hashtag,
              .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .hashtag {
                font-family: CiscoSansTT;
                font-size: 13px;
                padding: 0.05em 0.75em;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: alias; }
              .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .icon-fk,
              .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .icon-fk,
              .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .icon-fk {
                cursor: pointer;
                margin-left: -7px;
                color: #0076D5; }
            .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags input,
            .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags input,
            .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags input {
              border: none;
              font-size: 16px;
              background-color: transparent;
              max-height: 33px; }
              .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags input:active,
              .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags input:active,
              .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags input:active {
                border: none; }
              .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags input:focus-visible,
              .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags input:focus-visible,
              .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags input:focus-visible {
                border: none;
                outline: none; }
          .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion,
          .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion,
          .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion {
            display: flex;
            flex-wrap: wrap;
            max-width: 450px;
            margin-top: 10px; }
            .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper,
            .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper,
            .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper {
              display: flex;
              border-radius: 8px;
              background-color: #e2e2e2;
              color: #525354;
              margin: 0.15em;
              height: 31px;
              align-items: center; }
              .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .hashtag,
              .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .hashtag,
              .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .hashtag {
                font-family: CiscoSansTT;
                font-size: 13px;
                padding: 1px 0.75em;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer; }
              .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .icon-fk,
              .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .icon-fk,
              .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .icon-fk {
                cursor: pointer;
                display: none; }
              .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image,
              .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image,
              .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image {
                cursor: pointer; }
                .impact-stories .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image.icon-fk-add,
                .impact-stories-stage .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image.icon-fk-add,
                .impact-stories-app .full-video-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image.icon-fk-add {
                  background-image: url(./../assets/impact21/images/icon-add.svg);
                  width: 20px;
                  height: 20px;
                  background-repeat: no-repeat;
                  background-size: contain;
                  margin-right: 5px; }
    .impact-stories .full-video-container .action-container,
    .impact-stories-stage .full-video-container .action-container,
    .impact-stories-app .full-video-container .action-container {
      flex-direction: row;
      margin: 0%;
      justify-content: space-between;
      background-color: transparent;
      background-image: none;
      width: 100%;
      max-width: 380px; }
      .impact-stories .full-video-container .action-container .back,
      .impact-stories-stage .full-video-container .action-container .back,
      .impact-stories-app .full-video-container .action-container .back {
        width: 100px;
        margin-right: 20px;
        margin-top: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 36px;
        font-size: 1em;
        line-height: 1.33;
        font-family: CiscoSansTT;
        border: 1px solid #0076d5;
        background-color: #fff;
        color: #0076d5;
        border-radius: 24px;
        cursor: pointer; }
        .impact-stories .full-video-container .action-container .back .icon-fk,
        .impact-stories-stage .full-video-container .action-container .back .icon-fk,
        .impact-stories-app .full-video-container .action-container .back .icon-fk {
          display: none; }
        .impact-stories .full-video-container .action-container .back .image.back-icon-fk,
        .impact-stories-stage .full-video-container .action-container .back .image.back-icon-fk,
        .impact-stories-app .full-video-container .action-container .back .image.back-icon-fk {
          background: url(./../assets/impact21/images/back-icon.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 20px;
          height: 14px; }
        .impact-stories .full-video-container .action-container .back .text,
        .impact-stories-stage .full-video-container .action-container .back .text,
        .impact-stories-app .full-video-container .action-container .back .text {
          margin-top: 3px;
          margin-left: 3px; }
      .impact-stories .full-video-container .action-container .continue,
      .impact-stories-stage .full-video-container .action-container .continue,
      .impact-stories-app .full-video-container .action-container .continue {
        flex: 1;
        max-width: 256px;
        margin-top: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        margin-left: auto;
        margin-right: auto;
        font-size: 1em;
        line-height: 1.33;
        font-family: CiscoSansTT;
        color: #fff;
        background-color: #0076d5;
        border-radius: 24px;
        cursor: pointer; }
        .impact-stories .full-video-container .action-container .continue .text,
        .impact-stories-stage .full-video-container .action-container .continue .text,
        .impact-stories-app .full-video-container .action-container .continue .text {
          padding-top: 2px; }
  .impact-stories .video-post-preview-container,
  .impact-stories-stage .video-post-preview-container,
  .impact-stories-app .video-post-preview-container {
    z-index: 2;
    color: #0d274d;
    display: flex;
    flex-direction: column;
    max-width: 380px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 17px 19px;
    background-color: #fff;
    box-shadow: 4px 5px 10px 2px #0000001a; }
    .impact-stories .video-post-preview-container .heading,
    .impact-stories-stage .video-post-preview-container .heading,
    .impact-stories-app .video-post-preview-container .heading {
      text-align: center;
      font-size: 1em;
      font-family: CiscoSansTTBold;
      line-height: 1.5; }
    .impact-stories .video-post-preview-container .video-preview,
    .impact-stories-stage .video-post-preview-container .video-preview,
    .impact-stories-app .video-post-preview-container .video-preview {
      margin-top: 6px;
      position: relative;
      width: 100%;
      max-width: 380px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .impact-stories .video-post-preview-container .video-preview .player-container,
      .impact-stories-stage .video-post-preview-container .video-preview .player-container,
      .impact-stories-app .video-post-preview-container .video-preview .player-container {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .impact-stories .video-post-preview-container .video-preview .player-container:hover .pause-overlay-container,
        .impact-stories-stage .video-post-preview-container .video-preview .player-container:hover .pause-overlay-container,
        .impact-stories-app .video-post-preview-container .video-preview .player-container:hover .pause-overlay-container {
          display: none; }
        .impact-stories .video-post-preview-container .video-preview .player-container .play-overlay-container,
        .impact-stories-stage .video-post-preview-container .video-preview .player-container .play-overlay-container,
        .impact-stories-app .video-post-preview-container .video-preview .player-container .play-overlay-container {
          position: absolute;
          top: 38%;
          left: 40%;
          background-color: #ffffff;
          border-radius: 100%; }
          .impact-stories .video-post-preview-container .video-preview .player-container .play-overlay-container .play-overlay,
          .impact-stories-stage .video-post-preview-container .video-preview .player-container .play-overlay-container .play-overlay,
          .impact-stories-app .video-post-preview-container .video-preview .player-container .play-overlay-container .play-overlay {
            background-image: url(./../assets/impact21/images/play-circle.svg);
            width: 60px;
            height: 60px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer; }
        .impact-stories .video-post-preview-container .video-preview .player-container .pause-overlay-container,
        .impact-stories-stage .video-post-preview-container .video-preview .player-container .pause-overlay-container,
        .impact-stories-app .video-post-preview-container .video-preview .player-container .pause-overlay-container {
          position: absolute;
          top: 45%;
          left: 45%;
          background-color: #ffffff;
          border-radius: 100%;
          display: none; }
          .impact-stories .video-post-preview-container .video-preview .player-container .pause-overlay-container .pause-overlay,
          .impact-stories-stage .video-post-preview-container .video-preview .player-container .pause-overlay-container .pause-overlay,
          .impact-stories-app .video-post-preview-container .video-preview .player-container .pause-overlay-container .pause-overlay {
            background-image: url(./../assets/impact21/images/control-pause-btn.png);
            width: 60px;
            height: 60px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer; }
        .impact-stories .video-post-preview-container .video-preview .player-container video,
        .impact-stories-stage .video-post-preview-container .video-preview .player-container video,
        .impact-stories-app .video-post-preview-container .video-preview .player-container video {
          margin-left: -5px;
          object-fit: cover;
          width: 100%;
          height: 100%;
          border: 4px solid #f4f4f4;
          box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
          border-radius: 20px;
          cursor: pointer; }
        .impact-stories .video-post-preview-container .video-preview .player-container .video-controls,
        .impact-stories-stage .video-post-preview-container .video-preview .player-container .video-controls,
        .impact-stories-app .video-post-preview-container .video-preview .player-container .video-controls {
          margin-top: 0.75em;
          margin-left: 1%;
          max-width: 340px;
          width: 100%; }
          .impact-stories .video-post-preview-container .video-preview .player-container .video-controls .video-actions,
          .impact-stories-stage .video-post-preview-container .video-preview .player-container .video-controls .video-actions,
          .impact-stories-app .video-post-preview-container .video-preview .player-container .video-controls .video-actions {
            display: flex;
            width: 100%;
            height: 10px; }
            .impact-stories .video-post-preview-container .video-preview .player-container .video-controls .video-actions .play,
            .impact-stories-stage .video-post-preview-container .video-preview .player-container .video-controls .video-actions .play,
            .impact-stories-app .video-post-preview-container .video-preview .player-container .video-controls .video-actions .play {
              background-image: url(./../assets/impact21/images/control-play-btn.png);
              width: 10px;
              height: 10px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              cursor: pointer; }
            .impact-stories .video-post-preview-container .video-preview .player-container .video-controls .video-actions .pause,
            .impact-stories-stage .video-post-preview-container .video-preview .player-container .video-controls .video-actions .pause,
            .impact-stories-app .video-post-preview-container .video-preview .player-container .video-controls .video-actions .pause {
              margin-left: 5px;
              background-image: url(./../assets/impact21/images/control-pause-btn.png);
              width: 10px;
              height: 10px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              cursor: pointer; }
            .impact-stories .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container,
            .impact-stories-stage .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container,
            .impact-stories-app .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container {
              height: 4px;
              width: 100%;
              margin-left: 0.5em;
              margin-top: 3px;
              border-radius: 30px;
              box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.11);
              background-color: #f5f5f5; }
              .impact-stories .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek,
              .impact-stories-stage .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek,
              .impact-stories-app .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek {
                width: 1%;
                max-width: 100%;
                height: 4px;
                background-color: #0d274d;
                border-radius: 30px; }
          .impact-stories .video-post-preview-container .video-preview .player-container .video-controls .video-info,
          .impact-stories-stage .video-post-preview-container .video-preview .player-container .video-controls .video-info,
          .impact-stories-app .video-post-preview-container .video-preview .player-container .video-controls .video-info {
            font-family: "CiscoSansTTLight";
            margin-top: 1em;
            padding-left: 20px;
            font-size: 7px;
            display: flex;
            justify-content: space-between;
            align-items: center; }
    .impact-stories .video-post-preview-container .story,
    .impact-stories-stage .video-post-preview-container .story,
    .impact-stories-app .video-post-preview-container .story {
      margin-top: 20px;
      width: 100%;
      min-height: 50px;
      max-height: 160px;
      font-size: 0.75em;
      font-family: CiscoSansTT;
      line-height: 1.33;
      word-break: break-word;
      word-wrap: break-word; }
    .impact-stories .video-post-preview-container .hashtags,
    .impact-stories-stage .video-post-preview-container .hashtags,
    .impact-stories-app .video-post-preview-container .hashtags {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 10px;
      color: #0076d5; }
      .impact-stories .video-post-preview-container .hashtags .hashtag-wrapper,
      .impact-stories-stage .video-post-preview-container .hashtags .hashtag-wrapper,
      .impact-stories-app .video-post-preview-container .hashtags .hashtag-wrapper {
        margin: 0.25em;
        font-size: 0.75em;
        font-family: CiscoSansTTBold;
        line-height: 1.33; }
        .impact-stories .video-post-preview-container .hashtags .hashtag-wrapper .hashtag,
        .impact-stories-stage .video-post-preview-container .hashtags .hashtag-wrapper .hashtag,
        .impact-stories-app .video-post-preview-container .hashtags .hashtag-wrapper .hashtag {
          font-weight: bold; }
    .impact-stories .video-post-preview-container .user-info,
    .impact-stories-stage .video-post-preview-container .user-info,
    .impact-stories-app .video-post-preview-container .user-info {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px; }
      .impact-stories .video-post-preview-container .user-info .user-image,
      .impact-stories-stage .video-post-preview-container .user-info .user-image,
      .impact-stories-app .video-post-preview-container .user-info .user-image {
        width: 41px;
        height: 41px;
        border-radius: 50%;
        border: solid 1px #1485ee;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center; }
        .impact-stories .video-post-preview-container .user-info .user-image img,
        .impact-stories-stage .video-post-preview-container .user-info .user-image img,
        .impact-stories-app .video-post-preview-container .user-info .user-image img {
          width: 36px;
          height: 36px;
          border-radius: 50%; }
      .impact-stories .video-post-preview-container .user-info .username,
      .impact-stories-stage .video-post-preview-container .user-info .username,
      .impact-stories-app .video-post-preview-container .user-info .username {
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 2;
        font-family: CiscoSansTT;
        color: #0076D5; }
        .impact-stories .video-post-preview-container .user-info .username span,
        .impact-stories-stage .video-post-preview-container .user-info .username span,
        .impact-stories-app .video-post-preview-container .user-info .username span {
          padding: 0.10em; }
        .impact-stories .video-post-preview-container .user-info .username .firstname,
        .impact-stories-stage .video-post-preview-container .user-info .username .firstname,
        .impact-stories-app .video-post-preview-container .user-info .username .firstname {
          text-transform: capitalize; }
        .impact-stories .video-post-preview-container .user-info .username .lastname,
        .impact-stories-stage .video-post-preview-container .user-info .username .lastname,
        .impact-stories-app .video-post-preview-container .user-info .username .lastname {
          text-transform: capitalize;
          overflow: hidden;
          width: 120px;
          text-overflow: ellipsis; }
  .impact-stories .video-download-container,
  .impact-stories-stage .video-download-container,
  .impact-stories-app .video-download-container {
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 914px;
    width: 90%; }
    .impact-stories .video-download-container .video-download-preview,
    .impact-stories-stage .video-download-container .video-download-preview,
    .impact-stories-app .video-download-container .video-download-preview {
      width: 100%;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 32px;
      background-color: #fff;
      box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
      padding: 40px 16px; }
      .impact-stories .video-download-container .video-download-preview .download-video-hashtag,
      .impact-stories-stage .video-download-container .video-download-preview .download-video-hashtag,
      .impact-stories-app .video-download-container .video-download-preview .download-video-hashtag {
        font-size: 32px;
        font-family: CiscoSansTTLight;
        color: #1a3d69;
        margin-bottom: 8px; }
      .impact-stories .video-download-container .video-download-preview .thank-you,
      .impact-stories-stage .video-download-container .video-download-preview .thank-you,
      .impact-stories-app .video-download-container .video-download-preview .thank-you {
        font-size: 16px;
        color: #1a3d69;
        margin-bottom: 9px;
        font-family: CiscoSans;
        height: 40px;
        display: flex;
        align-items: center; }
      .impact-stories .video-download-container .video-download-preview .video-thumb,
      .impact-stories-stage .video-download-container .video-download-preview .video-thumb,
      .impact-stories-app .video-download-container .video-download-preview .video-thumb {
        position: relative;
        margin-bottom: 25px; }
        .impact-stories .video-download-container .video-download-preview .video-thumb .thumb-play,
        .impact-stories-stage .video-download-container .video-download-preview .video-thumb .thumb-play,
        .impact-stories-app .video-download-container .video-download-preview .video-thumb .thumb-play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 60px;
          height: 60px;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          background-color: #fff; }
          .impact-stories .video-download-container .video-download-preview .video-thumb .thumb-play img,
          .impact-stories-stage .video-download-container .video-download-preview .video-thumb .thumb-play img,
          .impact-stories-app .video-download-container .video-download-preview .video-thumb .thumb-play img {
            width: 50px;
            height: 50px; }
      .impact-stories .video-download-container .video-download-preview a,
      .impact-stories-stage .video-download-container .video-download-preview a,
      .impact-stories-app .video-download-container .video-download-preview a {
        color: white;
        text-decoration: none; }
        .impact-stories .video-download-container .video-download-preview a:active,
        .impact-stories-stage .video-download-container .video-download-preview a:active,
        .impact-stories-app .video-download-container .video-download-preview a:active {
          color: white; }
        .impact-stories .video-download-container .video-download-preview a:visited,
        .impact-stories-stage .video-download-container .video-download-preview a:visited,
        .impact-stories-app .video-download-container .video-download-preview a:visited {
          color: white; }
      .impact-stories .video-download-container .video-download-preview .thumb-preview,
      .impact-stories-stage .video-download-container .video-download-preview .thumb-preview,
      .impact-stories-app .video-download-container .video-download-preview .thumb-preview {
        width: 100%;
        max-width: 370px;
        border: 4px solid #f4f4f4;
        border-radius: 18px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
        box-sizing: border-box; }
      .impact-stories .video-download-container .video-download-preview .download,
      .impact-stories-stage .video-download-container .video-download-preview .download,
      .impact-stories-app .video-download-container .video-download-preview .download {
        width: 174px;
        display: flex;
        min-height: 40px;
        font-size: 1em;
        font-family: CiscoSansTT;
        color: #fff;
        background-color: #0076d5;
        border-radius: 50px;
        cursor: pointer; }
        .impact-stories .video-download-container .video-download-preview .download a,
        .impact-stories-stage .video-download-container .video-download-preview .download a,
        .impact-stories-app .video-download-container .video-download-preview .download a {
          color: white;
          text-decoration: none; }
          .impact-stories .video-download-container .video-download-preview .download a:active,
          .impact-stories-stage .video-download-container .video-download-preview .download a:active,
          .impact-stories-app .video-download-container .video-download-preview .download a:active {
            color: white; }
          .impact-stories .video-download-container .video-download-preview .download a:visited,
          .impact-stories-stage .video-download-container .video-download-preview .download a:visited,
          .impact-stories-app .video-download-container .video-download-preview .download a:visited {
            color: white; }
        .impact-stories .video-download-container .video-download-preview .download .icon-fk,
        .impact-stories-stage .video-download-container .video-download-preview .download .icon-fk,
        .impact-stories-app .video-download-container .video-download-preview .download .icon-fk {
          display: none; }
        .impact-stories .video-download-container .video-download-preview .download .dwnld-img,
        .impact-stories-stage .video-download-container .video-download-preview .download .dwnld-img,
        .impact-stories-app .video-download-container .video-download-preview .download .dwnld-img {
          display: flex;
          align-items: center;
          padding: 0 20px;
          justify-content: space-between;
          width: 100%; }
        .impact-stories .video-download-container .video-download-preview .download .download-icon-bg .image.icdownload,
        .impact-stories-stage .video-download-container .video-download-preview .download .download-icon-bg .image.icdownload,
        .impact-stories-app .video-download-container .video-download-preview .download .download-icon-bg .image.icdownload {
          background-image: url(./../assets/impact21/images/download-icon.png);
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: contain; }
        .impact-stories .video-download-container .video-download-preview .download p,
        .impact-stories-stage .video-download-container .video-download-preview .download p,
        .impact-stories-app .video-download-container .video-download-preview .download p {
          font-size: 14px; }
    .impact-stories .video-download-container .action,
    .impact-stories-stage .video-download-container .action,
    .impact-stories-app .video-download-container .action {
      display: flex;
      flex-wrap: wrap;
      margin: 0%;
      justify-content: center;
      background-color: transparent;
      background-image: none;
      gap: 20px;
      max-width: 420px; }
      .impact-stories .video-download-container .action .close,
      .impact-stories-stage .video-download-container .action .close,
      .impact-stories-app .video-download-container .action .close {
        background-color: #f5f5f5;
        color: #0076d5;
        width: 190px;
        min-width: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #0076d5;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        line-height: 1.33;
        font-family: CiscoSansTT;
        border-radius: 50px;
        cursor: pointer;
        padding-top: 3px; }
      .impact-stories .video-download-container .action .redo,
      .impact-stories-stage .video-download-container .action .redo,
      .impact-stories-app .video-download-container .action .redo {
        width: 204px;
        min-width: 204px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0076d5;
        background-color: #0076d5;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        color: #fff;
        line-height: 1.33;
        font-family: CiscoSansTT;
        border-radius: 50px;
        cursor: pointer;
        padding-top: 2px; }
  .impact-stories .upload-multiple-preview-image-container,
  .impact-stories-stage .upload-multiple-preview-image-container,
  .impact-stories-app .upload-multiple-preview-image-container {
    position: relative;
    z-index: 2;
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 460px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 35px 24px 44px 38px;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
    background-color: #fff;
    margin-top: 121px; }
    .impact-stories .upload-multiple-preview-image-container .heading,
    .impact-stories-stage .upload-multiple-preview-image-container .heading,
    .impact-stories-app .upload-multiple-preview-image-container .heading {
      text-align: center;
      font-size: 32px;
      font-family: CiscoSansTTLight;
      line-height: 40px; }
    .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview,
    .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview,
    .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview {
      width: 100%; }
      .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items,
      .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items,
      .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items {
        margin-top: 45px; }
        .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items .item,
        .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items .item,
        .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items .item {
          width: 100%;
          margin-bottom: 15px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          gap: 23px; }
          .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-image,
          .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-image,
          .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-image {
            width: 78px;
            height: 78px;
            border-radius: 6px; }
            .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-image img,
            .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-image img,
            .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-image img {
              width: 100%;
              height: 100%;
              border-radius: 6px;
              object-fit: cover; }
          .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail,
          .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail,
          .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-self: center;
            margin-bottom: 7px; }
            .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-top,
            .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-top,
            .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 5px; }
              .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-top .item-name,
              .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-top .item-name,
              .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-top .item-name {
                font-family: CiscoSansTT;
                font-size: 14px; }
              .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-top .item-delete,
              .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-top .item-delete,
              .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-top .item-delete {
                cursor: pointer;
                padding-top: 5px; }
                .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-top .item-delete .icon-fk-cancel-circle:before,
                .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-top .item-delete .icon-fk-cancel-circle:before,
                .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-top .item-delete .icon-fk-cancel-circle:before {
                  height: 14px;
                  width: 14px;
                  margin-right: 0; }
            .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-bottom,
            .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-bottom,
            .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-detail .item-detail-bottom {
              width: 100%;
              height: 4px;
              background-color: #0D274D;
              border-radius: 2px;
              box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15); }
      .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .action,
      .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .action,
      .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .action {
        max-width: unset;
        margin-bottom: 0;
        width: 100%;
        gap: 10px;
        margin-top: 30px; }
        .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload,
        .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload,
        .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          flex: 1; }
          .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload .custom-file-upload,
          .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload .custom-file-upload,
          .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload .custom-file-upload {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #0076d5;
            width: 100%;
            min-height: 40px;
            margin: auto;
            font-size: 1em;
            font-family: CiscoSansTT;
            color: #fff;
            border-radius: 24px;
            cursor: pointer;
            gap: 10px;
            padding: 0 10px;
            min-width: max-content; }
            .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload .custom-file-upload img,
            .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload .custom-file-upload img,
            .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload .custom-file-upload img {
              height: 18px; }
            .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload .custom-file-upload.disabled,
            .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload .custom-file-upload.disabled,
            .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload .custom-file-upload.disabled {
              cursor: not-allowed; }
          .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload label,
          .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload label,
          .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload label {
            width: 175px; }
            .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload label .text,
            .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload label .text,
            .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload label .text {
              font-size: 13px;
              padding-top: 2px; }
          .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload input,
          .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload input,
          .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .action .file-upload input {
            display: none; }
        .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .action .continue,
        .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .action .continue,
        .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .action .continue {
          width: 170px;
          font-size: 13px;
          margin-top: unset;
          padding-top: 2px; }
    .impact-stories .upload-multiple-preview-image-container .error,
    .impact-stories-stage .upload-multiple-preview-image-container .error,
    .impact-stories-app .upload-multiple-preview-image-container .error {
      margin-top: 20px;
      font-size: 0.75em;
      font-family: CiscoSansTTBold;
      text-align: right;
      color: #c10707; }
    .impact-stories .upload-multiple-preview-image-container .info,
    .impact-stories-stage .upload-multiple-preview-image-container .info,
    .impact-stories-app .upload-multiple-preview-image-container .info {
      margin-top: 20px;
      font-size: 0.75em;
      font-family: CiscoSansTTLight;
      text-align: right;
      color: #0d274d; }
      .impact-stories .upload-multiple-preview-image-container .info.hide,
      .impact-stories-stage .upload-multiple-preview-image-container .info.hide,
      .impact-stories-app .upload-multiple-preview-image-container .info.hide {
        display: none; }
  .impact-stories .upload-image-container,
  .impact-stories-stage .upload-image-container,
  .impact-stories-app .upload-image-container {
    position: relative;
    z-index: 2;
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 460px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 35px 15px 70px 15px;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
    background-color: #fff;
    margin-top: 121px; }
    .impact-stories .upload-image-container .card-camera-icon,
    .impact-stories-stage .upload-image-container .card-camera-icon,
    .impact-stories-app .upload-image-container .card-camera-icon {
      margin-bottom: 20px; }
      .impact-stories .upload-image-container .card-camera-icon img,
      .impact-stories-stage .upload-image-container .card-camera-icon img,
      .impact-stories-app .upload-image-container .card-camera-icon img {
        width: 50px; }
    .impact-stories .upload-image-container .heading,
    .impact-stories-stage .upload-image-container .heading,
    .impact-stories-app .upload-image-container .heading {
      text-align: center;
      font-size: 32px;
      font-family: CiscoSansTTLight;
      line-height: 40px; }
    .impact-stories .upload-image-container .drag-n-drop,
    .impact-stories-stage .upload-image-container .drag-n-drop,
    .impact-stories-app .upload-image-container .drag-n-drop {
      margin-top: 50px;
      width: 100%;
      max-width: 370px;
      min-height: 208px;
      padding-bottom: 1em;
      border-radius: 5px;
      border: dashed 1px #676565;
      background-color: #f9f9f9; }
      .impact-stories .upload-image-container .drag-n-drop .dnd-title,
      .impact-stories-stage .upload-image-container .drag-n-drop .dnd-title,
      .impact-stories-app .upload-image-container .drag-n-drop .dnd-title {
        padding-top: 45px;
        text-align: center;
        font-size: 1em;
        font-family: CiscoSansTT;
        line-height: 1.25; }
      .impact-stories .upload-image-container .drag-n-drop p,
      .impact-stories-stage .upload-image-container .drag-n-drop p,
      .impact-stories-app .upload-image-container .drag-n-drop p {
        text-align: center;
        font-size: 1em;
        font-family: CiscoSansTT;
        line-height: 1.2;
        margin: 5px auto 20px;
        text-transform: capitalize; }
      .impact-stories .upload-image-container .drag-n-drop .file-upload,
      .impact-stories-stage .upload-image-container .drag-n-drop .file-upload,
      .impact-stories-app .upload-image-container .drag-n-drop .file-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
        .impact-stories .upload-image-container .drag-n-drop .file-upload .max-limit,
        .impact-stories-stage .upload-image-container .drag-n-drop .file-upload .max-limit,
        .impact-stories-app .upload-image-container .drag-n-drop .file-upload .max-limit {
          font-size: 0.8em;
          font-family: CiscoSansTT;
          margin-top: 20px;
          color: rgba(13, 39, 77, 0.4); }
        .impact-stories .upload-image-container .drag-n-drop .file-upload .custom-file-upload,
        .impact-stories-stage .upload-image-container .drag-n-drop .file-upload .custom-file-upload,
        .impact-stories-app .upload-image-container .drag-n-drop .file-upload .custom-file-upload {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #0076d5;
          width: 100%;
          max-width: 254px;
          min-height: 40px;
          margin: auto;
          font-size: 1em;
          font-family: CiscoSansTT;
          color: #fff;
          border-radius: 24px;
          cursor: pointer;
          gap: 10px; }
          .impact-stories .upload-image-container .drag-n-drop .file-upload .custom-file-upload img,
          .impact-stories-stage .upload-image-container .drag-n-drop .file-upload .custom-file-upload img,
          .impact-stories-app .upload-image-container .drag-n-drop .file-upload .custom-file-upload img {
            height: 20px; }
        .impact-stories .upload-image-container .drag-n-drop .file-upload label .text,
        .impact-stories-stage .upload-image-container .drag-n-drop .file-upload label .text,
        .impact-stories-app .upload-image-container .drag-n-drop .file-upload label .text {
          font-size: 14px;
          padding-top: 3px; }
        .impact-stories .upload-image-container .drag-n-drop .file-upload input,
        .impact-stories-stage .upload-image-container .drag-n-drop .file-upload input,
        .impact-stories-app .upload-image-container .drag-n-drop .file-upload input {
          display: none; }
    .impact-stories .upload-image-container .upload-multiple-preview .item,
    .impact-stories-stage .upload-image-container .upload-multiple-preview .item,
    .impact-stories-app .upload-image-container .upload-multiple-preview .item {
      width: 100%;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: flex-start; }
      .impact-stories .upload-image-container .upload-multiple-preview .item .item-image,
      .impact-stories-stage .upload-image-container .upload-multiple-preview .item .item-image,
      .impact-stories-app .upload-image-container .upload-multiple-preview .item .item-image {
        width: 75px;
        height: 75px;
        border-radius: 6px; }
        .impact-stories .upload-image-container .upload-multiple-preview .item .item-image img,
        .impact-stories-stage .upload-image-container .upload-multiple-preview .item .item-image img,
        .impact-stories-app .upload-image-container .upload-multiple-preview .item .item-image img {
          width: 100%;
          height: 100%;
          border-radius: 6px; }
      .impact-stories .upload-image-container .upload-multiple-preview .item .item-detail,
      .impact-stories-stage .upload-image-container .upload-multiple-preview .item .item-detail,
      .impact-stories-app .upload-image-container .upload-multiple-preview .item .item-detail {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 25px;
        align-self: center;
        margin-bottom: 15px; }
        .impact-stories .upload-image-container .upload-multiple-preview .item .item-detail .item-detail-top,
        .impact-stories-stage .upload-image-container .upload-multiple-preview .item .item-detail .item-detail-top,
        .impact-stories-app .upload-image-container .upload-multiple-preview .item .item-detail .item-detail-top {
          display: flex;
          justify-content: space-between; }
          .impact-stories .upload-image-container .upload-multiple-preview .item .item-detail .item-detail-top .item-name,
          .impact-stories-stage .upload-image-container .upload-multiple-preview .item .item-detail .item-detail-top .item-name,
          .impact-stories-app .upload-image-container .upload-multiple-preview .item .item-detail .item-detail-top .item-name {
            font-family: CiscoSansTTLight;
            margin-bottom: 5px; }
          .impact-stories .upload-image-container .upload-multiple-preview .item .item-detail .item-detail-top .item-delete,
          .impact-stories-stage .upload-image-container .upload-multiple-preview .item .item-detail .item-detail-top .item-delete,
          .impact-stories-app .upload-image-container .upload-multiple-preview .item .item-detail .item-detail-top .item-delete {
            padding-top: 5px;
            cursor: pointer; }
        .impact-stories .upload-image-container .upload-multiple-preview .item .item-detail .item-detail-bottom,
        .impact-stories-stage .upload-image-container .upload-multiple-preview .item .item-detail .item-detail-bottom,
        .impact-stories-app .upload-image-container .upload-multiple-preview .item .item-detail .item-detail-bottom {
          width: 100%;
          height: 4px;
          background-color: #0D274D;
          border-radius: 2px; }
    .impact-stories .upload-image-container .upload-multiple-preview .file-upload,
    .impact-stories-stage .upload-image-container .upload-multiple-preview .file-upload,
    .impact-stories-app .upload-image-container .upload-multiple-preview .file-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 2.5em;
      margin-right: 10px; }
      .impact-stories .upload-image-container .upload-multiple-preview .file-upload .custom-file-upload,
      .impact-stories-stage .upload-image-container .upload-multiple-preview .file-upload .custom-file-upload,
      .impact-stories-app .upload-image-container .upload-multiple-preview .file-upload .custom-file-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0076d5;
        width: 100%;
        max-width: 254px;
        min-height: 40px;
        margin: auto;
        font-size: 1em;
        font-family: CiscoSansTT;
        color: #fff;
        border-radius: 24px;
        cursor: pointer;
        gap: 10px; }
        .impact-stories .upload-image-container .upload-multiple-preview .file-upload .custom-file-upload img,
        .impact-stories-stage .upload-image-container .upload-multiple-preview .file-upload .custom-file-upload img,
        .impact-stories-app .upload-image-container .upload-multiple-preview .file-upload .custom-file-upload img {
          height: 20px; }
        .impact-stories .upload-image-container .upload-multiple-preview .file-upload .custom-file-upload.disabled,
        .impact-stories-stage .upload-image-container .upload-multiple-preview .file-upload .custom-file-upload.disabled,
        .impact-stories-app .upload-image-container .upload-multiple-preview .file-upload .custom-file-upload.disabled {
          cursor: not-allowed; }
      .impact-stories .upload-image-container .upload-multiple-preview .file-upload label,
      .impact-stories-stage .upload-image-container .upload-multiple-preview .file-upload label,
      .impact-stories-app .upload-image-container .upload-multiple-preview .file-upload label {
        width: 175px; }
        .impact-stories .upload-image-container .upload-multiple-preview .file-upload label .text,
        .impact-stories-stage .upload-image-container .upload-multiple-preview .file-upload label .text,
        .impact-stories-app .upload-image-container .upload-multiple-preview .file-upload label .text {
          font-size: 14px;
          padding-top: 3px; }
      .impact-stories .upload-image-container .upload-multiple-preview .file-upload input,
      .impact-stories-stage .upload-image-container .upload-multiple-preview .file-upload input,
      .impact-stories-app .upload-image-container .upload-multiple-preview .file-upload input {
        display: none; }
    .impact-stories .upload-image-container .info,
    .impact-stories-stage .upload-image-container .info,
    .impact-stories-app .upload-image-container .info {
      position: absolute;
      bottom: 0;
      text-align: center;
      padding-bottom: 25px; }
    .impact-stories .upload-image-container .error,
    .impact-stories-stage .upload-image-container .error,
    .impact-stories-app .upload-image-container .error {
      margin-top: 5px;
      font-size: 0.75em;
      font-family: CiscoSansTTBold;
      text-align: right;
      color: #c10707; }
  .impact-stories .upload-preview-container,
  .impact-stories-stage .upload-preview-container,
  .impact-stories-app .upload-preview-container {
    z-index: 2;
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 50px 30px 50px;
    background-color: #fff;
    margin-top: 4.31em;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
    position: relative; }
    .impact-stories .upload-preview-container .heading,
    .impact-stories-stage .upload-preview-container .heading,
    .impact-stories-app .upload-preview-container .heading {
      text-align: center;
      font-size: 32px;
      font-family: CiscoSansTTLight;
      line-height: 1; }
    .impact-stories .upload-preview-container .upload-preview,
    .impact-stories-stage .upload-preview-container .upload-preview,
    .impact-stories-app .upload-preview-container .upload-preview {
      margin-top: 30px;
      position: relative;
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto; }
      .impact-stories .upload-preview-container .upload-preview img,
      .impact-stories-stage .upload-preview-container .upload-preview img,
      .impact-stories-app .upload-preview-container .upload-preview img {
        display: none;
        width: 100%;
        height: 100%;
        border: #f4f4f4 4px solid;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
        border-radius: 10px; }
      .impact-stories .upload-preview-container .upload-preview .sticker-container,
      .impact-stories-stage .upload-preview-container .upload-preview .sticker-container,
      .impact-stories-app .upload-preview-container .upload-preview .sticker-container {
        width: 100%; }
        .impact-stories .upload-preview-container .upload-preview .sticker-container.hide-stickers,
        .impact-stories-stage .upload-preview-container .upload-preview .sticker-container.hide-stickers,
        .impact-stories-app .upload-preview-container .upload-preview .sticker-container.hide-stickers {
          display: none !important; }
        .impact-stories .upload-preview-container .upload-preview .sticker-container .spinner.show,
        .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .spinner.show,
        .impact-stories-app .upload-preview-container .upload-preview .sticker-container .spinner.show {
          left: 35%; }
        .impact-stories .upload-preview-container .upload-preview .sticker-container .canvas-container,
        .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .canvas-container,
        .impact-stories-app .upload-preview-container .upload-preview .sticker-container .canvas-container {
          max-width: 350px;
          border-radius: 10px;
          margin: auto;
          position: relative; }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .prev,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .prev,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .prev {
            width: 40px;
            height: 40px;
            background-color: #2e2e2ecc;
            border-radius: 20px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translate(0, -50%);
            left: 10px; }
            .impact-stories .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .prev .prev-icon,
            .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .prev .prev-icon,
            .impact-stories-app .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .prev .prev-icon {
              border: none;
              box-shadow: none;
              z-index: 10;
              display: block;
              width: 9px;
              height: 15px; }
            .impact-stories .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .prev.show-arrow,
            .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .prev.show-arrow,
            .impact-stories-app .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .prev.show-arrow {
              display: flex; }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .next,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .next,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .next {
            width: 40px;
            height: 40px;
            background-color: #2e2e2ecc;
            border-radius: 20px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translate(0, -50%);
            right: 10px; }
            .impact-stories .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .next .next-icon,
            .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .next .next-icon,
            .impact-stories-app .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .next .next-icon {
              border: none;
              box-shadow: none;
              z-index: 10;
              display: block;
              width: 9px;
              height: 15px; }
            .impact-stories .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .next.show-arrow,
            .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .next.show-arrow,
            .impact-stories-app .upload-preview-container .upload-preview .sticker-container .canvas-container .slider-icon .next.show-arrow {
              display: flex; }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .canvas-container canvas,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .canvas-container canvas,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .canvas-container canvas {
            border-radius: 18px;
            border: #f4f4f4 4px solid;
            box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23); }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .canvas-container .upper-canvas,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .canvas-container .upper-canvas,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .canvas-container .upper-canvas {
            position: relative !important; }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .canvas-container .hide-stickers,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .canvas-container .hide-stickers,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .canvas-container .hide-stickers {
            display: none !important; }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .canvas-container #fabricCanvas,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .canvas-container #fabricCanvas,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .canvas-container #fabricCanvas {
            width: 340px;
            height: 450px;
            border-radius: 10px; }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .canvas-container #imageCanvas,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .canvas-container #imageCanvas,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .canvas-container #imageCanvas {
            width: 340px;
            height: 450px; }
        .impact-stories .upload-preview-container .upload-preview .sticker-container .select-sticker-container,
        .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-sticker-container,
        .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-sticker-container {
          margin-top: 25px;
          border-bottom: 2px solid #f0f0f0;
          border-top: 2px solid #f0f0f0;
          padding: 17px 0; }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .select-sticker-container .heading,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-sticker-container .heading,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-sticker-container .heading {
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .impact-stories .upload-preview-container .upload-preview .sticker-container .select-sticker-container .heading .heading-title,
            .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-sticker-container .heading .heading-title,
            .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-sticker-container .heading .heading-title {
              text-align: left;
              font-size: 24px;
              color: #1a3d69;
              font-family: CiscoSansTTLight;
              line-height: 1.33; }
            .impact-stories .upload-preview-container .upload-preview .sticker-container .select-sticker-container .heading .clear-btn,
            .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-sticker-container .heading .clear-btn,
            .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-sticker-container .heading .clear-btn {
              cursor: pointer;
              background: #f6be00;
              display: flex;
              padding: 5px 15px;
              border-radius: 30px;
              font-family: 'CiscoSansTTLight'; }
              .impact-stories .upload-preview-container .upload-preview .sticker-container .select-sticker-container .heading .clear-btn span,
              .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-sticker-container .heading .clear-btn span,
              .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-sticker-container .heading .clear-btn span {
                font-size: 12px;
                padding-top: 2px; }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .select-sticker-container .sub-heading,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-sticker-container .sub-heading,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-sticker-container .sub-heading {
            text-align: left;
            line-height: 1.33;
            font-size: 1em;
            color: #1a3d69;
            font-family: CiscoSansTTLight;
            line-height: 1.33;
            margin-bottom: 13px; }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .select-sticker-container .stickers-preview,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-sticker-container .stickers-preview,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-sticker-container .stickers-preview {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center; }
            .impact-stories .upload-preview-container .upload-preview .sticker-container .select-sticker-container .stickers-preview .sticker,
            .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-sticker-container .stickers-preview .sticker,
            .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-sticker-container .stickers-preview .sticker {
              cursor: pointer;
              background-color: #0076d5;
              box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.25);
              border-radius: 10px;
              margin: 5px 16px 5px 0;
              width: 55px;
              height: 55px;
              min-width: 55px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center; }
              .impact-stories .upload-preview-container .upload-preview .sticker-container .select-sticker-container .stickers-preview .sticker.clear,
              .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-sticker-container .stickers-preview .sticker.clear,
              .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-sticker-container .stickers-preview .sticker.clear {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 0.5em;
                color: #0076d5;
                font-family: CiscoSansTT;
                background-color: #fff;
                box-shadow: 3px 3px 3px 0px #0000002b, inset 3px 3px 3px 0px white; }
                .impact-stories .upload-preview-container .upload-preview .sticker-container .select-sticker-container .stickers-preview .sticker.clear .icon-fk,
                .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-sticker-container .stickers-preview .sticker.clear .icon-fk,
                .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-sticker-container .stickers-preview .sticker.clear .icon-fk {
                  font-size: 2em;
                  color: #0076d5;
                  margin-bottom: 5px; }
        .impact-stories .upload-preview-container .upload-preview .sticker-container .select-bg-container,
        .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-bg-container,
        .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-bg-container {
          border-bottom: 2px solid #f0f0f0;
          padding: 20px 0; }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .select-bg-container .heading,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-bg-container .heading,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-bg-container .heading {
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .impact-stories .upload-preview-container .upload-preview .sticker-container .select-bg-container .heading .heading-title,
            .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-bg-container .heading .heading-title,
            .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-bg-container .heading .heading-title {
              text-align: left;
              font-size: 24px;
              color: #1a3d69;
              font-family: CiscoSansTTLight;
              line-height: 1.33; }
            .impact-stories .upload-preview-container .upload-preview .sticker-container .select-bg-container .heading .clear-btn,
            .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-bg-container .heading .clear-btn,
            .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-bg-container .heading .clear-btn {
              cursor: pointer;
              background: #f6be00;
              display: flex;
              padding: 4px 15px;
              border-radius: 30px;
              font-family: 'CiscoSansTTLight'; }
              .impact-stories .upload-preview-container .upload-preview .sticker-container .select-bg-container .heading .clear-btn span,
              .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-bg-container .heading .clear-btn span,
              .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-bg-container .heading .clear-btn span {
                font-size: 12px;
                padding-top: 2px; }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .select-bg-container .sub-heading,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-bg-container .sub-heading,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-bg-container .sub-heading {
            text-align: left;
            line-height: 1.33;
            font-size: 1em;
            color: #1a3d69;
            font-family: CiscoSansTTLight;
            line-height: 1.33;
            margin-bottom: 13px; }
          .impact-stories .upload-preview-container .upload-preview .sticker-container .select-bg-container .bgs-preview,
          .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-bg-container .bgs-preview,
          .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-bg-container .bgs-preview {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center; }
            .impact-stories .upload-preview-container .upload-preview .sticker-container .select-bg-container .bgs-preview .bg,
            .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-bg-container .bgs-preview .bg,
            .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-bg-container .bgs-preview .bg {
              cursor: pointer;
              box-shadow: 3px 3px 3px 0px #0000002b;
              border-radius: 10px;
              margin: 5px 16px 5px 0px;
              width: 55px;
              height: 55px;
              min-width: 55px;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center; }
              .impact-stories .upload-preview-container .upload-preview .sticker-container .select-bg-container .bgs-preview .bg.clear,
              .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-bg-container .bgs-preview .bg.clear,
              .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-bg-container .bgs-preview .bg.clear {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 0.5em;
                font-family: CiscoSansTT;
                color: #0076d5;
                background-color: #fff;
                box-shadow: 3px 3px 3px 0px #0000002b, inset 3px 3px 3px 0px white; }
                .impact-stories .upload-preview-container .upload-preview .sticker-container .select-bg-container .bgs-preview .bg.clear .icon-fk,
                .impact-stories-stage .upload-preview-container .upload-preview .sticker-container .select-bg-container .bgs-preview .bg.clear .icon-fk,
                .impact-stories-app .upload-preview-container .upload-preview .sticker-container .select-bg-container .bgs-preview .bg.clear .icon-fk {
                  font-size: 2em;
                  margin-bottom: 5px; }
    .impact-stories .upload-preview-container .action,
    .impact-stories-stage .upload-preview-container .action,
    .impact-stories-app .upload-preview-container .action {
      margin-top: 30px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 88%; }
      .impact-stories .upload-preview-container .action .redo,
      .impact-stories-stage .upload-preview-container .action .redo,
      .impact-stories-app .upload-preview-container .action .redo {
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        font-size: 1em;
        line-height: 1.33;
        font-family: CiscoSansTT;
        color: #0076d5;
        border: 1px solid #0076d5;
        background-color: #fff;
        border-radius: 24px;
        cursor: pointer;
        padding: 0 20px; }
        .impact-stories .upload-preview-container .action .redo .icon-fk,
        .impact-stories-stage .upload-preview-container .action .redo .icon-fk,
        .impact-stories-app .upload-preview-container .action .redo .icon-fk {
          display: none;
          font-size: 1.25em;
          padding-top: 0.25em; }
        .impact-stories .upload-preview-container .action .redo .image.camera,
        .impact-stories-stage .upload-preview-container .action .redo .image.camera,
        .impact-stories-app .upload-preview-container .action .redo .image.camera {
          background-image: url(./../assets/impact21/images/back-icon.svg);
          width: 20px;
          height: 14px;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 5px; }
        .impact-stories .upload-preview-container .action .redo .text,
        .impact-stories-stage .upload-preview-container .action .redo .text,
        .impact-stories-app .upload-preview-container .action .redo .text {
          margin-top: 3px; }
      .impact-stories .upload-preview-container .action .continue,
      .impact-stories-stage .upload-preview-container .action .continue,
      .impact-stories-app .upload-preview-container .action .continue {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0076d5 !important;
        flex: 1;
        min-height: 40px;
        font-size: 1em;
        line-height: 1.33;
        font-family: CiscoSansTT;
        color: #fff;
        border-radius: 24px;
        cursor: pointer;
        border: none;
        box-shadow: none;
        background-image: none; }
    .impact-stories .upload-preview-container .capture-action,
    .impact-stories-stage .upload-preview-container .capture-action,
    .impact-stories-app .upload-preview-container .capture-action {
      cursor: pointer;
      text-align: center;
      background-color: #1a3d69;
      color: #ffffff;
      border-radius: 10px;
      padding: 1em;
      margin: 1em; }
  .impact-stories .full-upload-container,
  .impact-stories-stage .full-upload-container,
  .impact-stories-app .full-upload-container {
    z-index: 11;
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 29px 37px 29px;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
    background-color: #fff;
    margin-top: 119px;
    width: 90%;
    max-width: 913px;
    position: relative; }
    .impact-stories .full-upload-container .heading,
    .impact-stories-stage .full-upload-container .heading,
    .impact-stories-app .full-upload-container .heading {
      text-align: center;
      font-size: 32px;
      font-family: CiscoSansTTLight; }
    .impact-stories .full-upload-container .add-post-container,
    .impact-stories-stage .full-upload-container .add-post-container,
    .impact-stories-app .full-upload-container .add-post-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 30px;
      width: 100%; }
      .impact-stories .full-upload-container .add-post-container .upload-preview-and-retake,
      .impact-stories-stage .full-upload-container .add-post-container .upload-preview-and-retake,
      .impact-stories-app .full-upload-container .add-post-container .upload-preview-and-retake {
        position: relative;
        width: 100%;
        max-width: 370px;
        height: 100%;
        margin-right: 27px; }
        .impact-stories .full-upload-container .add-post-container .upload-preview-and-retake .prev,
        .impact-stories-stage .full-upload-container .add-post-container .upload-preview-and-retake .prev,
        .impact-stories-app .full-upload-container .add-post-container .upload-preview-and-retake .prev {
          display: none;
          position: absolute;
          width: 40px;
          height: 40px;
          top: 50%;
          transform: translate(0, -70%);
          left: 8px;
          z-index: 5;
          background-color: #2e2e2ecc;
          border-radius: 20px;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
          .impact-stories .full-upload-container .add-post-container .upload-preview-and-retake .prev.show-arrow,
          .impact-stories-stage .full-upload-container .add-post-container .upload-preview-and-retake .prev.show-arrow,
          .impact-stories-app .full-upload-container .add-post-container .upload-preview-and-retake .prev.show-arrow {
            display: flex; }
          .impact-stories .full-upload-container .add-post-container .upload-preview-and-retake .prev .prev-icon,
          .impact-stories-stage .full-upload-container .add-post-container .upload-preview-and-retake .prev .prev-icon,
          .impact-stories-app .full-upload-container .add-post-container .upload-preview-and-retake .prev .prev-icon {
            border: none;
            box-shadow: none;
            z-index: 10;
            display: block;
            width: 9px;
            height: 15px; }
        .impact-stories .full-upload-container .add-post-container .upload-preview-and-retake .next,
        .impact-stories-stage .full-upload-container .add-post-container .upload-preview-and-retake .next,
        .impact-stories-app .full-upload-container .add-post-container .upload-preview-and-retake .next {
          display: none;
          position: absolute;
          width: 40px;
          height: 40px;
          top: 50%;
          transform: translate(0, -70%);
          right: 8px;
          z-index: 5;
          background-color: #2e2e2ecc;
          border-radius: 20px;
          justify-content: center;
          align-items: center;
          cursor: pointer; }
          .impact-stories .full-upload-container .add-post-container .upload-preview-and-retake .next.show-arrow,
          .impact-stories-stage .full-upload-container .add-post-container .upload-preview-and-retake .next.show-arrow,
          .impact-stories-app .full-upload-container .add-post-container .upload-preview-and-retake .next.show-arrow {
            display: flex; }
          .impact-stories .full-upload-container .add-post-container .upload-preview-and-retake .next .next-icon,
          .impact-stories-stage .full-upload-container .add-post-container .upload-preview-and-retake .next .next-icon,
          .impact-stories-app .full-upload-container .add-post-container .upload-preview-and-retake .next .next-icon {
            border: none;
            box-shadow: none;
            z-index: 10;
            display: block;
            width: 9px;
            height: 15px; }
        .impact-stories .full-upload-container .add-post-container .upload-preview-and-retake img,
        .impact-stories-stage .full-upload-container .add-post-container .upload-preview-and-retake img,
        .impact-stories-app .full-upload-container .add-post-container .upload-preview-and-retake img {
          width: 100%;
          height: 100%;
          border: 4px solid #f4f4f4;
          box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
          border-radius: 10px; }
        .impact-stories .full-upload-container .add-post-container .upload-preview-and-retake .action-retake,
        .impact-stories-stage .full-upload-container .add-post-container .upload-preview-and-retake .action-retake,
        .impact-stories-app .full-upload-container .add-post-container .upload-preview-and-retake .action-retake {
          margin-top: 20px;
          font-size: 1em;
          line-height: 1.33;
          font-family: CiscoSansTT;
          cursor: pointer;
          color: #0076d5;
          display: flex;
          justify-content: center; }
          .impact-stories .full-upload-container .add-post-container .upload-preview-and-retake .action-retake .btn-redo,
          .impact-stories-stage .full-upload-container .add-post-container .upload-preview-and-retake .action-retake .btn-redo,
          .impact-stories-app .full-upload-container .add-post-container .upload-preview-and-retake .action-retake .btn-redo {
            border-bottom: 2px solid; }
      .impact-stories .full-upload-container .add-post-container .add-story-container,
      .impact-stories-stage .full-upload-container .add-post-container .add-story-container,
      .impact-stories-app .full-upload-container .add-post-container .add-story-container {
        flex: 1; }
        .impact-stories .full-upload-container .add-post-container .add-story-container .user-info,
        .impact-stories-stage .full-upload-container .add-post-container .add-story-container .user-info,
        .impact-stories-app .full-upload-container .add-post-container .add-story-container .user-info {
          display: flex;
          align-items: center; }
          .impact-stories .full-upload-container .add-post-container .add-story-container .user-info .user-img,
          .impact-stories-stage .full-upload-container .add-post-container .add-story-container .user-info .user-img,
          .impact-stories-app .full-upload-container .add-post-container .add-story-container .user-info .user-img {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #0d274d;
            width: 41px;
            height: 41px;
            border-radius: 50%; }
          .impact-stories .full-upload-container .add-post-container .add-story-container .user-info img,
          .impact-stories-stage .full-upload-container .add-post-container .add-story-container .user-info img,
          .impact-stories-app .full-upload-container .add-post-container .add-story-container .user-info img {
            width: 36px;
            height: 36px;
            border-radius: 50%; }
          .impact-stories .full-upload-container .add-post-container .add-story-container .user-info .username,
          .impact-stories-stage .full-upload-container .add-post-container .add-story-container .user-info .username,
          .impact-stories-app .full-upload-container .add-post-container .add-story-container .user-info .username {
            margin-left: 14px;
            display: flex;
            justify-content: flex-start;
            font-size: 14px;
            line-height: 1.5;
            font-family: CiscoSans; }
            .impact-stories .full-upload-container .add-post-container .add-story-container .user-info .username span,
            .impact-stories-stage .full-upload-container .add-post-container .add-story-container .user-info .username span,
            .impact-stories-app .full-upload-container .add-post-container .add-story-container .user-info .username span {
              padding: 0.15em; }
            .impact-stories .full-upload-container .add-post-container .add-story-container .user-info .username .firstname,
            .impact-stories-stage .full-upload-container .add-post-container .add-story-container .user-info .username .firstname,
            .impact-stories-app .full-upload-container .add-post-container .add-story-container .user-info .username .firstname {
              text-transform: capitalize; }
            .impact-stories .full-upload-container .add-post-container .add-story-container .user-info .username .lastname,
            .impact-stories-stage .full-upload-container .add-post-container .add-story-container .user-info .username .lastname,
            .impact-stories-app .full-upload-container .add-post-container .add-story-container .user-info .username .lastname {
              text-transform: capitalize; }
        .impact-stories .full-upload-container .add-post-container .add-story-container .story-container,
        .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container,
        .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container {
          margin-top: 25px; }
          .impact-stories .full-upload-container .add-post-container .add-story-container .story-container .heading,
          .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container .heading,
          .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container .heading {
            font-size: 24px;
            font-family: CiscoSansTTLight;
            line-height: 1.33;
            text-align: left; }
          .impact-stories .full-upload-container .add-post-container .add-story-container .story-container .sub-heading,
          .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container .sub-heading,
          .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container .sub-heading {
            font-size: 13px;
            font-family: CiscoSans;
            line-height: 1.33;
            text-align: left;
            margin-bottom: 18px;
            margin-top: 5px; }
          .impact-stories .full-upload-container .add-post-container .add-story-container .story-container .text-area,
          .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container .text-area,
          .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container .text-area {
            display: flex;
            flex-direction: column;
            cursor: text; }
            .impact-stories .full-upload-container .add-post-container .add-story-container .story-container .text-area.error textarea,
            .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container .text-area.error textarea,
            .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container .text-area.error textarea {
              border: 1px solid #c10707; }
            .impact-stories .full-upload-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta,
            .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta,
            .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta {
              font-family: CiscoSansTT;
              justify-content: space-between; }
              .impact-stories .full-upload-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta .error-msg,
              .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta .error-msg,
              .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container .text-area.error .text-area-meta .error-msg {
                display: inline;
                color: #c10707;
                font-family: CiscoSansTT;
                margin-top: 5px; }
            .impact-stories .full-upload-container .add-post-container .add-story-container .story-container .text-area textarea,
            .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container .text-area textarea,
            .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container .text-area textarea {
              height: 212px;
              width: 100%;
              font-size: 13px;
              box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.24);
              background-color: #f9f9f9;
              border: none;
              padding-top: 1em;
              padding-left: 1em;
              resize: none;
              font-family: CiscoSansTT;
              cursor: text;
              border-radius: 5px; }
              .impact-stories .full-upload-container .add-post-container .add-story-container .story-container .text-area textarea:focus-visible,
              .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container .text-area textarea:focus-visible,
              .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container .text-area textarea:focus-visible {
                outline: none; }
              .impact-stories .full-upload-container .add-post-container .add-story-container .story-container .text-area textarea:active,
              .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container .text-area textarea:active,
              .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container .text-area textarea:active {
                outline: none; }
            .impact-stories .full-upload-container .add-post-container .add-story-container .story-container .text-area .text-area-meta,
            .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container .text-area .text-area-meta,
            .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container .text-area .text-area-meta {
              display: flex;
              justify-content: flex-end;
              font-size: 0.625em;
              padding: 0.1em;
              font-family: CiscoSansTT; }
              .impact-stories .full-upload-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .error-msg,
              .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .error-msg,
              .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .error-msg {
                display: none; }
              .impact-stories .full-upload-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .character-count,
              .impact-stories-stage .full-upload-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .character-count,
              .impact-stories-app .full-upload-container .add-post-container .add-story-container .story-container .text-area .text-area-meta .character-count {
                margin-top: 5px;
                display: none; }
        .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container,
        .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container,
        .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container {
          margin-top: 25px; }
          .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .heading,
          .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .heading,
          .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .heading {
            font-size: 24px;
            font-family: CiscoSansTTLight;
            text-align: left;
            line-height: 1.33; }
          .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .sub-heading,
          .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .sub-heading,
          .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .sub-heading {
            text-align: left;
            font-family: CiscoSansTT;
            font-size: 13px;
            line-height: 1.33;
            margin-top: 5px; }
          .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags,
          .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags,
          .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags {
            margin-top: 10px;
            padding: 0.5em;
            border: none;
            display: flex;
            max-width: 480px;
            flex-wrap: wrap;
            border-radius: 10px;
            box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.24);
            background-color: #f9f9f9;
            align-items: center;
            gap: 8px;
            min-height: 50px; }
            .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags.hideinput input,
            .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags.hideinput input,
            .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags.hideinput input {
              display: none; }
            .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper,
            .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper,
            .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper {
              display: flex;
              color: #525354;
              border-radius: 8px;
              background-color: #e2e2e2;
              height: 31px;
              padding-right: 4px;
              align-items: center; }
              .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .hashtag,
              .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .hashtag,
              .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .hashtag {
                font-family: CiscoSansTT;
                font-size: 13px;
                padding: 0.05em 0.75em;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: alias; }
              .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .icon-fk,
              .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .icon-fk,
              .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags .hashtag-wrapper .icon-fk {
                cursor: pointer;
                margin-left: -7px;
                color: #0076D5; }
            .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags input,
            .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags input,
            .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags input {
              border: none;
              font-size: 16px;
              background-color: transparent;
              max-height: 33px; }
              .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags input:active,
              .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags input:active,
              .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags input:active {
                border: none; }
              .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags input:focus-visible,
              .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags input:focus-visible,
              .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags input:focus-visible {
                border: none;
                outline: none; }
          .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion,
          .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion,
          .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            max-width: 450px;
            margin-top: 10px; }
            .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper,
            .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper,
            .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper {
              display: flex;
              background-color: #ffffff;
              margin: 0.15em;
              color: #525354;
              border-radius: 8px;
              background-color: #e2e2e2;
              align-items: center;
              height: 32px; }
              .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .hashtag,
              .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .hashtag,
              .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .hashtag {
                font-family: CiscoSansTT;
                font-size: 13px;
                padding: 0.05em 0.75em;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer; }
              .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .icon-fk,
              .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .icon-fk,
              .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .icon-fk {
                cursor: pointer;
                display: none; }
              .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image,
              .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image,
              .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image {
                cursor: pointer; }
                .impact-stories .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image.icon-fk-add,
                .impact-stories-stage .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image.icon-fk-add,
                .impact-stories-app .full-upload-container .add-post-container .add-story-container .hashtag-container .hashtags-suggestion .hashtag-wrapper .image.icon-fk-add {
                  background-image: url(./../assets/impact21/images/icon-add.svg);
                  width: 20px;
                  height: 20px;
                  background-repeat: no-repeat;
                  background-size: contain;
                  margin-right: 5px; }
    .impact-stories .full-upload-container .action-container,
    .impact-stories-stage .full-upload-container .action-container,
    .impact-stories-app .full-upload-container .action-container {
      flex-direction: row;
      margin: 0%;
      justify-content: space-between;
      background-color: transparent;
      background-image: none;
      width: 100%;
      max-width: 380px; }
      .impact-stories .full-upload-container .action-container .back,
      .impact-stories-stage .full-upload-container .action-container .back,
      .impact-stories-app .full-upload-container .action-container .back {
        width: 100px;
        margin-right: 20px;
        margin-top: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 36px;
        font-size: 1em;
        line-height: 1.33;
        font-family: CiscoSansTT;
        color: #0076d5;
        border: 1px solid #0076d5;
        border-radius: 24px;
        cursor: pointer; }
        .impact-stories .full-upload-container .action-container .back .icon-fk,
        .impact-stories-stage .full-upload-container .action-container .back .icon-fk,
        .impact-stories-app .full-upload-container .action-container .back .icon-fk {
          display: none; }
        .impact-stories .full-upload-container .action-container .back .image.back-icon-fk,
        .impact-stories-stage .full-upload-container .action-container .back .image.back-icon-fk,
        .impact-stories-app .full-upload-container .action-container .back .image.back-icon-fk {
          background: url(./../assets/impact21/images/back-icon.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 20px;
          height: 14px; }
        .impact-stories .full-upload-container .action-container .back .text,
        .impact-stories-stage .full-upload-container .action-container .back .text,
        .impact-stories-app .full-upload-container .action-container .back .text {
          margin-top: 3px;
          margin-left: 3px; }
      .impact-stories .full-upload-container .action-container .continue,
      .impact-stories-stage .full-upload-container .action-container .continue,
      .impact-stories-app .full-upload-container .action-container .continue {
        flex: 1;
        max-width: 256px;
        margin-top: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0076d5;
        min-height: 40px;
        margin-left: auto;
        margin-right: auto;
        font-size: 1em;
        line-height: 1.33;
        font-family: CiscoSansTT;
        color: #fff;
        border-radius: 24px;
        cursor: pointer; }
        .impact-stories .full-upload-container .action-container .continue .text,
        .impact-stories-stage .full-upload-container .action-container .continue .text,
        .impact-stories-app .full-upload-container .action-container .continue .text {
          padding-top: 2px; }
  .impact-stories .upload-post-review-container,
  .impact-stories-stage .upload-post-review-container,
  .impact-stories-app .upload-post-review-container {
    z-index: 2;
    color: #0d274d;
    display: flex;
    flex-direction: column;
    max-width: 380px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
    background-color: #fff;
    padding: 16px 15px 19px 15px; }
    .impact-stories .upload-post-review-container .heading,
    .impact-stories-stage .upload-post-review-container .heading,
    .impact-stories-app .upload-post-review-container .heading {
      text-align: center;
      font-size: 1em;
      font-family: CiscoSansTTBold;
      line-height: 1.5; }
    .impact-stories .upload-post-review-container .upload-preview,
    .impact-stories-stage .upload-post-review-container .upload-preview,
    .impact-stories-app .upload-post-review-container .upload-preview {
      position: relative;
      width: 100%;
      height: 100%;
      max-height: 600px; }
      .impact-stories .upload-post-review-container .upload-preview .prev,
      .impact-stories-stage .upload-post-review-container .upload-preview .prev,
      .impact-stories-app .upload-post-review-container .upload-preview .prev {
        display: none;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        transform: translate(0, -50%);
        left: 10px;
        z-index: 5;
        background-color: #2e2e2ecc;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        cursor: pointer; }
        .impact-stories .upload-post-review-container .upload-preview .prev.show-arrow,
        .impact-stories-stage .upload-post-review-container .upload-preview .prev.show-arrow,
        .impact-stories-app .upload-post-review-container .upload-preview .prev.show-arrow {
          display: flex; }
        .impact-stories .upload-post-review-container .upload-preview .prev .prev-icon,
        .impact-stories-stage .upload-post-review-container .upload-preview .prev .prev-icon,
        .impact-stories-app .upload-post-review-container .upload-preview .prev .prev-icon {
          border: none;
          box-shadow: none;
          z-index: 10;
          display: block;
          width: 9px;
          height: 15px; }
      .impact-stories .upload-post-review-container .upload-preview .next,
      .impact-stories-stage .upload-post-review-container .upload-preview .next,
      .impact-stories-app .upload-post-review-container .upload-preview .next {
        display: none;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        transform: translate(0, -50%);
        right: 10px;
        z-index: 5;
        background-color: #2e2e2ecc;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        cursor: pointer; }
        .impact-stories .upload-post-review-container .upload-preview .next.show-arrow,
        .impact-stories-stage .upload-post-review-container .upload-preview .next.show-arrow,
        .impact-stories-app .upload-post-review-container .upload-preview .next.show-arrow {
          display: flex; }
        .impact-stories .upload-post-review-container .upload-preview .next .next-icon,
        .impact-stories-stage .upload-post-review-container .upload-preview .next .next-icon,
        .impact-stories-app .upload-post-review-container .upload-preview .next .next-icon {
          border: none;
          box-shadow: none;
          z-index: 10;
          display: block;
          width: 9px;
          height: 15px; }
      .impact-stories .upload-post-review-container .upload-preview img,
      .impact-stories-stage .upload-post-review-container .upload-preview img,
      .impact-stories-app .upload-post-review-container .upload-preview img {
        width: 100%;
        height: 100%;
        border: solid 4px #f4f4f4;
        box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.13);
        border-radius: 10px; }
    .impact-stories .upload-post-review-container .story,
    .impact-stories-stage .upload-post-review-container .story,
    .impact-stories-app .upload-post-review-container .story {
      margin-top: 30px;
      min-height: 50px;
      max-height: 160px;
      font-size: 0.75em;
      font-family: CiscoSansTT;
      line-height: 1.33;
      word-break: break-word;
      word-wrap: break-word;
      color: #000000; }
    .impact-stories .upload-post-review-container .hashtags,
    .impact-stories-stage .upload-post-review-container .hashtags,
    .impact-stories-app .upload-post-review-container .hashtags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px; }
      .impact-stories .upload-post-review-container .hashtags .hashtag-wrapper,
      .impact-stories-stage .upload-post-review-container .hashtags .hashtag-wrapper,
      .impact-stories-app .upload-post-review-container .hashtags .hashtag-wrapper {
        margin: 0.25em;
        font-size: 0.75em;
        font-family: CiscoSansTTBold;
        line-height: 1.33; }
        .impact-stories .upload-post-review-container .hashtags .hashtag-wrapper .hashtag,
        .impact-stories-stage .upload-post-review-container .hashtags .hashtag-wrapper .hashtag,
        .impact-stories-app .upload-post-review-container .hashtags .hashtag-wrapper .hashtag {
          font-weight: bold;
          color: #0076d5; }
    .impact-stories .upload-post-review-container .user-info,
    .impact-stories-stage .upload-post-review-container .user-info,
    .impact-stories-app .upload-post-review-container .user-info {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
      color: #0076D5; }
      .impact-stories .upload-post-review-container .user-info .user-image,
      .impact-stories-stage .upload-post-review-container .user-info .user-image,
      .impact-stories-app .upload-post-review-container .user-info .user-image {
        height: 41px;
        width: 41px;
        border-radius: 50%;
        border: 1px solid #1485ee;
        display: flex;
        align-items: center;
        justify-content: center; }
      .impact-stories .upload-post-review-container .user-info img,
      .impact-stories-stage .upload-post-review-container .user-info img,
      .impact-stories-app .upload-post-review-container .user-info img {
        width: 36px;
        height: 36px;
        border-radius: 50%; }
      .impact-stories .upload-post-review-container .user-info .username,
      .impact-stories-stage .upload-post-review-container .user-info .username,
      .impact-stories-app .upload-post-review-container .user-info .username {
        margin-left: 14px;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 2;
        font-family: CiscoSansTT; }
        .impact-stories .upload-post-review-container .user-info .username span,
        .impact-stories-stage .upload-post-review-container .user-info .username span,
        .impact-stories-app .upload-post-review-container .user-info .username span {
          padding: 0.15em; }
        .impact-stories .upload-post-review-container .user-info .username .firstname,
        .impact-stories-stage .upload-post-review-container .user-info .username .firstname,
        .impact-stories-app .upload-post-review-container .user-info .username .firstname {
          text-transform: capitalize; }
        .impact-stories .upload-post-review-container .user-info .username .lastname,
        .impact-stories-stage .upload-post-review-container .user-info .username .lastname,
        .impact-stories-app .upload-post-review-container .user-info .username .lastname {
          text-transform: capitalize;
          overflow: hidden;
          width: 120px;
          text-overflow: ellipsis; }
  .impact-stories .upload-download-container,
  .impact-stories-stage .upload-download-container,
  .impact-stories-app .upload-download-container {
    color: #0d274d;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 914px;
    width: 90%; }
    .impact-stories .upload-download-container .upload-download-preview,
    .impact-stories-stage .upload-download-container .upload-download-preview,
    .impact-stories-app .upload-download-container .upload-download-preview {
      width: 100%;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 22px;
      background-color: #fff;
      box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.23);
      padding: 40px 16px; }
      .impact-stories .upload-download-container .upload-download-preview .download-video-hashtag,
      .impact-stories-stage .upload-download-container .upload-download-preview .download-video-hashtag,
      .impact-stories-app .upload-download-container .upload-download-preview .download-video-hashtag {
        font-size: 32px;
        font-family: CiscoSansTTLight;
        color: #1a3d69;
        margin-bottom: 8px; }
      .impact-stories .upload-download-container .upload-download-preview .thank-you,
      .impact-stories-stage .upload-download-container .upload-download-preview .thank-you,
      .impact-stories-app .upload-download-container .upload-download-preview .thank-you {
        font-size: 16px;
        color: #1a3d69;
        margin-bottom: 9px;
        font-family: CiscoSans;
        height: 40px;
        display: flex;
        align-items: center; }
      .impact-stories .upload-download-container .upload-download-preview .abc,
      .impact-stories-stage .upload-download-container .upload-download-preview .abc,
      .impact-stories-app .upload-download-container .upload-download-preview .abc {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-gap: 20px;
        margin-bottom: 30px; }
      .impact-stories .upload-download-container .upload-download-preview .moreThan1 img,
      .impact-stories-stage .upload-download-container .upload-download-preview .moreThan1 img,
      .impact-stories-app .upload-download-container .upload-download-preview .moreThan1 img {
        border: 2px solid #f4f4f4; }
      .impact-stories .upload-download-container .upload-download-preview a,
      .impact-stories-stage .upload-download-container .upload-download-preview a,
      .impact-stories-app .upload-download-container .upload-download-preview a {
        color: white;
        text-decoration: none; }
        .impact-stories .upload-download-container .upload-download-preview a:active,
        .impact-stories-stage .upload-download-container .upload-download-preview a:active,
        .impact-stories-app .upload-download-container .upload-download-preview a:active {
          color: white; }
        .impact-stories .upload-download-container .upload-download-preview a:visited,
        .impact-stories-stage .upload-download-container .upload-download-preview a:visited,
        .impact-stories-app .upload-download-container .upload-download-preview a:visited {
          color: white; }
      .impact-stories .upload-download-container .upload-download-preview img,
      .impact-stories-stage .upload-download-container .upload-download-preview img,
      .impact-stories-app .upload-download-container .upload-download-preview img {
        width: 100%;
        max-width: 370px;
        border: 4px solid #f4f4f4;
        border-radius: 18px;
        box-sizing: border-box; }
      .impact-stories .upload-download-container .upload-download-preview .filename,
      .impact-stories-stage .upload-download-container .upload-download-preview .filename,
      .impact-stories-app .upload-download-container .upload-download-preview .filename {
        flex-grow: 1;
        font-family: CiscoSansTTBold;
        font-size: 1em; }
      .impact-stories .upload-download-container .upload-download-preview .download,
      .impact-stories-stage .upload-download-container .upload-download-preview .download,
      .impact-stories-app .upload-download-container .upload-download-preview .download {
        width: 174px;
        display: flex;
        min-height: 40px;
        font-size: 1em;
        font-family: CiscoSansTT;
        color: #fff;
        background-color: #0076d5;
        border-radius: 50px;
        cursor: pointer; }
        .impact-stories .upload-download-container .upload-download-preview .download a,
        .impact-stories-stage .upload-download-container .upload-download-preview .download a,
        .impact-stories-app .upload-download-container .upload-download-preview .download a {
          color: white;
          text-decoration: none; }
          .impact-stories .upload-download-container .upload-download-preview .download a:active,
          .impact-stories-stage .upload-download-container .upload-download-preview .download a:active,
          .impact-stories-app .upload-download-container .upload-download-preview .download a:active {
            color: white; }
          .impact-stories .upload-download-container .upload-download-preview .download a:visited,
          .impact-stories-stage .upload-download-container .upload-download-preview .download a:visited,
          .impact-stories-app .upload-download-container .upload-download-preview .download a:visited {
            color: white; }
        .impact-stories .upload-download-container .upload-download-preview .download .icon-fk,
        .impact-stories-stage .upload-download-container .upload-download-preview .download .icon-fk,
        .impact-stories-app .upload-download-container .upload-download-preview .download .icon-fk {
          display: none; }
        .impact-stories .upload-download-container .upload-download-preview .download .dwnld-img,
        .impact-stories-stage .upload-download-container .upload-download-preview .download .dwnld-img,
        .impact-stories-app .upload-download-container .upload-download-preview .download .dwnld-img {
          display: flex;
          align-items: center;
          padding: 0 18px;
          justify-content: space-between;
          width: 100%; }
        .impact-stories .upload-download-container .upload-download-preview .download .download-icon-bg .image.icdownload,
        .impact-stories-stage .upload-download-container .upload-download-preview .download .download-icon-bg .image.icdownload,
        .impact-stories-app .upload-download-container .upload-download-preview .download .download-icon-bg .image.icdownload {
          background-image: url(./../assets/impact21/images/download-icon.png);
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: contain; }
        .impact-stories .upload-download-container .upload-download-preview .download p,
        .impact-stories-stage .upload-download-container .upload-download-preview .download p,
        .impact-stories-app .upload-download-container .upload-download-preview .download p {
          font-size: 14px; }
    .impact-stories .upload-download-container .action,
    .impact-stories-stage .upload-download-container .action,
    .impact-stories-app .upload-download-container .action {
      display: flex;
      flex-wrap: wrap;
      margin: 0%;
      justify-content: center;
      background-color: transparent;
      background-image: none;
      gap: 20px;
      max-width: 420px; }
      .impact-stories .upload-download-container .action .close,
      .impact-stories-stage .upload-download-container .action .close,
      .impact-stories-app .upload-download-container .action .close {
        background-color: #f5f5f5;
        color: #0076d5;
        width: 190px;
        min-width: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #0076d5;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        line-height: 1.33;
        font-family: CiscoSansTT;
        border-radius: 50px;
        cursor: pointer;
        padding-top: 3px; }
      .impact-stories .upload-download-container .action .redo,
      .impact-stories-stage .upload-download-container .action .redo,
      .impact-stories-app .upload-download-container .action .redo {
        width: 204px;
        min-width: 204px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0076d5;
        background-color: #0076d5;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        color: #fff;
        line-height: 1.33;
        font-family: CiscoSansTT;
        border-radius: 50px;
        cursor: pointer;
        padding-top: 2px; }

.cisco-impact .external-link,
.cisco-impact-stage .external-link,
.cisco-impact-app .external-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0076d5;
  width: 230px;
  min-height: 40px;
  font-family: CicsoSansTT;
  color: #ffffff;
  border-radius: 24px;
  margin: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer; }
  .cisco-impact .external-link.disabled,
  .cisco-impact-stage .external-link.disabled,
  .cisco-impact-app .external-link.disabled {
    cursor: not-allowed; }

@media (min-width: 320px) and (max-width: 480px) {
  .ciscoliveus2023 {
    max-height: 100vh;
    background-color: #13284c; }
    .ciscoliveus2023 .lounge-container {
      padding: 23px 12px; }
      .ciscoliveus2023 .lounge-container .lounge-header .title {
        font-size: 3em;
        text-align: center;
        line-height: 1;
        margin-left: 10px; }
      .ciscoliveus2023 .lounge-container .lounge-content {
        height: 100vh; }
        .ciscoliveus2023 .lounge-container .lounge-content .lounge-description {
          width: 100%;
          margin-left: 10px; }
          .ciscoliveus2023 .lounge-container .lounge-content .lounge-description .description {
            line-height: 1;
            padding: 10px 0px;
            text-align: center; }
        .ciscoliveus2023 .lounge-container .lounge-content .interactive-section {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          overflow-y: auto; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .one0 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .two1 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .three2 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .four3 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button {
            width: 175px;
            height: 175px;
            position: static;
            margin: 15px 15px; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.three {
              margin-bottom: 10%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.four {
              margin-bottom: 10%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
              width: 55px;
              height: 74px;
              margin-bottom: 5px; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-description .interaction-title {
              font-size: 1em;
              line-height: 1; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.one .interaction-title {
              width: 150px; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.two .interaction-title {
              width: 100%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.three .interaction-title {
              width: 150px; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.four .interaction-title {
              width: 150px; }
    .ciscoliveus2023 .new-highlights-container-scroll,
    .ciscoliveus2023 .gallery-scroll-parent-container,
    .ciscoliveus2023 .leaderboard-container {
      height: 100vh;
      max-height: 100vh; }
      .ciscoliveus2023 .new-highlights-container-scroll .cards .gallery-card,
      .ciscoliveus2023 .new-highlights-container-scroll .gallery-scroll-cards .gallery-card,
      .ciscoliveus2023 .gallery-scroll-parent-container .cards .gallery-card,
      .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card,
      .ciscoliveus2023 .leaderboard-container .cards .gallery-card,
      .ciscoliveus2023 .leaderboard-container .gallery-scroll-cards .gallery-card {
        width: 300px;
        height: 300px; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .gallery-card .gallery-src.src-fankave,
        .ciscoliveus2023 .new-highlights-container-scroll .gallery-scroll-cards .gallery-card .gallery-src.src-fankave,
        .ciscoliveus2023 .gallery-scroll-parent-container .cards .gallery-card .gallery-src.src-fankave,
        .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card .gallery-src.src-fankave,
        .ciscoliveus2023 .leaderboard-container .cards .gallery-card .gallery-src.src-fankave,
        .ciscoliveus2023 .leaderboard-container .gallery-scroll-cards .gallery-card .gallery-src.src-fankave {
          width: 40px !important;
          height: 40px !important;
          top: 10px;
          left: 255px !important;
          background-color: #ffffff;
          border-radius: 50px; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .gallery-card .gallery-src.src-fankave img,
          .ciscoliveus2023 .new-highlights-container-scroll .gallery-scroll-cards .gallery-card .gallery-src.src-fankave img,
          .ciscoliveus2023 .gallery-scroll-parent-container .cards .gallery-card .gallery-src.src-fankave img,
          .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card .gallery-src.src-fankave img,
          .ciscoliveus2023 .leaderboard-container .cards .gallery-card .gallery-src.src-fankave img,
          .ciscoliveus2023 .leaderboard-container .gallery-scroll-cards .gallery-card .gallery-src.src-fankave img {
            margin-top: 8px;
            margin-left: 8px; }
      .ciscoliveus2023 .new-highlights-container-scroll .cards,
      .ciscoliveus2023 .gallery-scroll-parent-container .cards,
      .ciscoliveus2023 .leaderboard-container .cards {
        height: 100vh; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2,
        .ciscoliveus2023 .gallery-scroll-parent-container .cards .cardv2,
        .ciscoliveus2023 .leaderboard-container .cards .cardv2 {
          width: 300px; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .details,
          .ciscoliveus2023 .gallery-scroll-parent-container .cards .cardv2 .details,
          .ciscoliveus2023 .leaderboard-container .cards .cardv2 .details {
            max-width: 300px; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .stats,
          .ciscoliveus2023 .gallery-scroll-parent-container .cards .cardv2 .stats,
          .ciscoliveus2023 .leaderboard-container .cards .cardv2 .stats {
            width: 275px; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .image,
          .ciscoliveus2023 .gallery-scroll-parent-container .cards .cardv2 .image,
          .ciscoliveus2023 .leaderboard-container .cards .cardv2 .image {
            max-height: 300px;
            height: 175px;
            width: 300px; }
            .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .image .src-twitter,
            .ciscoliveus2023 .gallery-scroll-parent-container .cards .cardv2 .image .src-twitter,
            .ciscoliveus2023 .leaderboard-container .cards .cardv2 .image .src-twitter {
              font-size: 1.25em; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2 .source,
          .ciscoliveus2023 .gallery-scroll-parent-container .cards .cardv2 .source,
          .ciscoliveus2023 .leaderboard-container .cards .cardv2 .source {
            left: 260px; }
          .ciscoliveus2023 .new-highlights-container-scroll .cards .cardv2.portrait .image,
          .ciscoliveus2023 .gallery-scroll-parent-container .cards .cardv2.portrait .image,
          .ciscoliveus2023 .leaderboard-container .cards .cardv2.portrait .image {
            width: 300px; }
        .ciscoliveus2023 .new-highlights-container-scroll .cards .text-feed,
        .ciscoliveus2023 .gallery-scroll-parent-container .cards .text-feed,
        .ciscoliveus2023 .leaderboard-container .cards .text-feed {
          width: 300px; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .back .icon-fk,
      .ciscoliveus2023 .gallery-scroll-parent-container .header .back .icon-fk,
      .ciscoliveus2023 .leaderboard-container .header .back .icon-fk {
        font-size: 0.75em; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .back .back-label,
      .ciscoliveus2023 .gallery-scroll-parent-container .header .back .back-label,
      .ciscoliveus2023 .leaderboard-container .header .back .back-label {
        font-size: 0.75em; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .title,
      .ciscoliveus2023 .gallery-scroll-parent-container .header .title,
      .ciscoliveus2023 .leaderboard-container .header .title {
        font-size: 1.5em; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .action,
      .ciscoliveus2023 .gallery-scroll-parent-container .header .action,
      .ciscoliveus2023 .leaderboard-container .header .action {
        font-size: 1em;
        margin-right: 5px;
        padding: 5px 15px 5px 15px;
        width: 62px; }
      .ciscoliveus2023 .new-highlights-container-scroll .leaderboard-content,
      .ciscoliveus2023 .gallery-scroll-parent-container .leaderboard-content,
      .ciscoliveus2023 .leaderboard-container .leaderboard-content {
        max-height: 100vh; }
        .ciscoliveus2023 .new-highlights-container-scroll .leaderboard-content .top,
        .ciscoliveus2023 .gallery-scroll-parent-container .leaderboard-content .top,
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .top {
          margin-top: 30%;
          padding-top: 60px;
          margin-bottom: 40px;
          padding-bottom: 60px;
          padding-left: 0px;
          padding-right: 0px;
          margin-left: 15%;
          margin-right: 15%; }
          .ciscoliveus2023 .new-highlights-container-scroll .leaderboard-content .top .post,
          .ciscoliveus2023 .gallery-scroll-parent-container .leaderboard-content .top .post,
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .top .post {
            margin-bottom: 25px; }
        .ciscoliveus2023 .new-highlights-container-scroll .leaderboard-content .rest .post,
        .ciscoliveus2023 .gallery-scroll-parent-container .leaderboard-content .rest .post,
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .rest .post {
          margin-bottom: 25px; }
        .ciscoliveus2023 .new-highlights-container-scroll .leaderboard-content .btn,
        .ciscoliveus2023 .gallery-scroll-parent-container .leaderboard-content .btn,
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .btn {
          width: 66%; }
          .ciscoliveus2023 .new-highlights-container-scroll .leaderboard-content .btn .cisco-btn,
          .ciscoliveus2023 .gallery-scroll-parent-container .leaderboard-content .btn .cisco-btn,
          .ciscoliveus2023 .leaderboard-container .leaderboard-content .btn .cisco-btn {
            padding-top: 10px;
            padding-bottom: 10px; }
    .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards {
      height: 100vh; }
    .ciscoliveus2023 .badge-container {
      background-color: #13284c;
      background-image: none; }
      .ciscoliveus2023 .badge-container .header .title {
        font-size: 2em;
        margin-top: 15px;
        margin-left: 70px;
        background-color: #13284c; }
  .ciscoliveapjc2023 {
    max-height: 760px;
    height: 760px;
    background-color: #13284c; }
    .ciscoliveapjc2023 .lounge-container {
      padding: 23px 12px; }
      .ciscoliveapjc2023 .lounge-container .lounge-header .title {
        font-size: 3em;
        text-align: center;
        line-height: 1;
        margin-left: 10px; }
      .ciscoliveapjc2023 .lounge-container .lounge-content {
        height: 100vh; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description {
          width: 100%;
          margin-left: 10px;
          margin-top: 5%; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description .description {
            line-height: 1;
            padding: 10px 0px;
            text-align: center; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          overflow-y: auto; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .one0 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .two1 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .three2 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .four3 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button {
            width: 175px;
            height: 175px;
            position: static;
            margin: 15px 15px; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.three {
              margin-bottom: 10%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.four {
              margin-bottom: 10%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
              width: 55px;
              height: 74px;
              margin-bottom: 5px; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-description .interaction-title {
              font-size: 1em;
              line-height: 1; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.one .interaction-title {
              width: 150px; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.two .interaction-title {
              width: 100%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.three .interaction-title {
              width: 150px; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.four .interaction-title {
              width: 150px; }
    .ciscoliveapjc2023 .new-highlights-container-scroll,
    .ciscoliveapjc2023 .gallery-scroll-parent-container,
    .ciscoliveapjc2023 .leaderboard-container {
      height: 100vh;
      max-height: 100vh; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .cards .gallery-card,
      .ciscoliveapjc2023 .new-highlights-container-scroll .gallery-scroll-cards .gallery-card,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .cards .gallery-card,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card,
      .ciscoliveapjc2023 .leaderboard-container .cards .gallery-card,
      .ciscoliveapjc2023 .leaderboard-container .gallery-scroll-cards .gallery-card {
        width: 300px;
        height: 300px; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .gallery-card .gallery-src.src-fankave,
        .ciscoliveapjc2023 .new-highlights-container-scroll .gallery-scroll-cards .gallery-card .gallery-src.src-fankave,
        .ciscoliveapjc2023 .gallery-scroll-parent-container .cards .gallery-card .gallery-src.src-fankave,
        .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card .gallery-src.src-fankave,
        .ciscoliveapjc2023 .leaderboard-container .cards .gallery-card .gallery-src.src-fankave,
        .ciscoliveapjc2023 .leaderboard-container .gallery-scroll-cards .gallery-card .gallery-src.src-fankave {
          width: 40px !important;
          height: 40px !important;
          top: 10px;
          left: 255px !important;
          background-color: #ffffff;
          border-radius: 50px; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .gallery-card .gallery-src.src-fankave img,
          .ciscoliveapjc2023 .new-highlights-container-scroll .gallery-scroll-cards .gallery-card .gallery-src.src-fankave img,
          .ciscoliveapjc2023 .gallery-scroll-parent-container .cards .gallery-card .gallery-src.src-fankave img,
          .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-cards .gallery-card .gallery-src.src-fankave img,
          .ciscoliveapjc2023 .leaderboard-container .cards .gallery-card .gallery-src.src-fankave img,
          .ciscoliveapjc2023 .leaderboard-container .gallery-scroll-cards .gallery-card .gallery-src.src-fankave img {
            margin-top: 8px;
            margin-left: 8px; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .cards,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .cards,
      .ciscoliveapjc2023 .leaderboard-container .cards {
        height: 100vh; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2,
        .ciscoliveapjc2023 .gallery-scroll-parent-container .cards .cardv2,
        .ciscoliveapjc2023 .leaderboard-container .cards .cardv2 {
          width: 300px; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .details,
          .ciscoliveapjc2023 .gallery-scroll-parent-container .cards .cardv2 .details,
          .ciscoliveapjc2023 .leaderboard-container .cards .cardv2 .details {
            max-width: 300px; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .stats,
          .ciscoliveapjc2023 .gallery-scroll-parent-container .cards .cardv2 .stats,
          .ciscoliveapjc2023 .leaderboard-container .cards .cardv2 .stats {
            width: 275px; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .image,
          .ciscoliveapjc2023 .gallery-scroll-parent-container .cards .cardv2 .image,
          .ciscoliveapjc2023 .leaderboard-container .cards .cardv2 .image {
            max-height: 300px;
            height: 175px;
            width: 300px; }
            .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .image .src-twitter,
            .ciscoliveapjc2023 .gallery-scroll-parent-container .cards .cardv2 .image .src-twitter,
            .ciscoliveapjc2023 .leaderboard-container .cards .cardv2 .image .src-twitter {
              font-size: 1.25em; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2 .source,
          .ciscoliveapjc2023 .gallery-scroll-parent-container .cards .cardv2 .source,
          .ciscoliveapjc2023 .leaderboard-container .cards .cardv2 .source {
            left: 260px; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .cards .cardv2.portrait .image,
          .ciscoliveapjc2023 .gallery-scroll-parent-container .cards .cardv2.portrait .image,
          .ciscoliveapjc2023 .leaderboard-container .cards .cardv2.portrait .image {
            width: 300px; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .cards .text-feed,
        .ciscoliveapjc2023 .gallery-scroll-parent-container .cards .text-feed,
        .ciscoliveapjc2023 .leaderboard-container .cards .text-feed {
          width: 300px; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .back .icon-fk,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .back .icon-fk,
      .ciscoliveapjc2023 .leaderboard-container .header .back .icon-fk {
        font-size: 0.75em; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .back .back-label,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .back .back-label,
      .ciscoliveapjc2023 .leaderboard-container .header .back .back-label {
        font-size: 0.75em; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .title,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .title,
      .ciscoliveapjc2023 .leaderboard-container .header .title {
        font-size: 1.5em; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .action,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .action,
      .ciscoliveapjc2023 .leaderboard-container .header .action {
        font-size: 1em;
        margin-right: 5px;
        padding: 5px 15px 5px 15px;
        width: 62px; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .leaderboard-content,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .leaderboard-content,
      .ciscoliveapjc2023 .leaderboard-container .leaderboard-content {
        max-height: 100vh; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .leaderboard-content .top,
        .ciscoliveapjc2023 .gallery-scroll-parent-container .leaderboard-content .top,
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top {
          margin-top: 30%;
          padding-top: 60px;
          margin-bottom: 40px;
          padding-bottom: 60px;
          padding-left: 0px;
          padding-right: 0px;
          margin-left: 15%;
          margin-right: 15%; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .leaderboard-content .top .post,
          .ciscoliveapjc2023 .gallery-scroll-parent-container .leaderboard-content .top .post,
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top .post {
            margin-bottom: 25px; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .leaderboard-content .rest .post,
        .ciscoliveapjc2023 .gallery-scroll-parent-container .leaderboard-content .rest .post,
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .rest .post {
          margin-bottom: 25px; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .leaderboard-content .btn,
        .ciscoliveapjc2023 .gallery-scroll-parent-container .leaderboard-content .btn,
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .btn {
          width: 66%; }
          .ciscoliveapjc2023 .new-highlights-container-scroll .leaderboard-content .btn .cisco-btn,
          .ciscoliveapjc2023 .gallery-scroll-parent-container .leaderboard-content .btn .cisco-btn,
          .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .btn .cisco-btn {
            padding-top: 10px;
            padding-bottom: 10px; }
    .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards {
      height: 100vh; }
    .ciscoliveapjc2023 .badge-container {
      background-color: #13284c;
      background-image: none; }
      .ciscoliveapjc2023 .badge-container .header .title {
        font-size: 2em;
        margin-top: 15px;
        margin-left: 70px;
        background-color: #13284c; }
  .cisco-partner-summit .carousel-container .previous {
    margin-top: 160px;
    margin-left: 10px; }
  .cisco-partner-summit .carousel-container .next {
    margin-top: 160px;
    margin-right: 10px; } }

@media (min-width: 480px) and (max-width: 768px) {
  .ciscoliveus2023 {
    max-height: 100vh;
    background-color: #13284c; }
    .ciscoliveus2023 .lounge-container {
      padding: 46px 24px; }
      .ciscoliveus2023 .lounge-container .lounge-header .title {
        text-align: center;
        margin-left: 10px; }
      .ciscoliveus2023 .lounge-container .lounge-content {
        height: 100vh; }
        .ciscoliveus2023 .lounge-container .lounge-content .lounge-description {
          width: 100%;
          margin-left: 10px; }
          .ciscoliveus2023 .lounge-container .lounge-content .lounge-description .description {
            padding: 10px 0px;
            text-align: center; }
        .ciscoliveus2023 .lounge-container .lounge-content .interactive-section {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          overflow-y: auto; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .one0 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .two1 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .three2 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .four3 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button {
            position: static;
            margin: 15px 15px; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.four {
              margin-bottom: 10%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.three {
              margin-bottom: 10%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
              width: 77px;
              height: 74px;
              margin-bottom: 25px; }
    .ciscoliveus2023 .new-highlights-container-scroll,
    .ciscoliveus2023 .gallery-scroll-parent-container,
    .ciscoliveus2023 .leaderboard-container {
      height: 100vh;
      max-height: 100vh; }
      .ciscoliveus2023 .new-highlights-container-scroll .cards,
      .ciscoliveus2023 .gallery-scroll-parent-container .cards,
      .ciscoliveus2023 .leaderboard-container .cards {
        height: 100vh; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .back .back-label,
      .ciscoliveus2023 .gallery-scroll-parent-container .header .back .back-label,
      .ciscoliveus2023 .leaderboard-container .header .back .back-label {
        font-size: 1em; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .title,
      .ciscoliveus2023 .gallery-scroll-parent-container .header .title,
      .ciscoliveus2023 .leaderboard-container .header .title {
        font-size: 2em; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .action,
      .ciscoliveus2023 .gallery-scroll-parent-container .header .action,
      .ciscoliveus2023 .leaderboard-container .header .action {
        font-size: 1em;
        margin-right: 5px;
        padding: 15px 20px 15px 20px; }
      .ciscoliveus2023 .new-highlights-container-scroll .leaderboard-content,
      .ciscoliveus2023 .gallery-scroll-parent-container .leaderboard-content,
      .ciscoliveus2023 .leaderboard-container .leaderboard-content {
        max-height: 100vh; }
        .ciscoliveus2023 .new-highlights-container-scroll .leaderboard-content .top,
        .ciscoliveus2023 .gallery-scroll-parent-container .leaderboard-content .top,
        .ciscoliveus2023 .leaderboard-container .leaderboard-content .top {
          margin-top: 20%;
          padding-top: 30px;
          margin-bottom: 40px;
          padding-bottom: 14px;
          padding-left: 0px;
          padding-right: 0px;
          margin-left: 15%;
          margin-right: 15%; }
    .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards {
      height: 88vh; }
  .ciscoliveapjc2023 {
    max-height: 760px;
    height: 760px;
    background-color: #13284c; }
    .ciscoliveapjc2023 .lounge-container {
      padding: 46px 24px; }
      .ciscoliveapjc2023 .lounge-container .lounge-header .title {
        text-align: center;
        margin-left: 10px; }
      .ciscoliveapjc2023 .lounge-container .lounge-content {
        max-height: 760px; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description {
          width: 100%;
          margin-left: 10px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description .description {
            padding: 10px 0px;
            text-align: center; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;
          overflow-y: auto; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .one0 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .two1 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .three2 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .four3 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button {
            position: static;
            margin: 15px 15px; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.four {
              margin-bottom: 10%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.three {
              margin-bottom: 10%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
              width: 77px;
              height: 74px;
              margin-bottom: 25px; }
    .ciscoliveapjc2023 .new-highlights-container-scroll,
    .ciscoliveapjc2023 .gallery-scroll-parent-container,
    .ciscoliveapjc2023 .leaderboard-container {
      height: 100vh;
      max-height: 100vh; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .cards,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .cards,
      .ciscoliveapjc2023 .leaderboard-container .cards {
        height: 100vh; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .back .back-label,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .back .back-label,
      .ciscoliveapjc2023 .leaderboard-container .header .back .back-label {
        font-size: 1em; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .title,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .title,
      .ciscoliveapjc2023 .leaderboard-container .header .title {
        font-size: 2em; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .action,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .action,
      .ciscoliveapjc2023 .leaderboard-container .header .action {
        font-size: 1em;
        margin-right: 5px;
        padding: 15px 20px 15px 20px; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .leaderboard-content,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .leaderboard-content,
      .ciscoliveapjc2023 .leaderboard-container .leaderboard-content {
        max-height: 100vh; }
        .ciscoliveapjc2023 .new-highlights-container-scroll .leaderboard-content .top,
        .ciscoliveapjc2023 .gallery-scroll-parent-container .leaderboard-content .top,
        .ciscoliveapjc2023 .leaderboard-container .leaderboard-content .top {
          margin-top: 20%;
          padding-top: 30px;
          margin-bottom: 40px;
          padding-bottom: 14px;
          padding-left: 0px;
          padding-right: 0px;
          margin-left: 15%;
          margin-right: 15%; }
    .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards {
      height: 88vh; }
  .cisco-partner-summit .carousel-container .previous {
    margin-top: 160px;
    margin-left: 10px; }
  .cisco-partner-summit .carousel-container .next {
    margin-top: 160px;
    margin-right: 10px; } }

@media (min-width: 768px) and (max-width: 1024px) {
  .ciscoliveus2023 {
    max-height: 100vh;
    background-color: #13284c; }
    .ciscoliveus2023 .lounge-container {
      padding: 46px 24px; }
      .ciscoliveus2023 .lounge-container .lounge-header .title {
        text-align: center;
        margin-left: 10px; }
      .ciscoliveus2023 .lounge-container .lounge-content {
        height: 100vh; }
        .ciscoliveus2023 .lounge-container .lounge-content .lounge-description {
          width: 100%;
          margin-left: 10px; }
          .ciscoliveus2023 .lounge-container .lounge-content .lounge-description .description {
            padding: 10px 0px;
            text-align: center; }
        .ciscoliveus2023 .lounge-container .lounge-content .interactive-section {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          overflow-y: auto;
          width: 100%; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .one0 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .two1 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .three2 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .four3 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button {
            position: static;
            margin: 15px 15px; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.four {
              margin-bottom: 5%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.three {
              margin-bottom: 5%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
              width: 77px;
              height: 74px;
              margin-bottom: 25px; }
    .ciscoliveus2023 .new-highlights-container-scroll .cards,
    .ciscoliveus2023 .new-highlights-container-scroll .gallery-scroll-cards,
    .ciscoliveus2023 .new-highlights-container-scroll .leaderboard-content,
    .ciscoliveus2023 .gallery-scroll-parent-container .cards,
    .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-cards,
    .ciscoliveus2023 .gallery-scroll-parent-container .leaderboard-content,
    .ciscoliveus2023 .leaderboard-container .cards,
    .ciscoliveus2023 .leaderboard-container .gallery-scroll-cards,
    .ciscoliveus2023 .leaderboard-container .leaderboard-content {
      max-height: 100vh;
      height: 100vh; }
    .ciscoliveus2023 .new-highlights-container-scroll .header .back .back-label,
    .ciscoliveus2023 .gallery-scroll-parent-container .header .back .back-label,
    .ciscoliveus2023 .leaderboard-container .header .back .back-label {
      font-size: 1em; }
    .ciscoliveus2023 .new-highlights-container-scroll .header .title,
    .ciscoliveus2023 .gallery-scroll-parent-container .header .title,
    .ciscoliveus2023 .leaderboard-container .header .title {
      font-size: 3em; }
    .ciscoliveus2023 .new-highlights-container-scroll .header .action,
    .ciscoliveus2023 .gallery-scroll-parent-container .header .action,
    .ciscoliveus2023 .leaderboard-container .header .action {
      font-size: 1.25em;
      margin-right: 5px;
      padding: 10px 15px 10px 15px; }
    .ciscoliveus2023 .new-highlights-container-scroll .cards,
    .ciscoliveus2023 .gallery-scroll-parent-container .cards,
    .ciscoliveus2023 .leaderboard-container .cards {
      height: 100vh; }
    .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards {
      height: 88vh; }
    .ciscoliveus2023 .new-highlights-container-scroll {
      max-height: 100vh; }
  .ciscoliveapjc2023 {
    max-height: 760px;
    height: 760px;
    background-color: #13284c; }
    .ciscoliveapjc2023 .lounge-container {
      padding: 46px 24px; }
      .ciscoliveapjc2023 .lounge-container .lounge-header .title {
        text-align: center;
        margin-left: 10px; }
      .ciscoliveapjc2023 .lounge-container .lounge-content {
        height: 100%; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description {
          width: 100%;
          margin-left: 10px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description .description {
            padding: 10px 0px;
            text-align: center; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          overflow-y: auto;
          width: 100%; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .one0 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .two1 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .three2 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .four3 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button {
            position: static;
            margin: 15px 15px; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.four {
              margin-bottom: 5%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.three {
              margin-bottom: 5%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
              width: 77px;
              height: 74px;
              margin-bottom: 25px; }
    .ciscoliveapjc2023 .new-highlights-container-scroll .cards,
    .ciscoliveapjc2023 .new-highlights-container-scroll .gallery-scroll-cards,
    .ciscoliveapjc2023 .new-highlights-container-scroll .leaderboard-content,
    .ciscoliveapjc2023 .gallery-scroll-parent-container .cards,
    .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-cards,
    .ciscoliveapjc2023 .gallery-scroll-parent-container .leaderboard-content,
    .ciscoliveapjc2023 .leaderboard-container .cards,
    .ciscoliveapjc2023 .leaderboard-container .gallery-scroll-cards,
    .ciscoliveapjc2023 .leaderboard-container .leaderboard-content {
      max-height: 100vh;
      height: 100vh; }
    .ciscoliveapjc2023 .new-highlights-container-scroll .header .back .back-label,
    .ciscoliveapjc2023 .gallery-scroll-parent-container .header .back .back-label,
    .ciscoliveapjc2023 .leaderboard-container .header .back .back-label {
      font-size: 1em; }
    .ciscoliveapjc2023 .new-highlights-container-scroll .header .title,
    .ciscoliveapjc2023 .gallery-scroll-parent-container .header .title,
    .ciscoliveapjc2023 .leaderboard-container .header .title {
      font-size: 3em; }
    .ciscoliveapjc2023 .new-highlights-container-scroll .header .action,
    .ciscoliveapjc2023 .gallery-scroll-parent-container .header .action,
    .ciscoliveapjc2023 .leaderboard-container .header .action {
      font-size: 1.25em;
      margin-right: 5px;
      padding: 10px 15px 10px 15px; }
    .ciscoliveapjc2023 .new-highlights-container-scroll .cards,
    .ciscoliveapjc2023 .gallery-scroll-parent-container .cards,
    .ciscoliveapjc2023 .leaderboard-container .cards {
      height: 100vh; }
    .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards {
      height: 88vh; }
    .ciscoliveapjc2023 .new-highlights-container-scroll {
      max-height: 100vh; }
  .cisco-partner-summit .carousel-container .previous {
    margin-top: 160px;
    margin-left: 10px; }
  .cisco-partner-summit .carousel-container .next {
    margin-top: 160px;
    margin-right: 10px; } }

@media (min-width: 1024px) and (max-width: 1200px) {
  .ciscoliveus2023 {
    background-color: #13284c;
    max-height: 100vh; }
    .ciscoliveus2023 .lounge-container {
      padding: 46px 24px; }
      .ciscoliveus2023 .lounge-container .lounge-header .title {
        text-align: center; }
      .ciscoliveus2023 .lounge-container .lounge-content {
        height: 100vh; }
        .ciscoliveus2023 .lounge-container .lounge-content .lounge-description {
          width: 100%;
          margin-left: 10px; }
          .ciscoliveus2023 .lounge-container .lounge-content .lounge-description .description {
            padding: 10px 0px;
            text-align: center; }
        .ciscoliveus2023 .lounge-container .lounge-content .interactive-section {
          margin-top: 5%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          overflow-y: auto; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .one0 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .two1 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .three2 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .four3 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button {
            position: static;
            margin: 15px 15px; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.four {
              margin-bottom: 5%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
              width: 77px;
              height: 74px;
              margin-bottom: 25px; }
    .ciscoliveus2023 .new-highlights-container-scroll .cards,
    .ciscoliveus2023 .new-highlights-container-scroll .gallery-scroll-cards,
    .ciscoliveus2023 .new-highlights-container-scroll .leaderboard-content,
    .ciscoliveus2023 .gallery-scroll-parent-container .cards,
    .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-cards,
    .ciscoliveus2023 .gallery-scroll-parent-container .leaderboard-content,
    .ciscoliveus2023 .leaderboard-container .cards,
    .ciscoliveus2023 .leaderboard-container .gallery-scroll-cards,
    .ciscoliveus2023 .leaderboard-container .leaderboard-content {
      max-height: 100vh;
      height: 100vh; }
    .ciscoliveus2023 .new-highlights-container-scroll {
      max-height: 100vh; }
    .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards {
      height: 100vh; }
  .ciscoliveapjc2023 {
    background-color: #13284c;
    max-height: 760px; }
    .ciscoliveapjc2023 .lounge-container {
      padding: 46px 24px; }
      .ciscoliveapjc2023 .lounge-container .lounge-header .title {
        text-align: center; }
      .ciscoliveapjc2023 .lounge-container .lounge-content {
        height: 100vh; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description {
          width: 100%;
          margin-left: 10px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description .description {
            padding: 10px 0px;
            text-align: center; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section {
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          overflow-y: auto; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .one0 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .two1 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .three2 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .four3 {
            width: 450px;
            height: 210px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button {
            position: static;
            margin: 15px 15px; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.four {
              margin-bottom: 5%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
              width: 77px;
              height: 74px;
              margin-bottom: 25px; }
    .ciscoliveapjc2023 .new-highlights-container-scroll .cards,
    .ciscoliveapjc2023 .new-highlights-container-scroll .gallery-scroll-cards,
    .ciscoliveapjc2023 .new-highlights-container-scroll .leaderboard-content,
    .ciscoliveapjc2023 .gallery-scroll-parent-container .cards,
    .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-cards,
    .ciscoliveapjc2023 .gallery-scroll-parent-container .leaderboard-content,
    .ciscoliveapjc2023 .leaderboard-container .cards,
    .ciscoliveapjc2023 .leaderboard-container .gallery-scroll-cards,
    .ciscoliveapjc2023 .leaderboard-container .leaderboard-content {
      max-height: 100vh;
      height: 100vh; }
    .ciscoliveapjc2023 .new-highlights-container-scroll {
      max-height: 100vh; }
    .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards {
      height: 100vh; }
  .cisco-partner-summit .carousel-container .previous {
    margin-top: 160px;
    margin-left: 10px; }
  .cisco-partner-summit .carousel-container .next {
    margin-top: 160px;
    margin-right: 10px; } }

@media (max-width: 768px) {
  .impact-stories .action-container .top-left-logo,
  .impact-stories-stage .action-container .top-left-logo,
  .impact-stories-app .action-container .top-left-logo {
    height: 96px;
    background-image: url(./../assets/impact21/images/top-ribbon-image-mobile.png); }
    .impact-stories .action-container .top-left-logo .logo,
    .impact-stories-stage .action-container .top-left-logo .logo,
    .impact-stories-app .action-container .top-left-logo .logo {
      margin-left: 38px; }
      .impact-stories .action-container .top-left-logo .logo img,
      .impact-stories-stage .action-container .top-left-logo .logo img,
      .impact-stories-app .action-container .top-left-logo .logo img {
        width: 100px; }
  .impact-stories .action-container .title,
  .impact-stories-stage .action-container .title,
  .impact-stories-app .action-container .title {
    margin: 23px auto 0 auto;
    font-size: 32px;
    line-height: 40px; }
  .impact-stories .action-container .tagline,
  .impact-stories-stage .action-container .tagline,
  .impact-stories-app .action-container .tagline {
    margin: 0 auto 15px auto;
    line-height: 40px; }
  .impact-stories .action-container .action-items,
  .impact-stories-stage .action-container .action-items,
  .impact-stories-app .action-container .action-items {
    margin: auto 15px;
    grid-gap: 15px; }
    .impact-stories .action-container .action-items .action-card,
    .impact-stories-stage .action-container .action-items .action-card,
    .impact-stories-app .action-container .action-items .action-card {
      width: 100%;
      min-height: 230px;
      padding: 25px 14px 21px 36px; }
      .impact-stories .action-container .action-items .action-card .action-pic,
      .impact-stories-stage .action-container .action-items .action-card .action-pic,
      .impact-stories-app .action-container .action-items .action-card .action-pic {
        height: 35px; }
        .impact-stories .action-container .action-items .action-card .action-pic .image.camera,
        .impact-stories-stage .action-container .action-items .action-card .action-pic .image.camera,
        .impact-stories-app .action-container .action-items .action-card .action-pic .image.camera {
          width: 47.8px;
          height: 35.4px; }
        .impact-stories .action-container .action-items .action-card .action-pic .image.video,
        .impact-stories-stage .action-container .action-items .action-card .action-pic .image.video,
        .impact-stories-app .action-container .action-items .action-card .action-pic .image.video {
          width: 50.6px;
          height: 28px; }
        .impact-stories .action-container .action-items .action-card .action-pic .image.upload,
        .impact-stories-stage .action-container .action-items .action-card .action-pic .image.upload,
        .impact-stories-app .action-container .action-items .action-card .action-pic .image.upload {
          width: 49.8px;
          height: 33.1px; }
        .impact-stories .action-container .action-items .action-card .action-pic .image.twitter,
        .impact-stories-stage .action-container .action-items .action-card .action-pic .image.twitter,
        .impact-stories-app .action-container .action-items .action-card .action-pic .image.twitter {
          width: 40px;
          height: 32.5px; }
      .impact-stories .action-container .action-items .action-card .action-heading,
      .impact-stories-stage .action-container .action-items .action-card .action-heading,
      .impact-stories-app .action-container .action-items .action-card .action-heading {
        line-height: 1.43;
        min-height: 47px; }
  .impact-stories .fk-container .top-left-logo,
  .impact-stories-stage .fk-container .top-left-logo,
  .impact-stories-app .fk-container .top-left-logo {
    height: 96px;
    background-image: url(./../assets/impact21/images/top-ribbon-image-mobile.png); }
    .impact-stories .fk-container .top-left-logo .logo,
    .impact-stories-stage .fk-container .top-left-logo .logo,
    .impact-stories-app .fk-container .top-left-logo .logo {
      margin-left: 38px; }
      .impact-stories .fk-container .top-left-logo .logo img,
      .impact-stories-stage .fk-container .top-left-logo .logo img,
      .impact-stories-app .fk-container .top-left-logo .logo img {
        width: 100px; }
  .impact-stories .fk-container .cancel-btn-desktop,
  .impact-stories-stage .fk-container .cancel-btn-desktop,
  .impact-stories-app .fk-container .cancel-btn-desktop {
    display: none; }
  .impact-stories .fk-container .cancel-btn-mobile,
  .impact-stories-stage .fk-container .cancel-btn-mobile,
  .impact-stories-app .fk-container .cancel-btn-mobile {
    display: flex; }
  .impact-stories .fk-container.image-review .heading-out,
  .impact-stories-stage .fk-container.image-review .heading-out,
  .impact-stories-app .fk-container.image-review .heading-out {
    margin-top: 20px;
    font-size: 20px; }
  .impact-stories .fk-container.image-review .image-post-preview-container,
  .impact-stories-stage .fk-container.image-review .image-post-preview-container,
  .impact-stories-app .fk-container.image-review .image-post-preview-container {
    margin-top: 20px; }
  .impact-stories .fk-container.video-review .heading-out,
  .impact-stories-stage .fk-container.video-review .heading-out,
  .impact-stories-app .fk-container.video-review .heading-out {
    font-size: 20px;
    padding-top: 13px;
    height: 40px;
    display: flex;
    align-items: center; }
  .impact-stories .fk-container.upload-review .heading-out,
  .impact-stories-stage .fk-container.upload-review .heading-out,
  .impact-stories-app .fk-container.upload-review .heading-out {
    margin-top: 20px;
    font-size: 20px; }
  .impact-stories .fk-container.upload-review .upload-post-review-container,
  .impact-stories-stage .fk-container.upload-review .upload-post-review-container,
  .impact-stories-app .fk-container.upload-review .upload-post-review-container {
    margin-top: 20px; }
  .impact-stories .fk-container.upload-download .title,
  .impact-stories-stage .fk-container.upload-download .title,
  .impact-stories-app .fk-container.upload-download .title {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .impact-stories .fk-container.upload-download .tagline,
  .impact-stories-stage .fk-container.upload-download .tagline,
  .impact-stories-app .fk-container.upload-download .tagline {
    margin-left: auto;
    margin-right: auto; }
  .impact-stories .fk-container.video-download .title,
  .impact-stories-stage .fk-container.video-download .title,
  .impact-stories-app .fk-container.video-download .title {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .impact-stories .fk-container.video-download .tagline,
  .impact-stories-stage .fk-container.video-download .tagline,
  .impact-stories-app .fk-container.video-download .tagline {
    margin-left: auto;
    margin-right: auto; }
  .impact-stories .fk-container.image-download .title,
  .impact-stories-stage .fk-container.image-download .title,
  .impact-stories-app .fk-container.image-download .title {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .impact-stories .fk-container.image-download .tagline,
  .impact-stories-stage .fk-container.image-download .tagline,
  .impact-stories-app .fk-container.image-download .tagline {
    margin-left: auto;
    margin-right: auto; }
  .impact-stories .image-capture-container,
  .impact-stories-stage .image-capture-container,
  .impact-stories-app .image-capture-container {
    margin-top: 23px;
    padding: 35px 0 25px 0;
    min-height: unset; }
    .impact-stories .image-capture-container .capture-action,
    .impact-stories-stage .image-capture-container .capture-action,
    .impact-stories-app .image-capture-container .capture-action {
      width: 95%; }
    .impact-stories .image-capture-container .heading,
    .impact-stories-stage .image-capture-container .heading,
    .impact-stories-app .image-capture-container .heading {
      font-size: 20px; }
    .impact-stories .image-capture-container .image-live-preview,
    .impact-stories-stage .image-capture-container .image-live-preview,
    .impact-stories-app .image-capture-container .image-live-preview {
      margin-top: 20px;
      width: 100%; }
  .impact-stories .image-preview-container,
  .impact-stories-stage .image-preview-container,
  .impact-stories-app .image-preview-container {
    margin-top: 23px;
    padding: 40px 15px 25px 15px;
    min-height: unset;
    width: 380px; }
    .impact-stories .image-preview-container .heading,
    .impact-stories-stage .image-preview-container .heading,
    .impact-stories-app .image-preview-container .heading {
      font-size: 20px; }
    .impact-stories .image-preview-container .image-preview,
    .impact-stories-stage .image-preview-container .image-preview,
    .impact-stories-app .image-preview-container .image-preview {
      margin-top: 20px; }
  .impact-stories .full-image-container,
  .impact-stories-stage .full-image-container,
  .impact-stories-app .full-image-container {
    margin-top: 33px; }
    .impact-stories .full-image-container .heading,
    .impact-stories-stage .full-image-container .heading,
    .impact-stories-app .full-image-container .heading {
      font-size: 20px; }
    .impact-stories .full-image-container .add-post-container,
    .impact-stories-stage .full-image-container .add-post-container,
    .impact-stories-app .full-image-container .add-post-container {
      margin-top: 20px; }
      .impact-stories .full-image-container .add-post-container .image-preview-and-retake,
      .impact-stories-stage .full-image-container .add-post-container .image-preview-and-retake,
      .impact-stories-app .full-image-container .add-post-container .image-preview-and-retake {
        margin: 0 auto 20px auto; }
        .impact-stories .full-image-container .add-post-container .image-preview-and-retake .action-retake,
        .impact-stories-stage .full-image-container .add-post-container .image-preview-and-retake .action-retake,
        .impact-stories-app .full-image-container .add-post-container .image-preview-and-retake .action-retake {
          font-size: 13px; }
      .impact-stories .full-image-container .add-post-container .add-story-container .user-info .user-image,
      .impact-stories-stage .full-image-container .add-post-container .add-story-container .user-info .user-image,
      .impact-stories-app .full-image-container .add-post-container .add-story-container .user-info .user-image {
        height: 45px;
        width: 45px; }
        .impact-stories .full-image-container .add-post-container .add-story-container .user-info .user-image img,
        .impact-stories-stage .full-image-container .add-post-container .add-story-container .user-info .user-image img,
        .impact-stories-app .full-image-container .add-post-container .add-story-container .user-info .user-image img {
          height: 39px;
          width: 39px; }
      .impact-stories .full-image-container .add-post-container .add-story-container .user-info .username,
      .impact-stories-stage .full-image-container .add-post-container .add-story-container .user-info .username,
      .impact-stories-app .full-image-container .add-post-container .add-story-container .user-info .username {
        font-size: 16px; }
  .impact-stories .image-download-container .image-download-preview .download-video-hashtag,
  .impact-stories-stage .image-download-container .image-download-preview .download-video-hashtag,
  .impact-stories-app .image-download-container .image-download-preview .download-video-hashtag {
    font-size: 34px; }
  .impact-stories .image-download-container .image-download-preview .thank-you,
  .impact-stories-stage .image-download-container .image-download-preview .thank-you,
  .impact-stories-app .image-download-container .image-download-preview .thank-you {
    font-size: 20px; }
  .impact-stories .record-video-container,
  .impact-stories-stage .record-video-container,
  .impact-stories-app .record-video-container {
    margin-top: 33px;
    padding-top: 20px; }
    .impact-stories .record-video-container .heading,
    .impact-stories-stage .record-video-container .heading,
    .impact-stories-app .record-video-container .heading {
      font-size: 20px;
      height: 40px;
      display: flex;
      align-items: center; }
    .impact-stories .record-video-container .video-live-preview,
    .impact-stories-stage .record-video-container .video-live-preview,
    .impact-stories-app .record-video-container .video-live-preview {
      margin-top: 0; }
    .impact-stories .record-video-container .capture-action,
    .impact-stories-stage .record-video-container .capture-action,
    .impact-stories-app .record-video-container .capture-action {
      margin-top: 0; }
      .impact-stories .record-video-container .capture-action.record .image,
      .impact-stories-stage .record-video-container .capture-action.record .image,
      .impact-stories-app .record-video-container .capture-action.record .image {
        width: 71px;
        height: 71px; }
        .impact-stories .record-video-container .capture-action.record .image .video-icon-fk,
        .impact-stories-stage .record-video-container .capture-action.record .image .video-icon-fk,
        .impact-stories-app .record-video-container .capture-action.record .image .video-icon-fk {
          width: 35.2px;
          height: 23.2px; }
      .impact-stories .record-video-container .capture-action.stop .image,
      .impact-stories-stage .record-video-container .capture-action.stop .image,
      .impact-stories-app .record-video-container .capture-action.stop .image {
        width: 71px;
        height: 71px; }
        .impact-stories .record-video-container .capture-action.stop .image .stop-icon-fk,
        .impact-stories-stage .record-video-container .capture-action.stop .image .stop-icon-fk,
        .impact-stories-app .record-video-container .capture-action.stop .image .stop-icon-fk {
          width: 38px;
          height: 38px; }
  .impact-stories .video-preview-container,
  .impact-stories-stage .video-preview-container,
  .impact-stories-app .video-preview-container {
    margin-top: 33px;
    padding-top: 20px;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.24); }
    .impact-stories .video-preview-container .heading,
    .impact-stories-stage .video-preview-container .heading,
    .impact-stories-app .video-preview-container .heading {
      font-size: 20px;
      display: flex;
      align-items: center;
      height: 40px; }
    .impact-stories .video-preview-container .video-preview,
    .impact-stories-stage .video-preview-container .video-preview,
    .impact-stories-app .video-preview-container .video-preview {
      margin-top: 8px; }
      .impact-stories .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container,
      .impact-stories-stage .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container,
      .impact-stories-app .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container {
        margin-top: 1px;
        height: 6px;
        border-radius: 30px;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.19);
        background-color: #f5f5f5; }
        .impact-stories .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek,
        .impact-stories-stage .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek,
        .impact-stories-app .video-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek {
          height: 6px;
          border-radius: 30px; }
      .impact-stories .video-preview-container .video-preview .player-container .video-controls .video-info,
      .impact-stories-stage .video-preview-container .video-preview .player-container .video-controls .video-info,
      .impact-stories-app .video-preview-container .video-preview .player-container .video-controls .video-info {
        font-size: 15px; }
  .impact-stories .full-video-container,
  .impact-stories-stage .full-video-container,
  .impact-stories-app .full-video-container {
    margin-top: 33px; }
    .impact-stories .full-video-container .heading,
    .impact-stories-stage .full-video-container .heading,
    .impact-stories-app .full-video-container .heading {
      font-size: 20px; }
    .impact-stories .full-video-container .add-post-container,
    .impact-stories-stage .full-video-container .add-post-container,
    .impact-stories-app .full-video-container .add-post-container {
      margin-top: 20px; }
      .impact-stories .full-video-container .add-post-container .video-preview-and-retake,
      .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake,
      .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake {
        margin: 0 auto 20px auto; }
        .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container,
        .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container,
        .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container {
          width: 340px; }
          .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container,
          .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container,
          .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container {
            margin-top: 1px;
            height: 6px;
            border-radius: 30px;
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.19);
            background-color: #f5f5f5; }
            .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container .seek,
            .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container .seek,
            .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-actions .seek-container .seek {
              height: 6px;
              border-radius: 30px; }
          .impact-stories .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-info,
          .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-info,
          .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .player-container .video-controls .video-info {
            font-size: 15px; }
        .impact-stories .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo .text,
        .impact-stories-stage .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo .text,
        .impact-stories-app .full-video-container .add-post-container .video-preview-and-retake .action-retake .btn-redo .text {
          font-size: 13px; }
      .impact-stories .full-video-container .add-post-container .add-story-container .user-info .user-image,
      .impact-stories-stage .full-video-container .add-post-container .add-story-container .user-info .user-image,
      .impact-stories-app .full-video-container .add-post-container .add-story-container .user-info .user-image {
        height: 45px;
        width: 45px; }
        .impact-stories .full-video-container .add-post-container .add-story-container .user-info .user-image img,
        .impact-stories-stage .full-video-container .add-post-container .add-story-container .user-info .user-image img,
        .impact-stories-app .full-video-container .add-post-container .add-story-container .user-info .user-image img {
          height: 39px;
          width: 39px; }
      .impact-stories .full-video-container .add-post-container .add-story-container .user-info .username,
      .impact-stories-stage .full-video-container .add-post-container .add-story-container .user-info .username,
      .impact-stories-app .full-video-container .add-post-container .add-story-container .user-info .username {
        font-size: 16px; }
  .impact-stories .video-post-preview-container,
  .impact-stories-stage .video-post-preview-container,
  .impact-stories-app .video-post-preview-container {
    margin-top: 10px !important; }
    .impact-stories .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container,
    .impact-stories-stage .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container,
    .impact-stories-app .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container {
      height: 6px; }
      .impact-stories .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek,
      .impact-stories-stage .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek,
      .impact-stories-app .video-post-preview-container .video-preview .player-container .video-controls .video-actions .seek-container .seek {
        height: 6px; }
    .impact-stories .video-post-preview-container .video-preview .player-container .video-controls .video-info,
    .impact-stories-stage .video-post-preview-container .video-preview .player-container .video-controls .video-info,
    .impact-stories-app .video-post-preview-container .video-preview .player-container .video-controls .video-info {
      font-size: 15px; }
    .impact-stories .video-post-preview-container .story,
    .impact-stories-stage .video-post-preview-container .story,
    .impact-stories-app .video-post-preview-container .story {
      font-size: 13px; }
    .impact-stories .video-post-preview-container .hashtags .hashtag-wrapper,
    .impact-stories-stage .video-post-preview-container .hashtags .hashtag-wrapper,
    .impact-stories-app .video-post-preview-container .hashtags .hashtag-wrapper {
      font-size: 13px; }
    .impact-stories .video-post-preview-container .user-info .user-image,
    .impact-stories-stage .video-post-preview-container .user-info .user-image,
    .impact-stories-app .video-post-preview-container .user-info .user-image {
      width: 45px;
      height: 45px; }
      .impact-stories .video-post-preview-container .user-info .user-image img,
      .impact-stories-stage .video-post-preview-container .user-info .user-image img,
      .impact-stories-app .video-post-preview-container .user-info .user-image img {
        width: 40px;
        height: 40px; }
    .impact-stories .video-post-preview-container .user-info .username,
    .impact-stories-stage .video-post-preview-container .user-info .username,
    .impact-stories-app .video-post-preview-container .user-info .username {
      font-size: 16px; }
  .impact-stories .video-download-container .video-download-preview .download-video-hashtag,
  .impact-stories-stage .video-download-container .video-download-preview .download-video-hashtag,
  .impact-stories-app .video-download-container .video-download-preview .download-video-hashtag {
    font-size: 34px; }
  .impact-stories .video-download-container .video-download-preview .thank-you,
  .impact-stories-stage .video-download-container .video-download-preview .thank-you,
  .impact-stories-app .video-download-container .video-download-preview .thank-you {
    font-size: 20px; }
  .impact-stories .upload-image-container,
  .impact-stories-stage .upload-image-container,
  .impact-stories-app .upload-image-container {
    padding: 33px 15px 70px 15px;
    margin-top: 33px;
    height: 310px; }
    .impact-stories .upload-image-container .heading,
    .impact-stories-stage .upload-image-container .heading,
    .impact-stories-app .upload-image-container .heading {
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 20px; }
    .impact-stories .upload-image-container .drag-n-drop,
    .impact-stories-stage .upload-image-container .drag-n-drop,
    .impact-stories-app .upload-image-container .drag-n-drop {
      margin-top: 60px;
      border: none;
      background-color: transparent;
      height: max-content; }
      .impact-stories .upload-image-container .drag-n-drop .dnd-title,
      .impact-stories-stage .upload-image-container .drag-n-drop .dnd-title,
      .impact-stories-app .upload-image-container .drag-n-drop .dnd-title {
        display: none; }
      .impact-stories .upload-image-container .drag-n-drop p,
      .impact-stories-stage .upload-image-container .drag-n-drop p,
      .impact-stories-app .upload-image-container .drag-n-drop p {
        display: none; }
      .impact-stories .upload-image-container .drag-n-drop .file-upload .max-limit,
      .impact-stories-stage .upload-image-container .drag-n-drop .file-upload .max-limit,
      .impact-stories-app .upload-image-container .drag-n-drop .file-upload .max-limit {
        font-size: 0.8em;
        margin-top: 8px;
        height: 40px;
        display: flex;
        align-items: center; }
      .impact-stories .upload-image-container .drag-n-drop .file-upload .custom-file-upload,
      .impact-stories-stage .upload-image-container .drag-n-drop .file-upload .custom-file-upload,
      .impact-stories-app .upload-image-container .drag-n-drop .file-upload .custom-file-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0076d5;
        width: 100%;
        max-width: 254px;
        min-height: 40px;
        margin: auto;
        font-size: 1em;
        font-family: CiscoSansTT;
        color: #fff;
        border-radius: 24px;
        cursor: pointer;
        gap: 10px; }
        .impact-stories .upload-image-container .drag-n-drop .file-upload .custom-file-upload img,
        .impact-stories-stage .upload-image-container .drag-n-drop .file-upload .custom-file-upload img,
        .impact-stories-app .upload-image-container .drag-n-drop .file-upload .custom-file-upload img {
          height: 20px; }
      .impact-stories .upload-image-container .drag-n-drop .file-upload label .text,
      .impact-stories-stage .upload-image-container .drag-n-drop .file-upload label .text,
      .impact-stories-app .upload-image-container .drag-n-drop .file-upload label .text {
        font-size: 14px;
        padding-top: 3px; }
      .impact-stories .upload-image-container .drag-n-drop .file-upload input,
      .impact-stories-stage .upload-image-container .drag-n-drop .file-upload input,
      .impact-stories-app .upload-image-container .drag-n-drop .file-upload input {
        display: none; }
    .impact-stories .upload-image-container .info,
    .impact-stories-stage .upload-image-container .info,
    .impact-stories-app .upload-image-container .info {
      position: absolute;
      bottom: 0;
      text-align: center;
      padding-bottom: 25px; }
    .impact-stories .upload-image-container .error,
    .impact-stories-stage .upload-image-container .error,
    .impact-stories-app .upload-image-container .error {
      margin-top: 5px;
      font-size: 0.75em;
      font-family: CiscoSansTTBold;
      text-align: right;
      color: #c10707; }
  .impact-stories .upload-multiple-preview-image-container,
  .impact-stories-stage .upload-multiple-preview-image-container,
  .impact-stories-app .upload-multiple-preview-image-container {
    padding: 13px 18px 44px 18px;
    margin-top: 33px; }
    .impact-stories .upload-multiple-preview-image-container .heading,
    .impact-stories-stage .upload-multiple-preview-image-container .heading,
    .impact-stories-app .upload-multiple-preview-image-container .heading {
      font-size: 20px;
      height: 40px; }
    .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items,
    .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items,
    .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items {
      margin-top: 23px; }
      .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items .item,
      .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items .item,
      .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items .item {
        margin-bottom: 10px;
        gap: 18px; }
        .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-image,
        .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-image,
        .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .items .item .item-image {
          width: 54px;
          height: 54px; }
    .impact-stories .upload-multiple-preview-image-container .upload-multiple-preview .action,
    .impact-stories-stage .upload-multiple-preview-image-container .upload-multiple-preview .action,
    .impact-stories-app .upload-multiple-preview-image-container .upload-multiple-preview .action {
      gap: 5px; }
  .impact-stories .upload-preview-container,
  .impact-stories-stage .upload-preview-container,
  .impact-stories-app .upload-preview-container {
    margin-top: 23px;
    padding: 40px 15px 25px 15px;
    min-height: unset; }
    .impact-stories .upload-preview-container .heading,
    .impact-stories-stage .upload-preview-container .heading,
    .impact-stories-app .upload-preview-container .heading {
      font-size: 20px; }
    .impact-stories .upload-preview-container .upload-preview,
    .impact-stories-stage .upload-preview-container .upload-preview,
    .impact-stories-app .upload-preview-container .upload-preview {
      margin-top: 25px; }
  .impact-stories .full-upload-container,
  .impact-stories-stage .full-upload-container,
  .impact-stories-app .full-upload-container {
    margin-top: 33px; }
    .impact-stories .full-upload-container .heading,
    .impact-stories-stage .full-upload-container .heading,
    .impact-stories-app .full-upload-container .heading {
      font-size: 20px; }
    .impact-stories .full-upload-container .add-post-container,
    .impact-stories-stage .full-upload-container .add-post-container,
    .impact-stories-app .full-upload-container .add-post-container {
      margin-top: 20px; }
      .impact-stories .full-upload-container .add-post-container .upload-preview-and-retake,
      .impact-stories-stage .full-upload-container .add-post-container .upload-preview-and-retake,
      .impact-stories-app .full-upload-container .add-post-container .upload-preview-and-retake {
        margin: 0 auto 20px auto; }
        .impact-stories .full-upload-container .add-post-container .upload-preview-and-retake .action-retake,
        .impact-stories-stage .full-upload-container .add-post-container .upload-preview-and-retake .action-retake,
        .impact-stories-app .full-upload-container .add-post-container .upload-preview-and-retake .action-retake {
          font-size: 13px; }
      .impact-stories .full-upload-container .add-post-container .add-story-container .user-info .user-image,
      .impact-stories-stage .full-upload-container .add-post-container .add-story-container .user-info .user-image,
      .impact-stories-app .full-upload-container .add-post-container .add-story-container .user-info .user-image {
        height: 45px;
        width: 45px; }
        .impact-stories .full-upload-container .add-post-container .add-story-container .user-info .user-image img,
        .impact-stories-stage .full-upload-container .add-post-container .add-story-container .user-info .user-image img,
        .impact-stories-app .full-upload-container .add-post-container .add-story-container .user-info .user-image img {
          height: 39px;
          width: 39px; }
      .impact-stories .full-upload-container .add-post-container .add-story-container .user-info .username,
      .impact-stories-stage .full-upload-container .add-post-container .add-story-container .user-info .username,
      .impact-stories-app .full-upload-container .add-post-container .add-story-container .user-info .username {
        font-size: 16px; }
  .impact-stories .upload-post-review-container,
  .impact-stories-stage .upload-post-review-container,
  .impact-stories-app .upload-post-review-container {
    margin-top: 23px;
    padding: 15px 15px 25px 15px;
    min-height: unset; }
    .impact-stories .upload-post-review-container .heading,
    .impact-stories-stage .upload-post-review-container .heading,
    .impact-stories-app .upload-post-review-container .heading {
      font-size: 20px; }
    .impact-stories .upload-post-review-container .image-preview,
    .impact-stories-stage .upload-post-review-container .image-preview,
    .impact-stories-app .upload-post-review-container .image-preview {
      margin-top: 30px; }
  .impact-stories .upload-download-container .upload-download-preview .download-video-hashtag,
  .impact-stories-stage .upload-download-container .upload-download-preview .download-video-hashtag,
  .impact-stories-app .upload-download-container .upload-download-preview .download-video-hashtag {
    font-size: 34px; }
  .impact-stories .upload-download-container .upload-download-preview .thank-you,
  .impact-stories-stage .upload-download-container .upload-download-preview .thank-you,
  .impact-stories-app .upload-download-container .upload-download-preview .thank-you {
    font-size: 20px; } }

@media (max-width: 460px) {
  .impact-stories .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .timer-overlay,
  .impact-stories-stage .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .timer-overlay,
  .impact-stories-app .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .timer-overlay {
    left: 71%; } }

@media (max-width: 400px) {
  .impact-stories .action-container .spinner,
  .impact-stories-stage .action-container .spinner,
  .impact-stories-app .action-container .spinner {
    left: 38%; }
  .impact-stories .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .timer-overlay,
  .impact-stories-stage .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .timer-overlay,
  .impact-stories-app .record-video-container .video-live-preview .video-recorder-wrapper .timer-button-container .timer-overlay {
    left: 69%; }
  .impact-stories .image-preview-container,
  .impact-stories-stage .image-preview-container,
  .impact-stories-app .image-preview-container {
    width: 360px; } }

@media (max-width: 320px) {
  .ciscoliveus2023 {
    max-height: 100vh;
    background-color: #13284c; }
    .ciscoliveus2023 .lounge-container {
      background-color: #13284c;
      padding: 23px 12px; }
      .ciscoliveus2023 .lounge-container .lounge-header .title {
        font-size: 2em;
        text-align: center;
        line-height: 1;
        margin-left: 10px; }
      .ciscoliveus2023 .lounge-container .lounge-content {
        height: 100vh; }
        .ciscoliveus2023 .lounge-container .lounge-content .lounge-description {
          width: 100%;
          margin-left: 10px; }
          .ciscoliveus2023 .lounge-container .lounge-content .lounge-description .description {
            line-height: 1;
            padding: 10px 0px;
            text-align: center; }
        .ciscoliveus2023 .lounge-container .lounge-content .interactive-section {
          margin-top: 25%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          overflow-y: auto; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .one0 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .two1 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .three2 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .four3 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button {
            width: 175px;
            height: 175px;
            position: static;
            margin: 15px 15px; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
              width: 55px;
              height: 74px;
              margin-bottom: 5px; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-description .interaction-title {
              font-size: 1em;
              line-height: 1; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.one .interaction-title {
              width: 150px; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.two .interaction-title {
              width: 100%; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.three .interaction-title {
              width: 150px; }
            .ciscoliveus2023 .lounge-container .lounge-content .interactive-section .interactive-button.four .interaction-title {
              width: 150px; }
    .ciscoliveus2023 .new-highlights-container-scroll,
    .ciscoliveus2023 .gallery-scroll-parent-container,
    .ciscoliveus2023 .leaderboard-container {
      height: 100vh;
      max-height: 100vh; }
      .ciscoliveus2023 .new-highlights-container-scroll .cards,
      .ciscoliveus2023 .new-highlights-container-scroll .gallery-scroll-cards,
      .ciscoliveus2023 .new-highlights-container-scroll .leaderboard-content,
      .ciscoliveus2023 .gallery-scroll-parent-container .cards,
      .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-cards,
      .ciscoliveus2023 .gallery-scroll-parent-container .leaderboard-content,
      .ciscoliveus2023 .leaderboard-container .cards,
      .ciscoliveus2023 .leaderboard-container .gallery-scroll-cards,
      .ciscoliveus2023 .leaderboard-container .leaderboard-content {
        max-height: 100vh;
        height: 100vh; }
      .ciscoliveus2023 .new-highlights-container-scroll .gallery-scroll-container .gallery-scroll-cards,
      .ciscoliveus2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards,
      .ciscoliveus2023 .leaderboard-container .gallery-scroll-container .gallery-scroll-cards {
        height: 100vh; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .back .icon-fk,
      .ciscoliveus2023 .gallery-scroll-parent-container .header .back .icon-fk,
      .ciscoliveus2023 .leaderboard-container .header .back .icon-fk {
        font-size: 0.75em; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .back .back-label,
      .ciscoliveus2023 .gallery-scroll-parent-container .header .back .back-label,
      .ciscoliveus2023 .leaderboard-container .header .back .back-label {
        font-size: 0.75em; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .title,
      .ciscoliveus2023 .gallery-scroll-parent-container .header .title,
      .ciscoliveus2023 .leaderboard-container .header .title {
        font-size: 1.5em; }
      .ciscoliveus2023 .new-highlights-container-scroll .header .action,
      .ciscoliveus2023 .gallery-scroll-parent-container .header .action,
      .ciscoliveus2023 .leaderboard-container .header .action {
        font-size: 0.75em;
        margin-right: 5px;
        padding: 2px 10px 1px 10px; }
  .ciscoliveapjc2023 {
    max-height: 760px;
    background-color: #13284c; }
    .ciscoliveapjc2023 .lounge-container {
      background-color: #13284c;
      padding: 23px 12px; }
      .ciscoliveapjc2023 .lounge-container .lounge-header .title {
        font-size: 2em;
        text-align: center;
        line-height: 1;
        margin-left: 10px; }
      .ciscoliveapjc2023 .lounge-container .lounge-content {
        height: 100vh; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description {
          width: 100%;
          margin-top: 5%;
          margin-left: 10px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .lounge-description .description {
            line-height: 1;
            padding: 10px 0px;
            text-align: center; }
        .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section {
          margin-top: 5%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          overflow-y: auto; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .one0 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .two1 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .three2 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .four3 {
            width: 300px;
            height: 150px;
            padding: 12px 9px 40px 9px;
            margin-bottom: 20px; }
          .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button {
            width: 175px;
            height: 175px;
            position: static;
            margin: 15px 15px; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
              width: 55px;
              height: 74px;
              margin-bottom: 5px; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button .interaction-description .interaction-title {
              font-size: 1em;
              line-height: 1; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.one .interaction-title {
              width: 150px; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.two .interaction-title {
              width: 100%; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.three .interaction-title {
              width: 150px; }
            .ciscoliveapjc2023 .lounge-container .lounge-content .interactive-section .interactive-button.four .interaction-title {
              width: 150px; }
    .ciscoliveapjc2023 .new-highlights-container-scroll,
    .ciscoliveapjc2023 .gallery-scroll-parent-container,
    .ciscoliveapjc2023 .leaderboard-container {
      height: 100vh;
      max-height: 100vh; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .cards,
      .ciscoliveapjc2023 .new-highlights-container-scroll .gallery-scroll-cards,
      .ciscoliveapjc2023 .new-highlights-container-scroll .leaderboard-content,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .cards,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-cards,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .leaderboard-content,
      .ciscoliveapjc2023 .leaderboard-container .cards,
      .ciscoliveapjc2023 .leaderboard-container .gallery-scroll-cards,
      .ciscoliveapjc2023 .leaderboard-container .leaderboard-content {
        max-height: 100vh;
        height: 100vh; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .gallery-scroll-container .gallery-scroll-cards,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .gallery-scroll-container .gallery-scroll-cards,
      .ciscoliveapjc2023 .leaderboard-container .gallery-scroll-container .gallery-scroll-cards {
        height: 100vh; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .back .icon-fk,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .back .icon-fk,
      .ciscoliveapjc2023 .leaderboard-container .header .back .icon-fk {
        font-size: 0.75em; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .back .back-label,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .back .back-label,
      .ciscoliveapjc2023 .leaderboard-container .header .back .back-label {
        font-size: 0.75em; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .title,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .title,
      .ciscoliveapjc2023 .leaderboard-container .header .title {
        font-size: 1.5em; }
      .ciscoliveapjc2023 .new-highlights-container-scroll .header .action,
      .ciscoliveapjc2023 .gallery-scroll-parent-container .header .action,
      .ciscoliveapjc2023 .leaderboard-container .header .action {
        font-size: 0.75em;
        margin-right: 5px;
        padding: 2px 10px 1px 10px; }
  .impact-stories .image-preview-container {
    width: 300px; } }
