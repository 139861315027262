.fk-filter {
  width: 100%;
  min-height: 20px;
  display: flex;
  gap: 40px;
  padding: 0 0 10px;
  flex-wrap: wrap; }
  .fk-filter .fk-filter-item {
    border: solid 2px #01366A;
    border-radius: 20px;
    padding: 6px 13px;
    box-sizing: border-box;
    display: flex;
    place-items: center;
    cursor: pointer;
    color: #01366A; }
    .fk-filter .fk-filter-item.fk-selected {
      background-color: #01366A;
      color: #fff; }

.fk-filter-mobile {
  gap: 10px; }
  .fk-filter-mobile .fk-filter-item {
    border: solid 1px #01366A;
    font-size: 13px; }
