.fk-gallery-view-container {
  font-family: 'CiscoSansTT';
  padding: 0 0;
  background: #f5f5f5;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative; }
  .fk-gallery-view-container .fk-gallery-canvas {
    overflow: auto;
    box-sizing: border-box; }
  .fk-gallery-view-container .fk-scroll-icon {
    display: flex;
    position: absolute;
    bottom: 20px;
    left: 0;
    justify-content: center;
    width: 100%; }
    .fk-gallery-view-container .fk-scroll-icon .fk-mouse-scroll-icon {
      height: 80px; }
  .fk-gallery-view-container .fk-story-container {
    cursor: zoom-out;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    display: grid;
    background-color: rgba(255, 255, 255, 0.4); }
    .fk-gallery-view-container .fk-story-container .fk-story-inner-container {
      display: grid;
      overflow: hidden;
      margin: auto;
      box-sizing: border-box;
      justify-content: center;
      box-shadow: 3px 2px 8px 0 rgba(0, 0, 0, 0.13); }

.fk-paginator {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 100px;
  height: 100px;
  display: none; }
  .fk-paginator.fk-show {
    display: block; }
  .fk-paginator .fk-loader-container {
    backdrop-filter: none; }
    @supports not ((backdrop-filter: blur(200px)) or (-webkit-backdrop-filter: blur(200px))) {
      .fk-paginator .fk-loader-container {
        background-color: transparent; } }
