.category-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6%;
  font-family: CiscoSansThin; }
  .category-container .category-image {
    cursor: pointer; }
    .category-container .category-image img {
      max-width: 185px;
      width: 100%;
      height: auto; }
  .category-container .category-details {
    text-align: left; }
    .category-container .category-details .category-text {
      font-size: 3vw;
      width: 5em;
      margin-bottom: 1em;
      margin-left: 1em; }
    .category-container .category-details .category-button {
      cursor: pointer;
      margin-left: 10%;
      padding: 2% 0%;
      text-align: center;
      font-family: CiscoSans;
      font-size: 1.5vw; }
  .category-container.one .category-details .category-button {
    background-color: #fbad22; }
  .category-container.two .category-image img {
    max-width: 185px;
    width: 100%;
    height: auto; }
  .category-container.two .category-details .category-button {
    background-color: #69bf4a; }
  .category-container.three .category-image img {
    max-width: 188px;
    width: 100%;
    height: auto; }
  .category-container.three .category-details .category-button {
    background-color: #04a6e1; }
  .category-container.four .category-image img {
    max-width: 188px;
    width: 100%;
    height: auto; }
  .category-container.four .category-details .category-button {
    background-color: #e2211c; }
