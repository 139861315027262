@font-face {
  font-family: "CiscoSansTT";
  src: url("./CiscoSansTTRegular.woff") format("woff"),
    url("./CiscoSansTTRegular.ttf") format("truetype");
}

@font-face {
  font-family: "CiscoSansTTBold";
  src: url("./CiscoSansTTBold.woff") format("woff"),
    url("./CiscoSansTTBold.ttf") format("truetype");
}

@font-face {
  font-family: "CiscoSansTTLight";
  src: url("./CiscoSansTTLight.woff") format("woff"),
    url("./CiscoSansTTLight.ttf") format("truetype");
}
@font-face {
  font-family: "CiscoSansTTBoldOblique";
  src: url("./CiscoSansTTBoldOblique.woff") format("woff"),
    url("./CiscoSansTTBoldOblique.ttf") format("truetype");
}
