@keyframes fadein {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes fadeout {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 0; } }

.cards {
  display: flex;
  justify-content: space-around;
  align-items: inherit;
  flex-flow: row wrap; }
  .cards.fade-in .cardv2 {
    animation: 5s ease-in fadein; }
  .cards.fade-out .cardv2 {
    animation: 1s ease-out 0s normal forwards fadeout; }

.overlay {
  display: flex;
  justify-content: center;
  align-items: inherit;
  flex-flow: row wrap;
  margin: 20px; }
  .overlay .cardv2 {
    position: relative;
    width: 350px;
    transform: none;
    transition: none; }
    .overlay .cardv2 .image {
      position: relative;
      width: 300px;
      height: 300px;
      max-height: 400px;
      background-size: cover;
      background-position: center center; }
      .overlay .cardv2 .image img {
        max-width: 350px; }
      .overlay .cardv2 .image .source {
        top: 10px;
        right: 10px; }
        .overlay .cardv2 .image .source .src-twitter {
          width: 30px;
          height: 30px; }
          .overlay .cardv2 .image .source .src-twitter .icon-fk-twitter-1 {
            font-size: 1.45em;
            line-height: 1.4;
            margin-left: 0; }
        .overlay .cardv2 .image .source .src-instagram {
          width: 30px;
          height: 30px; }
          .overlay .cardv2 .image .source .src-instagram .icon-fk-instagram-1 {
            font-size: 1.2em; }
    .overlay .cardv2 .details {
      max-width: 350px;
      max-height: 500px; }
      .overlay .cardv2 .details .author .name {
        font-size: 16px; }
      .overlay .cardv2 .details .author .handle {
        font-size: 14px; }
      .overlay .cardv2 .details .msg {
        font-size: 16px;
        min-height: 200px; }
    .overlay .cardv2 .stats {
      width: 330px;
      font-size: 16px; }
      .overlay .cardv2 .stats .like-icon-fk {
        margin-left: 25px; }
      .overlay .cardv2 .stats .retweet-icon-fk {
        margin-left: 25px; }
    .overlay .cardv2:hover {
      transform: none;
      transition: none; }
  .overlay .close {
    font-family: Roboto;
    font-weight: 900;
    font-size: 20px;
    background-color: #ffffff;
    border-radius: 100px; }
