a {
  text-decoration: none;
  cursor: pointer; }

.text-feed {
  position: relative;
  background-color: #ffffff;
  margin-bottom: 1vw;
  width: 99vw; }
  .text-feed .source .src-twitter .icon-fk-youtube {
    color: red;
    font-size: 5.5vw; }
  .text-feed .source .src-instagram {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); }
    .text-feed .source .src-instagram .icon-fk-instagram-1 {
      color: #ffffff;
      line-height: 1.65;
      margin-left: 1px; }
  .text-feed .details {
    width: 100%;
    height: 100%; }
    .text-feed .details .author {
      position: relative;
      font-size: 3vw;
      color: #4c4c4c;
      width: 100%; }
      .text-feed .details .author img {
        position: absolute;
        top: 23px;
        left: 23px; }
      .text-feed .details .author .avatar {
        width: 15vw;
        height: 15vw;
        border-radius: 10vw;
        border: 1px solid #4c4c4c; }
      .text-feed .details .author .name {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 50%;
        max-height: 8vh;
        padding-top: 10%;
        margin-left: 27%;
        font-weight: 700;
        position: relative;
        font-size: 3.5vw;
        text-align: left;
        overflow: hidden; }
      .text-feed .details .author .handle {
        max-width: 60vw;
        max-height: 8vh;
        margin-left: 27%;
        font-size: 3vw;
        font-weight: 400;
        position: relative;
        text-align: left;
        overflow: hidden; }
      .text-feed .details .author .created-at {
        position: absolute;
        top: 70%;
        left: 80%;
        color: #9ca5aa;
        font-weight: 400; }
    .text-feed .details .msg {
      color: #4c4c4c;
      font-size: 4vw;
      font-weight: 500;
      padding: 5vw 0vw 1vw 5vw;
      padding-top: 5%;
      line-height: 1.54;
      letter-spacing: 0.05vw;
      text-align: left;
      width: 93%;
      margin-top: 1vw; }
  .text-feed .stats {
    border-top: 1px solid #edf2f6;
    width: 100%;
    font-family: Roboto;
    font-size: 4.5vw;
    padding: 1.5vh 0vw 1.5vh 0vw;
    display: flex;
    justify-content: space-between;
    color: #0084ff; }
    .text-feed .stats a {
      color: #0084ff; }
      .text-feed .stats a .icon-fk-reply {
        padding-right: 40px; }
    .text-feed .stats .like-count {
      padding-right: 40px; }
    .text-feed .stats .retweet-count {
      padding-right: 40px; }

@media (orientation: landscape) {
  .text-feed .details .author .name {
    margin-left: 25%; }
  .text-feed .details .author .handle {
    margin-left: 25%; }
  .text-feed .details .author .created-at {
    top: 70%; } }
