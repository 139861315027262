.router-container .landing-title {
  padding-top: 2%;
  text-align: center;
  font-family: CiscoSans;
  font-size: 3em;
  display: none; }

.router-container .router-container-back {
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  display: none; }
  .router-container .router-container-back .icon-fk-left-dir {
    margin-left: -0.5em; }
    .router-container .router-container-back .icon-fk-left-dir::before {
      font-size: 1.2em; }

.router-container .router-container-title {
  font-family: CiscoSansThin;
  display: none; }
