.lounge-container {
  background-image: url(./../assets/ciscolive2022/cl2022-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  padding: 46px 24px;
  display: flex;
  flex-direction: column; }
  .lounge-container .lounge-header {
    background-color: transparent; }
    .lounge-container .lounge-header .title {
      font-size: 3.75em;
      line-height: 1.5;
      font-family: CiscoSansThin;
      text-align: left; }
    .lounge-container .lounge-header .action {
      display: none; }
      .lounge-container .lounge-header .action .icon-fk-twitter-1 {
        display: none; }
  .lounge-container .lounge-content {
    height: 580px;
    background-color: transparent; }
    .lounge-container .lounge-content .lounge-description {
      width: 60%; }
      .lounge-container .lounge-content .lounge-description .description {
        font-size: 1em;
        font-family: CiscoSansLight;
        text-align: left;
        line-height: 1.5; }
      .lounge-container .lounge-content .lounge-description .virtual-booth {
        display: none; }
    .lounge-container .lounge-content .interactive-section {
      position: relative; }
      .lounge-container .lounge-content .interactive-section .interactive-button {
        width: 275px;
        height: 275px;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute; }
        .lounge-container .lounge-content .interactive-section .interactive-button.one {
          background-color: #1c355e;
          top: 240px;
          left: 15px; }
          .lounge-container .lounge-content .interactive-section .interactive-button.one .interaction-icon-fk {
            background-image: url(./../assets/ciscoliveus2021/highlights_us2021.png); }
          .lounge-container .lounge-content .interactive-section .interactive-button.one .interaction-title {
            width: 160px;
            text-align: center; }
        .lounge-container .lounge-content .interactive-section .interactive-button.two {
          background-color: #00bed6;
          top: 35px;
          left: 360px; }
          .lounge-container .lounge-content .interactive-section .interactive-button.two .interaction-icon-fk {
            background-image: url(./../assets/ciscoliveus2021/featured_us2021.png); }
          .lounge-container .lounge-content .interactive-section .interactive-button.two .interaction-title {
            width: 110px;
            text-align: center; }
        .lounge-container .lounge-content .interactive-section .interactive-button.three {
          background-color: #6abf49;
          top: 245px;
          right: 260px; }
          .lounge-container .lounge-content .interactive-section .interactive-button.three .interaction-icon-fk {
            background-image: url(./../assets/ciscolive2022/platform.png); }
          .lounge-container .lounge-content .interactive-section .interactive-button.three .interaction-title {
            width: 160px;
            text-align: center; }
        .lounge-container .lounge-content .interactive-section .interactive-button.four {
          background-color: #f4b21c;
          top: -65px;
          right: 80px; }
          .lounge-container .lounge-content .interactive-section .interactive-button.four .interaction-icon-fk {
            background-image: url(./../assets/ciscolive2022/platform.png); }
          .lounge-container .lounge-content .interactive-section .interactive-button.four .interaction-title {
            width: 160px;
            text-align: center; }
        .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
          width: 77px;
          height: 74px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          margin-bottom: 25px; }
        .lounge-container .lounge-content .interactive-section .interactive-button .interaction-description .interaction-title {
          font-family: CiscoSansLight;
          font-size: 1.75em;
          line-height: 1; }
        .lounge-container .lounge-content .interactive-section .interactive-button .interaction-description .interaction-action-btn {
          display: none; }

@media (max-width: 320px) {
  .lounge-container {
    padding: 23px 12px; }
    .lounge-container .lounge-header .lounge-title {
      font-size: 3em;
      text-align: center;
      line-height: 1; }
    .lounge-container .lounge-content {
      height: 100%; }
      .lounge-container .lounge-content .lounge-description {
        width: 100%; }
        .lounge-container .lounge-content .lounge-description .description {
          line-height: 1;
          padding: 10px 0px;
          text-align: center; }
      .lounge-container .lounge-content .interactive-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .lounge-container .lounge-content .interactive-section .interactive-button {
          width: 175px;
          height: 175px;
          position: static;
          margin: 15px 15px; }
          .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
            width: 55px;
            height: 74px;
            margin-bottom: 5px; }
          .lounge-container .lounge-content .interactive-section .interactive-button .interaction-description .interaction-title {
            font-size: 1em;
            line-height: 1; }
          .lounge-container .lounge-content .interactive-section .interactive-button.one .interaction-title {
            width: 150px; }
          .lounge-container .lounge-content .interactive-section .interactive-button.three .interaction-title {
            width: 150px; }
          .lounge-container .lounge-content .interactive-section .interactive-button.four .interaction-title {
            width: 150px; } }

@media (min-width: 320px) and (max-width: 480px) {
  .lounge-container {
    padding: 23px 12px; }
    .lounge-container .lounge-header .lounge-title {
      font-size: 3em;
      text-align: center;
      line-height: 1; }
    .lounge-container .lounge-content {
      height: 100%; }
      .lounge-container .lounge-content .lounge-description {
        width: 100%; }
        .lounge-container .lounge-content .lounge-description .description {
          line-height: 1;
          padding: 10px 0px;
          text-align: center; }
      .lounge-container .lounge-content .interactive-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .lounge-container .lounge-content .interactive-section .interactive-button {
          width: 175px;
          height: 175px;
          position: static;
          margin: 15px 15px; }
          .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
            width: 55px;
            height: 74px;
            margin-bottom: 5px; }
          .lounge-container .lounge-content .interactive-section .interactive-button .interaction-description .interaction-title {
            font-size: 1em;
            line-height: 1; }
          .lounge-container .lounge-content .interactive-section .interactive-button.one .interaction-title {
            width: 150px; }
          .lounge-container .lounge-content .interactive-section .interactive-button.three .interaction-title {
            width: 150px; }
          .lounge-container .lounge-content .interactive-section .interactive-button.four .interaction-title {
            width: 150px; } }

@media (min-width: 480px) and (max-width: 768px) {
  .lounge-container {
    padding: 46px 24px; }
    .lounge-container .lounge-header .lounge-title {
      text-align: center; }
    .lounge-container .lounge-content {
      height: 100%; }
      .lounge-container .lounge-content .lounge-description {
        width: 100%; }
        .lounge-container .lounge-content .lounge-description .description {
          padding: 10px 0px;
          text-align: center; }
      .lounge-container .lounge-content .interactive-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .lounge-container .lounge-content .interactive-section .interactive-button {
          position: static;
          margin: 15px 15px; }
          .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
            width: 77px;
            height: 74px;
            margin-bottom: 25px; } }

@media (min-width: 768px) and (max-width: 1024px) {
  .lounge-container {
    padding: 46px 24px; }
    .lounge-container .lounge-header .lounge-title {
      text-align: center; }
    .lounge-container .lounge-content {
      height: 100%; }
      .lounge-container .lounge-content .lounge-description {
        width: 100%; }
        .lounge-container .lounge-content .lounge-description .description {
          padding: 10px 0px;
          text-align: center; }
      .lounge-container .lounge-content .interactive-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .lounge-container .lounge-content .interactive-section .interactive-button {
          position: static;
          margin: 15px 15px; }
          .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
            width: 77px;
            height: 74px;
            margin-bottom: 25px; } }

@media (min-width: 1024px) and (max-width: 1200px) {
  .lounge-container {
    padding: 46px 24px; }
    .lounge-container .lounge-header .lounge-title {
      text-align: center; }
    .lounge-container .lounge-content {
      height: 100%; }
      .lounge-container .lounge-content .lounge-description {
        width: 100%; }
        .lounge-container .lounge-content .lounge-description .description {
          padding: 10px 0px;
          text-align: center; }
      .lounge-container .lounge-content .interactive-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .lounge-container .lounge-content .interactive-section .interactive-button {
          position: static;
          margin: 15px 15px; }
          .lounge-container .lounge-content .interactive-section .interactive-button .interaction-icon-fk {
            width: 77px;
            height: 74px;
            margin-bottom: 25px; } }
